<template>
  <div class="home">
    <Loader v-if="load"/>
    <div class="section bg-tree" style="padding-top: 80px">
      <div class="intro wrap">
        <h2 class="title-m margin-bot-16">{{ $lang.home.intro.title }}</h2>
        <p>{{ $lang.home.intro.representBefore }}</p>
        <p class="padding-top-16">{{ $lang.home.intro.represent }} <span class="bold">5%</span>{{ $lang.home.intro.population }}
          <span class="bold">80%</span> {{ $lang.home.intro.terre }}</p>
        <p class="padding-top-16">{{ $lang.home.intro.p2 }}</p>
        <p class="padding-top-16">{{ $lang.home.intro.p3 }}</p>
      </div>
    </div>
    <div class="section bg-tree-top">
      <div class="wrap large action-block">
        <div class="text-align-center">
          <h2 class="title-m margin-bot-16">{{ $lang.home.actions.title }}</h2>
          <ul class="list-actions">
            <li class="item-action" @click="selectAction" id="reforestation">
              {{ $lang.home.actions.action1 }}
            </li>
            <li class="item-action" @click="selectAction" id="eaupotable">{{ $lang.home.actions.action2 }}
            </li>
            <li class="item-action" @click="selectAction" id="digitale">
              {{ $lang.home.actions.action3 }}
            </li>
            <li class="item-action selected" @click="selectAction" id="droitdespeuples">
              {{ $lang.home.actions.action4 }}
            </li>
          </ul>
        </div>
        <div class="actions-imgs" v-if="actionItem && actionsImages[actionItem] && actionsImages[actionItem].length > 0">
          <img :src="img.url" alt="" v-for="img in actionsImages[actionItem]" :key="img">
        </div>
        <div class="actions-imgs" v-else>
          <img :src="img.fields.file.url" alt="" v-for="img in defaultImage[0]" :key="img">
        </div>
      </div>
    </div>
    <div class="section">
      <div class="right-sep"><img class="" src="../assets/imgs/half-separator.svg"></div>

      <div class="wrap large">
        <div class="flex autonomie-block">
          <div class="margin-right-24">
            <h3 class="title-m margin-bot-16">{{ $lang.home.soutien.title }}</h3>
            <p>{{ $lang.home.soutien.p1 }}</p><br>
            <p v-if="$lang.home.soutien.p3">{{ $lang.home.soutien.p3 }}</p><br>
            <p>{{ $lang.home.soutien.p2 }}</p>
          </div>
          <div><img src="../assets/imgs/autonomie.jpg" alt=""></div>
        </div>

      </div>
      <div class="left-sep"><img src="../assets/imgs/half-separator.svg"></div>

    </div>
    <div class="section">
      <div class="wrap large text-align-center">
        <h3 class="title-m margin-bot-8">{{ $lang.home.qlqPhotos.title }}</h3>
        <p class="title-xs text-soft">{{ $lang.home.qlqPhotos.subtitle }}</p>

        <div class="some-photos flex">
          <div><img src="../assets/imgs/QuelquesPhotos/elevefemme.jpg" alt=""></div>
          <div class="flex">
            <div class="flex vertical-pics">
              <img src="../assets/imgs/QuelquesPhotos/MaisonElevee.jpg" alt="">
              <img src="../assets/imgs/QuelquesPhotos/members.jpg" alt="">
            </div>
            <div>
              <img src="../assets/imgs/QuelquesPhotos/Tuileve.jpg" alt="">
            </div>
          </div>
        </div>
        <primary-btn class="show-more" link="https://www.instagram.com/association_jiboiana/?hl=fr"
                     :title="$lang.home.qlqPhotos.btn"/>
      </div>
    </div>
    <div class="section">
      <div class="wrap large">
        <div class="team-block">
          <h3 class="title-m margin-bot-8">{{ $lang.home.equipe.title }}</h3>
          <p class="title-xs text-soft">{{ $lang.home.equipe.subtitle }}</p>
          <div class="team-img margin-bot-16">
            <img src="../assets/imgs/team.jpg" alt="">
          </div>
          <p class="margin-bot-16">{{ $lang.home.equipe.p }}</p>
          <primary-btn route="/about/jiboiana" :title="$lang.home.equipe.btn "></primary-btn>
        </div>
      </div>
    </div>
    <support/>
  </div>
</template>

<script>

import PrimaryBtn from '@/components/PrimaryBtn'
import Support from '@/components/Support'
import Loader from '@/components/Loader'

export default {
  name: 'Home',
  components: {
    Support,
    Loader,
    PrimaryBtn
  },
  data () {
    return {
      actionItem: 'droitsdespeuples',
      load: true,
      actionsImages: {},
      defaultImage: []
    }
  },
  methods: {
    selectAction: function (e) {
      this.actionItem = ''
      this.actionItem = e.target.id
      document.querySelectorAll('.item-action').forEach(el => {
        if (el.id === this.actionItem) {
          el.classList.add('selected')
        } else {
          el.classList.remove('selected')
        }
      })
    }
  },
  created () {
  },
  mounted () {
    this.load = false
    document.title = 'Jiboiana'
    document.querySelector('meta[name="description"]').setAttribute('content', this.$lang.head.title1 + ' ' + this.$lang.head.title2)
    this.$contentful.getEntry('32iRSUCxpXls2g68EkXt0Q').then(el => {
      this.defaultImage.push(el.fields.droitDesPeuples)
      this.actionsImages = {
        droitdespeuples: [el.fields.droitDesPeuples[0].fields.file, el.fields.droitDesPeuples[1].fields.file, el.fields.droitDesPeuples[2].fields.file],
        digitale: [el.fields.digitale[0].fields.file, el.fields.digitale[1].fields.file, el.fields.digitale[2].fields.file],
        eaupotable: [el.fields.eaupotable[0].fields.file, el.fields.eaupotable[1].fields.file, el.fields.eaupotable[2].fields.file],
        reforestation: [el.fields.reforestation[0].fields.file, el.fields.reforestation[1].fields.file, el.fields.reforestation[2].fields.file]
      }
    })
  }
}
</script>
<style lang="scss" scoped>
.team-block {
  display: flex;
  flex-direction: column;
  align-items: center;

  .team-img {
    margin-top: 24px;
    max-width: 992px;
    max-height: 470px;

    > img {
      object-position: center;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
}

.show-more {
  margin-top: 18px;
  float: right;
}

.some-photos {
  margin-top: 24px;
  max-height: 404px;
  width: 100%;

  > div:first-child {
    width: 50%;

    > img {
      width: 100%;
      object-fit: cover;
      height: 100%;
    }
  }

  > div:nth-child(2) {
    width: 50%;

    div {
      width: 50%;

      > img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }

    > .vertical-pics {
      flex-direction: column;

      > img {
        object-fit: cover;
        height: 50%;
      }
    }
  }

  img {
    border: 2px solid white;
  }
}

.right-sep {
  display: flex;
  justify-content: flex-end;
}

.left-sep {
  display: flex;

  img {
    transform: scaleX(-1);
  }
}

.autonomie-block {
  margin: 60px 0;
  > div {
    width: 50%;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
  > div:last-child {
    max-height: 387px;
  }
}

.list-actions {
  display: flex;
  width: max-content;
  margin: 0 auto 32px auto;
  //margin-bottom: 32px;

  .item-action {
    cursor: pointer;
    max-height: 34px;
    background: var(--color-surface-divider);
    border-radius: 37px;
    padding: 4px 12px;

    &:not(:last-child) {
      margin-right: 12px;
    }

    &.selected {
      background: var(--color-secondary);
      color: white;
    }
  }
}

.intro {
  text-align: center;
  max-width: 700px;
  padding: 0 32px;
  margin: 0 auto;

}

.bg-tree {
  background-image: url("../assets/imgs/trees.jpg");
  background-color: #cccccc;
  //height: 500px;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  height: 878px;
}

.bg-tree-top {
  position: relative;
  height: 878px;
  margin-top: -1px;

  &:before {
    z-index: -1;
    content: "";
    position: absolute;
    inset: 0;
    background-image: url("../assets/imgs/trees.jpg");
    background-color: #cccccc;
    //height: 500px;
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    -moz-transform: scaleY(-1);
    -o-transform: scaleY(-1);
    -webkit-transform: scaleY(-1);
    transform: scaleY(-1);
    filter: FlipV;
    -ms-filter: "FlipV";
  }

  .action-block {
    margin: 220px auto 0 auto;
  }

  .actions-imgs {
    display: grid;
    grid-template-columns: 25% 50% 25%;

    & > img {
      width: 100%;
      object-fit: cover;
      height: 404px;
      transition: filter 0.2s ease;

      &:hover {
        filter: grayscale(1);
      }

      &:nth-child(2) {
        height: 100%;

      }
    }

  }
}

@media (max-width: 768px) {

  .container-support {
    width: 136px;

  }

  .some-photos {
    flex-direction: column;
    max-height: none;

    div {
      width: 100% !important
    }
  }
  .right-sep {
    width: 50%;
    display: block;
    float: right;

    img {
      object-fit: cover;
      width: 100%;
    }
  }

  .left-sep {
    width: 50%;

    img {
      object-fit: cover;
      width: 100%;
    }
  }
  .autonomie-block {
    flex-direction: column;
    max-height: none;

    > div {
      width: 100%;
      margin-right: 0;

      &:first-child {
        margin-bottom: 16px;
      }
    }
  }
  .list-actions {
    overflow-x: scroll;
    width: 100%;
    padding-bottom: 16px;

    .item-action {
      min-width: 154px;
    }
  }
  .bg-tree {
    // height: inherit;
    background-color: inherit;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .bg-tree-top {
    &:before {
      background-size: contain;
    }
    .action-block {
      margin-top: 0;
    }
  }

  .actions-imgs {
    //max-height: 400px;
    grid-template-columns: 50% 50% !important;

    & > img {
      border: 2px solid white;

      &:nth-child(2) {
        grid-column-start: 1;
        grid-column-end: 3;
        filter: grayscale(0);
      }

      &:nth-child(1), &:nth-child(3) {
        grid-row-start: 2;
      }
    }
  }

}

@media (max-width: 425px) {
  .intro {
    padding: 0 16px;
  }

}
</style>
