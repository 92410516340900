<template>
<div>
  <img src="../assets/imgs/separator.jpg" alt="" loading="lazy">
</div>
</template>

<script>
export default {
  name: 'Separator'
}
</script>

<style scoped lang="scss">
div{
  width: 100%;
  max-height: 170px;
  img{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
</style>
