<template>
  <div class="section">
    <div class="wrap large container-text">
      <div class="text-align-center">
        <h1 class="title-l margin-bot-24">{{ $lang.collab.title }}</h1>
        <p class="quote sub title-m margin-bot-12">{{ $lang.collab.p }}</p>
        <p>{{ $lang.collab.p2 }}</p>
      </div>
    </div>
    <div class="section" id="collab">
      <div class="wrap large">
        <collab/>
      </div>
    </div>
  </div>
</template>

<script>

import Collab from '@/components/collab'

export default {
  name: 'Jiboiana',
  components: { Collab },
  mounted () {
    document.title = this.$lang.collab.title2
    document.querySelector('meta[name="description"]').setAttribute('content', this.$lang.collab.title)
  }

}
</script>

<style scoped lang="scss">
h2 {
  margin-bottom: 12px;
}

.container-text {
  max-width: 800px;
}

p {
  &:not(.sub) {
    color: var(--color-text-soft);
  }

  &:not(.quote) {
    &::first-letter {
      font-size: 2rem;
      font-weight: bold;
    }
  }

  &:not(.sub):not(.quote) {
    margin-bottom: 64px;
  }
}
</style>
