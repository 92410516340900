<template>
  <div class="text-align-center">
    <h2 class="margin-bot-12">{{ $lang.about.team.title }}</h2>
    <div class="container-team">
      <profil v-for="t in team" :title="t.fields.name" :img="t.fields.ProfilPic.fields.file.url" type="team"
              :role="t.fields.role" :key="t"/>
    </div>
  </div>
</template>

<script>
import Profil from '@/components/Profil'

export default {
  name: 'Team',
  components: { Profil },
  data () {
    return {
      params: {
        skip: 0,
        locale: window.localStorage.getItem('locale') === 'en' || window.localStorage.getItem('locale') === 'br' ? 'en-US' : 'fr',
        content_type: 'team',
        order: '-sys.createdAt'
      },
      team: []
    }
  },
  created () {
    this.$contentful.getEntries(this.params)
      .then(res => {
        this.team = res.items
      })
  }
}
</script>

<style scoped lang="scss">
.sort-btn {
  cursor: pointer;
  max-height: 34px;
  background: var(--color-surface-divider);
  border-radius: 37px;
  padding: 4px 12px;

  &:not(:last-child) {
    margin-right: 12px;
  }

  &.selected {
    background: var(--color-secondary);
    color: white;
  }
}

.sort-grid {
  button {
    margin-right: 12px;

  }
}

.container-team {
  display: grid;
  row-gap: 40px;
  grid-template-columns: repeat(auto-fit, minmax(357px, 1fr));
  justify-items: center;
}

</style>
