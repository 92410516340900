import fr from './fr.js'
import en from './en.js'
import br from './br.js'

const x = localStorage.getItem('locale')
let lang = null
if (x === 'fr') {
  lang = fr
}
if (x === 'en') {
  lang = en
}
if (x === 'br') {
  lang = br
}
if (x === null) {
  lang = fr
}
export default lang
