<template>
  <div class="section">
    <div class="wrap large">
      <div class="flex space-between">
        <div class="left">
          <h1 class="title-l margin-bot-24">{{ $lang.events.title }}</h1>
          <div>
            <h3 class="title-s">{{ $lang.events.type }}</h3>
            <ul>
              <li v-for="type in eventType" :key="type">
                <input type="checkbox" v-model="typeSelected" :id="type.id"
                       :value="type.id" @change="showRightCard">
                <label class="small" :for="type.id">{{ type.name }}</label>
              </li>
            </ul>
          </div>
        </div>
        <div class="right">
          <div class="card-container">
            <card class="card" v-for="event in events" :key="event" :title="event.fields.title"
                  :subtitle="event.fields.localisation" :description="event.fields.richText"
                  :img="event.fields.image.fields.file.url" type="event" :link="event.fields.eventLink"
                  :date="moment(event.fields.date).format('L')" :data-type="bindData(event.fields)"
                  @showModal="infoModal(event.fields)"/>
          </div>
          <p v-if="errorMsg" :style="errorMsg !== null ? 'display: flex': ''" class="errMsg">{{ errorMsg }}</p>
        </div>
      </div>
    </div>
    <uni-modal :title="currentEvent.title" :date="moment(currentEvent.date).format('dddd Do MMMM YYYY')"
               :location="currentEvent.localisation" :is-visible="openModal"
               @close-modal="openModal = false" :has-footer-slot="true" :key="currrentEvent">
      <template v-slot:body>
        <p>{{ currentEvent.richText }}</p>
      </template>
      <template v-slot:footer>
        <primary-btn :link="currentEvent.eventLink" :title="$lang.events.reserve" style="margin: 0 auto"/>
      </template>
    </uni-modal>
  </div>
</template>

<script>
import moment from 'moment'
import Card from '@/components/Card'
import UniModal from '@/components/UniModal'
import PrimaryBtn from '@/components/PrimaryBtn'

export default {
  name: 'Events',
  components: {
    PrimaryBtn,
    UniModal,
    Card
  },
  data () {
    return {
      currentEvent: [],
      moment: null,
      params: {
        skip: 0,
        limit: 4,
        locale: window.localStorage.getItem('locale') === 'en' || window.localStorage.getItem('locale') === 'br' ? 'en-US' : 'fr',
        content_type: 'events',
        order: '-sys.createdAt'
      },
      events: null,
      eventType: [],
      typeSelected: [],
      errorMsg: null,
      openModal: false
    }
  },
  computed: {
    isEventDetailsPage () {
      return !!this.$route.params.id
    },
    ShowRightEvent () {
      if (this.isEventDetailsPage) {
        const eventId = this.$route.params.id
        return this.fetchEventDetails(eventId)
      }
      return null // Si ce n'est pas la page de détails de l'événement, retournez null
    }
  },
  created () {
    moment.locale('fr')
    this.moment = moment
    this.$contentful.getEntries(this.params)
      .then(res => {
        this.events = res.items
      })
    this.$contentful.getEntries({
      skip: 0,
      locale: window.localStorage.getItem('locale') === 'en' || window.localStorage.getItem('locale') === 'br' ? 'en-US' : 'fr',
      content_type: 'eventType'
    })
      .then(res => {
        this.eventType = res.items.map(el => {
          const type = {
            name: el.fields.name,
            id: el.sys.id
          }
          return type
        }
        )
      })
  },
  methods: {
    async fetchEventDetails (eventId) {
      try {
        // Effectuez la requête pour récupérer les détails de l'événement
        // par exemple, en utilisant une méthode de l'API Contentful
        const response = await this.$contentful.getEntry(eventId)
        return response // Retournez les détails de l'événement
      } catch (error) {
        console.error(error)
        return null // En cas d'erreur, retournez null ou une valeur par défaut appropriée
      }
    },
    bindData (e) {
      const tags = []
      e.EventType.forEach(el => {
        tags.push(el.sys.id)
      })
      return tags
    },
    showRightCard () {
      this.matchArray = []
      const cards = document.querySelectorAll('.card')
      cards.forEach(el => {
        if (this.typeSelected.length !== 0) {
          if (this.typeSelected.some(r => el.getAttribute('data-type').split(',').indexOf(r) >= 0)) {
            el.style.display = 'block'
          } else {
            el.style.display = 'none'
          }
        } else {
          if (el.style.display === 'none') {
            el.style.display = 'block'
          }
        }
      })
    },
    infoModal (e) {
      this.openModal = true
      this.currentEvent = e
      console.log(e)
    },
    closeModal () {
      this.showModal = false
    },
    showModalFromURL () {
      if (this.ShowRightEvent) {
        // console.log(this.ShowRightEvent)
        this.infoModal(this.ShowRightEvent)
      }
    }
  },
  watch: {
    '$route.params.id': function (show) {
      this.showModalFromURL()
    }
  },
  mounted () {
    this.showModalFromURL()
    document.title = this.$lang.events.title
    document.querySelector('meta[name="description"]').setAttribute('content', this.$lang.head.title1 + ' ' + this.$lang.head.title2)
  }
}
</script>

<style scoped lang="scss">
.card-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(384px, 1fr));
  grid-column-gap: 24px;
  grid-row-gap: 32px;
}

.left {
  margin-right: 24px;

  input {
    cursor: pointer;
    margin-right: 8px;
    color: #1BB4FF;
  }
}

.right {
  flex: 1;

  .errMsg {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    color: var(--color-danger);
    display: none;
  }
}

@media screen and (max-width: 991px) {
  .flex.space-between {
    flex-direction: column;
    align-items: center;
  }
  .right {
    margin-top: 32px;
  }
  .left {
    margin-right: 0;
    max-width: 384px;
  }
  ul {
    display: flex;
    flex-wrap: wrap;

    li {
      margin-right: 8px;
      margin-bottom: 8px;

      label {
        position: relative;
        width: fit-content;
        height: 32px;
        border: 2px solid var(--color-surface-divider);
        border-radius: 20px;
        padding: 4px 12px;
      }

      input {
        display: none;

        &:checked ~ label {
          border: 2px solid #1BB4FF;
          color: #1BB4FF;
        }

      }
    }
  }
  .card-container {
    grid-template-columns: 1fr;
  }
}
</style>
