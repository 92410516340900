<template>
  <Header :header-img="$route.meta.img || headerImg" v-if="$route.path !== '/contact'" :custom-text="$route.name === 'article' ? customText: null" :custom-head="$route.path ==='/blog' ? customHead: null "/>
  <main>
    <router-view/>
  </main>
  <Footer v-if="$route.path !== '/contact'"/>
</template>

<style lang="scss">
</style>
<script>
import './assets/styles/style.scss'
import Header from '@/components/Header'
import Footer from '@/components/Footer'

export default {
  components: {
    Footer,
    Header
  },
  data () {
    return {
      headerImgs: [{
        page: 'home',
        src: 'FemmesenJaunes.jpg'
      }, {
        page: 'projects',
        src: 'Cabane.jpg'
      }],
      headerImg: null,
      customText: null,
      customHead: null
    }
  }
}
</script>
