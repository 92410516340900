<template>
  <div class="container">
    <div class="bg-img">
      <div class="grey-img"></div>
      <img alt="" :src='$route.meta.img'>
    </div>
    <navbar class="navbar"/>
    <div class="section">
      <div class="relative contact-body">
        <div class="info">
          <h1 class="title-l text-white margin-bot-8">{{ $lang.contact.title }}</h1>
          <p class="text-white margin-bot-32">{{ $lang.contact.p }}</p>
          <div class="contact-info">
            <div class="phone">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M21.9999 16.9201V19.9201C22.0011 20.1986 21.944 20.4743 21.8324 20.7294C21.7209 20.9846 21.5572 21.2137 21.352 21.402C21.1468 21.5902 20.9045 21.7336 20.6407 21.8228C20.3769 21.912 20.0973 21.9452 19.8199 21.9201C16.7428 21.5857 13.7869 20.5342 11.1899 18.8501C8.77376 17.3148 6.72527 15.2663 5.18993 12.8501C3.49991 10.2413 2.44818 7.27109 2.11993 4.1801C2.09494 3.90356 2.12781 3.62486 2.21643 3.36172C2.30506 3.09859 2.4475 2.85679 2.6347 2.65172C2.82189 2.44665 3.04974 2.28281 3.30372 2.17062C3.55771 2.05843 3.83227 2.00036 4.10993 2.0001H7.10993C7.59524 1.99532 8.06572 2.16718 8.43369 2.48363C8.80166 2.80008 9.04201 3.23954 9.10993 3.7201C9.23656 4.68016 9.47138 5.62282 9.80993 6.5301C9.94448 6.88802 9.9736 7.27701 9.89384 7.65098C9.81408 8.02494 9.6288 8.36821 9.35993 8.6401L8.08993 9.9101C9.51349 12.4136 11.5864 14.4865 14.0899 15.9101L15.3599 14.6401C15.6318 14.3712 15.9751 14.1859 16.3491 14.1062C16.723 14.0264 17.112 14.0556 17.4699 14.1901C18.3772 14.5286 19.3199 14.7635 20.2799 14.8901C20.7657 14.9586 21.2093 15.2033 21.5265 15.5776C21.8436 15.9519 22.0121 16.4297 21.9999 16.9201Z"
                  fill="#EC6C0F"/>
              </svg>
              <div>
                <p>+33 785762324 (Laetitia)</p>
                <p>+33 667879645 (Léo)</p>
              </div>

            </div>
            <div class="jib-infos">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4 4H20C21.1 4 22 4.9 22 6V18C22 19.1 21.1 20 20 20H4C2.9 20 2 19.1 2 18V6C2 4.9 2.9 4 4 4Z"
                      fill="#EC6C0F"/>
                <path d="M22 6L12 13L2 6" stroke="#F9FAFA" stroke-width="2" stroke-linecap="round"
                      stroke-linejoin="round"/>
              </svg>
              <p>Association.jiboiana@gmail.com</p>
            </div>
            <div class="jib-infos">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M21 10C21 17 12 23 12 23C12 23 3 17 3 10C3 7.61305 3.94821 5.32387 5.63604 3.63604C7.32387 1.94821 9.61305 1 12 1C14.3869 1 16.6761 1.94821 18.364 3.63604C20.0518 5.32387 21 7.61305 21 10Z"
                  fill="#EC6C0F"/>
                <path
                  d="M12 13C13.6569 13 15 11.6569 15 10C15 8.34315 13.6569 7 12 7C10.3431 7 9 8.34315 9 10C9 11.6569 10.3431 13 12 13Z"
                  stroke="#F9FAFA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              <p>61 Rue de Reuilly, 75012 Paris</p>

            </div>
          </div>
        </div>
        <form @submit.prevent="createContact">
          <p class="err" v-if="errorMsg.length">{{ this.errorMsg }}</p>
          <div class="flex space-between margin-bot-16 two-input">
            <div class="half-input">
              <label for="firstname">{{ $lang.contact.input1 }}</label>
              <input type="text" id="firstname" name="firstname" v-model="contact.fields.firstname" placeholder="John">
            </div>
            <div class="half-input">
              <label for="lastname">{{ $lang.contact.input2 }}</label>
              <input type="text" id="lastname" name="lastname" v-model="contact.fields.lastname" placeholder="Doe">
            </div>
          </div>

          <div class="flex space-between margin-bot-16 two-input">
            <div class="half-input">
              <label for="email">Mail</label>
              <input type="email" id="email" placeholder="Jane.doe@adress.com" v-model="contact.fields.email">
            </div>
            <div class="half-input">
              <label for="number">{{ $lang.contact.input3 }}</label>
              <input type="tel" id="number" placeholder="+55 1234566" v-model="contact.fields.number">
            </div>
          </div>
          <label class="">{{ $lang.contact.input4[0] }}</label>
          <div class="radios">
            <div>
              <input type="radio" id="object1" name="object" value="Message" v-model="contact.fields.object">
              <label for="object1">{{ $lang.contact.input4[1] }}</label>
            </div>
            <div>
              <input type="radio" id="object2" name="object" value="Don d'objets" v-model="contact.fields.object">
              <label for="object2">{{ $lang.contact.input4[2] }}</label>
            </div>
            <div>
              <input type="radio" id="object3" name="object" value="Collaborations" v-model="contact.fields.object">
              <label for="object3">{{ $lang.contact.input4[3] }}</label>
            </div>
            <div>
              <input type="radio" id="object4" name="object" value="Voyages" v-model="contact.fields.object">
              <label for="object4">{{ $lang.contact.input4[4] }}</label>
            </div>
            <div>
              <input type="radio" id="object5" name="object" value="Bénévolat" v-model="contact.fields.object">
              <label for="object5">{{ $lang.contact.input4[5] }}</label>
            </div>
          </div>

          <label for="body">{{ $lang.contact.input5 }}e</label>
          <textarea id="body" name="body" placeholder="Bonjour, ..." style="height:235px"
                    v-model="contact.fields.body"></textarea>
          <input type="submit" :value="$lang.contact.send">
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar'

const Airtable = require('airtable')
Airtable.configure({
  endpointUrl: 'https://api.airtable.com',
  apiKey: 'keyt3V8Znwk2RX4nF'
})
const base = Airtable.base('appHhqzoeR7gtnorv')
export default {
  name: 'Contact',
  components: { Navbar },
  data () {
    return {
      errorMsg: '',
      emptyField: null,
      contact: {
        fields: {
          firstname: null,
          lastname: null,
          email: null,
          number: null,
          object: null,
          body: null
        }
      }
    }
  },
  methods: {
    checkInputEmpty (el) {
      for (const input in el) {
        if (el[input] == null) {
          return true
        }
      }
      return false
    },
    createContact () {
      if (!this.checkInputEmpty(this.contact.fields)) {
        this.errorMsg = ''
        base('contact').create([this.contact], (err, records) => {
          if (err) {
            console.error(err)
            return
          }
          this.contact = {
            fields: {
              firstname: null,
              lastname: null,
              email: null,
              number: null,
              object: null,
              body: null
            }
          }
          // Autres actions à effectuer après la création du contact
          // ...
        })
      } else {
        this.errorMsg = 'Veuillez remplir tous les champs'
      }
    }

  },
  mounted () {
    document.title = 'Contact Jiboiana'
    document.querySelector('meta[name="description"]').setAttribute('content', localStorage.locale === 'fr' ? 'Nous contacter' : 'Contact us')
  }
}
</script>

<style scoped lang="scss">
input, textarea {
  border-radius: 8px;
  padding: 8px 12px;
  border: none;
}

label {
  color: white;
}

textarea {
  width: 100%;

}

.half-input {
  width: 100%;
  display: flex;
  flex-direction: column;

  &:last-child {
    margin-left: 24px;
  }
}

.radios {
  margin-top: 6px;
  margin-bottom: 8px;
  display: flex;
  flex-wrap: wrap;

  div {
    position: relative;
    width: fit-content;
    margin-right: 6px;
    margin-bottom: 8px;

    label {
      color: var(--color-text);
      height: 32px;
      //border: 2px solid var(--color-surface-divider);
      border-radius: 20px;
      padding: 4px 12px;
      background: var(--color-surface-divider);
    }

    input[type='radio'] {
      position: absolute;
      width: 100%;
      display: none;

      &:checked ~ label {
        background: #1BB4FF;
        color: white;
      }
    }
  }
}

.contact-info {
  padding-left: 12px;

  p {
    color: white;
  }

  .phone {
    display: flex;
    align-items: center;

    div {
      margin-left: 12px;
    }
  }

  .jib-infos {
    margin-top: 16px;
    display: flex;

    p {
      margin-left: 12px;
    }
  }
}

.contact-body {
  max-width: 1200px;
  margin: 0 auto;
  background: rgba(255, 255, 255, 0.12);
  backdrop-filter: blur(57px);
  padding: 16px 24px;
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 4px;
  height: 100%;
  display: flex;

  .info {
    width: 494px;
  }
}

.container {
  padding-top: 32px;
  position: relative;
  width: 100vw;
  height: 100vh;
  background: rgba(255, 255, 255, 0.12);
  backdrop-filter: blur(57px);
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 4px;

  .bg-img {
    z-index: 0;
    position: absolute;
    inset: 0;

    .grey-img {
      content: none;
      position: absolute;
      inset: 0;
      z-index: 2;
      background: linear-gradient(358.46deg, rgba(0, 0, 0, 0.342) -4.99%, rgba(1, 1, 1, 0.1824) 52.73%, rgba(1, 1, 1, 0.3572) 104.02%)
    }

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }

  }
}

input[type=submit] {
  border: none;
  color: white;
  max-height: 38px;
  padding: 7px;
  min-width: 180px;
  background: var(--color-primary);
  border-radius: var(--radius-default);
  float: right;
  width: 305px;
  margin-left: 24px;
}

@media screen and (max-width: 1200px) {
  .container {
    height: 100%;
  }
  .two-input {
    flex-direction: column;

    .half-input {
      &:last-child {
        margin-left: 0;
      }
    }
  }
}

@media (max-width: 886px) {
  .contact-body {
    flex-direction: column;

    form {
      margin-top: 48px;
    }
  }
}

@media (max-width: 425px) {
  input[type='submit'] {
    width: 100%;
  }
}
</style>
