const br = {
  nav: {
    project: 'Nossos projetos',
    event: 'Eventos',
    about: {
      abt: 'Quem somos nós',
      jiboina: 'quem é o Jiboiana',
      collab: 'Colaborações'
    }
  },
  head: {
    title1: 'Vamos apoiar juntos',
    title2: 'Guardiões da Terra'
  },
  footer: {
    title1: 'Support together the ',
    title2: 'The guardians of the Earth',
    contact: {
      title: 'Contact us',
      link: 'Get involved'
    },
    about: {
      title: 'About',
      link1: 'Team',
      link2: 'Partners',
      link3: 'Our actions',
      link4: 'Values'
    },
    ressources: {
      title: 'Ressources',
      link1: 'Contact',
      link2: 'Legal mentions',
      link3: 'Crédits'
    },
    follow: {
      title: 'Follow Us'
    }
  },
  home: {
    intro: {
      title: 'The little-known role of indigenous peoples ',
      represent: 'They represent only ',
      population: ' of the world\'s population, yet they protect ',
      terre: 'of the Earth\'s biodiversity.',
      p2: 'For centuries, Western cultures have separated humans from nature. That is why indigenous peoples are often forgotten from political and environmental discussions. They are the very embodiment of this much-needed union.',
      p3: 'Jiboiana\'s mission is to support them and highlight their role in the preservation of Nature.'
    },
    actions: {
      title: 'Our actions',
      action1: 'Reforestation',
      action2: 'Driking water',
      action3: 'Digital',
      action4: 'Right of peoples'
    },
    soutien: {
      title: 'Supporting their autonomy',
      p1: 'Jiboiana\'s projects aim to support indigenous communities on a long term scale, with their autonomy in mind: drinking water, food autonomy, nurseries for reforestation, solar energy, land purchase, and protection.\n' +
        'The fundamental idea is that once these projects are completed, they will no longer need us.',
      p2: 'In addition, we work with indigenous leaders and activists on political projects, aimed at protecting their rights and lands, as well as bringing their voices to the international stage.'
    },
    qlqPhotos: {
      title: 'Some pictures',
      subtitle: 'Because they deserve to be seen and heard.',
      btn: 'Discover'
    },
    equipe: {
      title: 'Jiboiana team',
      subtitle: 'Members of jiboiana team',
      p: 'We are not alone, and withouth them, nothing would have been possible !',
      btn: 'Meet the team'
    }
  },
  about: {
    title: 'Who is Jiboiana?',
    quote: '“Jiboiana is more than just an association: it’s a movement for the global recognition of Indigenous peoples.”',
    author: 'Léo Landon',
    p1: 'Our association was born in 2020 following several trips to the heart of the Amazon with the Huni Kuin people. Thanks to their generosity, they allowed us to participate in some of their rites and customs, allowing us to understand that humans can evolve in harmony with Nature, and to learn other ways of thinking and living.',
    p2: 'During our exchanges, they expressed the need to create a bridge between them and the international community, in order to make their culture and their struggle known. Preserving the forest, and therefore preserving their way of life, is not only a national issue; it concerns each and every one of us.',
    p3: 'This is why we created Jiboiana: because we are aware that our destiny is intimately linked to theirs. If the indigenous peoples disappear, the largest and richest ecosystems on the planet will also disappear. ',
    where: {
      title: 'Where are we from ?',
      p1: 'Our team is made up of seven French men and women, living between Paris, São Paulo, the Netherlands, and Bogota. We have also gathered a team of volunteers who help us carry out our actions.',
      p2: 'Art is an important part of all cultures; to honor this universality, we have partnerships with many artists: photographers, musicians, painters, directors... By offering their works, they allow us to raise awareness and funds for our projects.',
      p3: 'We warmly thank each and every one of you for your contributions which are as important as they are necessary!'
    },
    jiboina: {
      title: 'What does Jiboiana mean?',
      p: 'Jiboiana comes from the word "Jiboia", which in Portuguese means "Boa", the large snake found in tropical forests. In the culture of the Huni Kuin people, the Jiboia came to bring them the knowledge of their medicines and traditions. It is an animal that through its molt brings transformation. It is therefore regularly recurring in their prayers and songs.'
    },
    team: {
      title: 'The team'
    }
  },
  collab: {
    title: 'Partners and support of Jiboiana',
    p: 'Estamos tudos juntos',
    p2: 'Thanks to everyone of our parterns and collaborators who support us, with every cause that whe chose to defend. Without them, without you, we wouldn\'t be this far',
    title2: 'Partners et collaborators'
  },
  projects: {
    title: 'All our projects',
    btnDiscover: 'Discover'
  },
  events: {
    title: 'Events',
    type: 'Type',
    reserve: 'Save your spot'
  }

}

export default br
