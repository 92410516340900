import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import locale from './locales/index'
import * as Contentful from 'contentful'
import InstantSearch from 'vue-instantsearch/vue3/es'
// import 'instantsearch.css/themes/reset.css'
// import 'instantsearch.css/themes/algolia-min.css'

const vue = createApp(App).use(store).use(router)
const client = Contentful.createClient({
  space: 'ijpmkw98fb9q',
  accessToken: 'ZX3-Q_BUEEGfwohSQNUM58tDOuq_EXfppcw-83JPbb0'
})

vue.config.globalProperties.$contentful = client
vue.use(InstantSearch)
vue.config.globalProperties.$lang = locale

vue.mount('#app')

// 'X3LOXZO0EA',
// 'c8a34cb875b7cfa64b735126eaceb442'
