<template>
  <div class="text-align-center">
    <h2 class="margin-bot-12">{{ $lang.collab.title2 }}</h2>
    <div class="container-team">
      <profil v-for="p in partners" :title="p.fields.nom" :img="p.fields.logo.fields.file.url" type="partner" :key="p"
              :id="p.fields.partnerType.fields.type" :link="p.fields.lien"/>
    </div>
  </div>
</template>

<script>

import Profil from '@/components/Profil'

export default {
  name: 'Collab',
  components: { Profil },
  data () {
    return {
      select: 'Associations',
      sortList: [],
      partners: [],
      params: {
        skip: 0,
        locale: window.localStorage.getItem('locale') === 'en' || window.localStorage.getItem('locale') === 'br' ? 'en-US' : 'fr',
        content_type: 'partenaires',
        order: '-sys.createdAt'
      }
    }
  },
  created () {
    this.$contentful.getEntries(this.params)
      .then(res => {
        this.partners = res.items
      })
  }
}
</script>

<style scoped lang="scss">
.sort-btn {
  cursor: pointer;
  max-height: 34px;
  background: var(--color-surface-divider);
  border-radius: 37px;
  padding: 4px 12px;

  &:not(:last-child) {
    margin-right: 12px;
  }

  &.selected {
    background: var(--color-secondary);
    color: white;
  }
}

.sort-grid {
  button {
    margin-right: 12px;

  }
}

.container-team {
  display: grid;
  row-gap: 40px;
  grid-template-columns: repeat(auto-fit, minmax(357px, 1fr));
  justify-items: center;
}

</style>
