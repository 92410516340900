<template>
  <div>
    <loader v-if="loading"/>
    <div class="section" ref="top">
      <div class="wrap large">
        <div class="text-align-center main-text">
          <h1 class="margin-bot-24">{{ project.title }}</h1>
          <h2 class="">{{ project.hook }}</h2>
          <p class="title-xs text-soft">{{ project.subtitle }}</p>
          <p class="rich-text text-soft">{{ project.richText }}</p>
        </div>
        <div class="section">
          <div class="imgs" v-if="project.images">
            <div><img :src="project.images[0]['fields'].file.url" alt="" v-if="project.images[0]" loading="lazy"></div>
            <div>
              <img :src="project.images[1]['fields'].file.url" alt="" v-if="project.images[1]" loading="lazy">
              <img :src="project.images[2]['fields'].file.url" alt="" v-if="project.images[2]" loading="lazy">
            </div>
            <div><img :src="project.images[3]['fields'].file.url" alt="" v-if="project.images[3]" loading="lazy"></div>
          </div>
          <primary-btn class="btn" link="https://www.helloasso.com/associations/jiboiana/collectes/reforestation-en-amazonie-avec-le-peuple-autochtone-huni-kuin-par-jiboiana" :title="$lang.support"/>
        </div>
      </div>
      <separator/>
      <div class="section">
        <div class="wrap large">
          <h1 class="title-m margin-bot-32">Découvrir nos projets</h1>
          <div class="list-projects">
            <Card v-for="project in otherProjects" :title="project.fields.title" :subtitle="project.fields.subtitle"
                  :description="project.fields.richText" :img="project.fields.images[0].fields.file.url"
                  :key="project.fields.title" :id="project.sys.id"
                  :tag="project.fields.tag" type="project"/>
          </div>
        </div>
      </div>
    </div>
    <support/>
  </div>
</template>

<script>
import PrimaryBtn from '@/components/PrimaryBtn'
import Separator from '@/components/Separator'
import Support from '@/components/Support'
import Card from '@/components/Card'
import Loader from '@/components/Loader'

export default {
  name: 'Project',
  computed: {
    currentId () {
      return this.$route.params.slug
    }
  },
  watch: {
    currentId (newOne, oldOne) {
      if (newOne !== oldOne && this.$route.params) {
        window.scrollTo(0, 0)
        this.loading = true
        setTimeout(() => {
          this.load()
          if (this.$refs.top) {
            this.$refs.top.scrollIntoView({
              behavior: 'smooth',
              block: 'start',
              inline: 'nearest'
            })
          }
          this.loading = false
        }, 350)
      }
    }
  },
  components: {
    Card,
    Support,
    Separator,
    PrimaryBtn,
    Loader
  },
  data () {
    return {
      otherProjects: [],
      project: [],
      imgsId: [],
      loading: true
    }
  },
  methods: {
    load () {
      this.imgsId = []
      const id = this.$route.params.slug
      this.$contentful.getEntry(id)
        .then((entry) => {
          this.project = entry.fields
          document.title = this.project.title
          this.project.images.forEach(el => {
            this.imgsId.push(el.sys.id)
          })
          this.project.images = []
          this.imgsId.forEach(el => {
            this.$contentful.getAsset(el)
              .then((asset) => {
                this.project.images.push(asset)
                this.loading = false
              })
              .catch(console.error)
          })
        })
        .catch(console.error)
      this.$contentful.getEntries({
        skip: 0,
        limit: 3,
        locale: window.localStorage.getItem('locale') === 'en' || window.localStorage.getItem('locale') === 'br' ? 'en-US' : 'fr',
        content_type: 'project',
        order: '-sys.createdAt',
        'sys.id[ne]': this.currentId
      })
        .then(res => {
          this.otherProjects = res.items
        })
    }
  },
  mounted () {
    document.title = this.project.title
    document.querySelector('meta[name="description"]').setAttribute('content', this.project.hook)
  },
  created () {
    window.scrollTo(0, 0)
    this.load()
  }
}

</script>

<style scoped lang="scss">
.btn {
  margin: 0 auto;
}

.rich-text {
  &::first-letter {
    font-size: 2rem;
    font-weight: bold;
  }
}

h2, .text-soft {
  margin-bottom: 12px;
}

.main-text {
  max-width: 800px;
  margin: 0 auto;
}

.imgs {
  margin-bottom: 12px;
  height: 502px;
  display: grid;
  grid-template-columns: 1fr 180px;
  grid-template-rows: repeat(3, 1fr);
  grid-row-gap: 8px;
  grid-column-gap: 24px;

  div {
    width: 100%;
    height: 100%;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }

    &:first-child {
      grid-row-start: 1;
      grid-row-end: 4;
    }

    &:nth-child(2) {
      display: grid;
      grid-row-gap: 8px;
      grid-row-start: 1;
      grid-row-end: 3;

    }
  }
}

.list-projects {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 24px;
  grid-row-gap: 32px;
}

@media screen and (max-width: 576px) {
  .list-projects {
    grid-template-columns: none!important;
  }
}

@media screen and (max-width: 991px) {
  .list-projects {
    grid-template-columns: repeat(2, 1fr);
  }
  .imgs {
    height: unset;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: unset;
    grid-gap: 4px;

    div {
      &:first-child {
        grid-row-start: unset;
        grid-row-end: unset;
        grid-column-start: 1;
        grid-column-end: 3;
      }

      &:nth-child(2) {
        grid-gap: 4px;
        grid-row-start: 2;
        grid-column-start: 1;
        grid-column-end: 2;
      }
    }

  }
}

</style>
