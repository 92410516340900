<template>
  <div class="container">
    <div class="flex scroll">
      <div v-for="img in partners" class="container-support" :key="img">
        <img :src="img.fields.logo.fields.file.url" :alt="img" loading="lazy">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Support',
  data () {
    return {
      partners: []
    }
  },
  created () {
    this.$contentful.getEntries({
      skip: 0,
      locale: 'fr',
      content_type: 'partenaires',
      order: '-sys.createdAt',
      select: 'fields.logo'
    })
      .then(res => {
        this.partners = res.items
      })
  }
}
</script>

<style scoped lang="scss">

div {
  background: #F9FAFA;
  height: 102px;
  justify-content: space-around;
  align-items: center;

  .container-support {
    //max-height: 51px;

    img {
      width: 100%;
      height: 100%;
      object-position: center;
      object-fit: cover;
    }
  }
}

@media (max-width: 768px) {
  .container {
    width: 100vw;
    overflow: scroll;
  }
  div {
    width: fit-content;
  }
  .container-support {
    width: 136px;

    img {
      object-fit: contain !important;
    }

  }
}
</style>
