import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'

export default createStore({
  state: {
    lang: []
  },
  plugins: [createPersistedState()],
  getters: {
    getLang (state) {
      return state.lang
    }
  },
  mutations: {
    SET_LANG: (state, array) => {
      state.lang = array
    }
  },
  actions: {
    setLang (store, array) {
      store.commit('SET_LANG', array)
    }
  }
})
