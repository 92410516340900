<template>
    <div>
      <loader v-if="loading"/>
      <div class="section" ref="top">
        <div class="wrap large">
            <div class="article-container" v-if="article">
                <div v-html="parsedContent"/>
                <div class="right-bar">
                    <div class="article-infos">
                        <div class="flex space-between author" v-if="article.author">
                            <div>
                                <p class="author-name">{{ article.author.fields.name }}</p>
                                <p class="date">{{ moment(article.createdDate).format('L') }}</p>
                            </div>
                            <div class="author-img-container">
                                <img :src="article.author.fields.ProfilPic.fields.file.url" alt="">
                            </div>
                        </div>
                        <p class="tags">{{ article.tags }}</p>
                        <div class="bottom-infos">
                            <div class="follow-block">
                                <p>Nous suivre</p>
                                <div class="socials">
                                    <a href="https://www.facebook.com/association.jiboiana/" target="_blank">
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M14.9997 1.66699H12.4997C11.3946 1.66699 10.3348 2.10598 9.5534 2.88738C8.77199 3.66878 8.33301 4.72859 8.33301 5.83366V8.33366H5.83301V11.667H8.33301V18.3337H11.6663V11.667H14.1663L14.9997 8.33366H11.6663V5.83366C11.6663 5.61265 11.7541 5.40068 11.9104 5.2444C12.0667 5.08812 12.2787 5.00033 12.4997 5.00033H14.9997V1.66699Z" fill="white"/>
                                        </svg>
                                    </a>
                                    <a href="https://www.instagram.com/association_jiboiana/?hl=en" target="_blank">
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M14.167 1.66699H5.83366C3.53247 1.66699 1.66699 3.53247 1.66699 5.83366V14.167C1.66699 16.4682 3.53247 18.3337 5.83366 18.3337H14.167C16.4682 18.3337 18.3337 16.4682 18.3337 14.167V5.83366C18.3337 3.53247 16.4682 1.66699 14.167 1.66699Z" fill="white"/>
                                            <path d="M13.3337 9.47525C13.4366 10.1688 13.3181 10.8771 12.9952 11.4994C12.6723 12.1218 12.1614 12.6264 11.5351 12.9416C10.9088 13.2569 10.1991 13.3666 9.5069 13.2552C8.81468 13.1438 8.17521 12.817 7.67944 12.3212C7.18367 11.8254 6.85685 11.186 6.74546 10.4938C6.63408 9.80154 6.74379 9.09183 7.05901 8.46556C7.37423 7.8393 7.8789 7.32837 8.50123 7.00545C9.12356 6.68254 9.83187 6.56407 10.5254 6.66692C11.2328 6.77182 11.8878 7.10147 12.3935 7.60717C12.8992 8.11288 13.2288 8.76782 13.3337 9.47525Z" fill="#383838" stroke="#383838" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M14.583 5.41699H14.5913" stroke="#383838" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </a>
                                </div>
                            </div>
                            <primary-btn :title="$lang.support" link="https://www.helloasso.com/associations/jiboiana/collectes/reforestation-en-amazonie-avec-le-peuple-autochtone-huni-kuin-par-jiboiana" class="btn"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
        <separator/>
        <div class="section">
          <div class="wrap large">
            <h1 class="title-m margin-bot-32">Découvrir d'autres articles</h1>
            <div class="list-articles margin-bot-32">
            <div v-for="article in otherArticles" :key="article">
                <router-link type="button" :to="{name: 'article', params:{slug: article.sys.id}}">
                    <img :src="article.fields.cover.fields.file.url" alt="">
                    <p class="tags">{{ article.fields.tags }}</p>
                    <p class="date">{{moment(article.fields.createdDate).format('L') }}</p>
                    <p class="title">{{article.fields.title }}</p>
                </router-link>
            </div>
          </div>
          </div>
        </div>
      <support/>
    </div>
  </template>

<script>
// import PrimaryBtn from '@/components/PrimaryBtn'
import Separator from '@/components/Separator'
import Support from '@/components/Support'
import Loader from '@/components/Loader'
import MarkdownIt from 'markdown-it'
import PrimaryBtn from '@/components/PrimaryBtn'
import moment from 'moment'

export default {
  name: 'Article',
  computed: {
    currentId () {
      return this.$route.params.slug
    }
  },
  watch: {
    currentId (newOne, oldOne) {
      if (newOne !== oldOne) {
        window.scrollTo(0, 0)
        this.loading = true
        setTimeout(() => {
          this.load()
          if (this.$refs.top) {
            this.$refs.top.scrollIntoView({
              behavior: 'smooth',
              block: 'start',
              inline: 'nearest'
            })
          }
          this.loading = false
        }, 350)
      }
    }
  },
  components: {
    Support,
    Separator,
    PrimaryBtn,
    Loader
  },
  data () {
    return {
      otherArticles: [],
      article: [],
      imgsId: [],
      loading: true,
      parsedContent: '',
      moment: null
    }
  },
  methods: {
    load () {
      this.imgsId = []
      const id = this.$route.params.slug
      this.$contentful.getEntry(id)
        .then((entry) => {
          this.article = entry.fields
          document.title = entry.fields.title
          if (this.$route.name === 'article') {
            this.$root.customText = entry.fields.title
            this.$root.headerImg = entry.fields.cover.fields.file.url
          }
          this.loading = false
          this.parseContent()
        })
        .catch(console.error)
      this.$contentful.getEntries({
        skip: 0,
        limit: 3,
        locale: window.localStorage.getItem('locale') === 'en' || window.localStorage.getItem('locale') === 'br' ? 'en-US' : 'fr',
        content_type: 'article',
        order: '-sys.createdAt',
        'sys.id[ne]': this.currentId
      })
        .then(res => {
          this.otherArticles = res.items
        })
    },
    parseContent () {
      const md = new MarkdownIt()
      if (this.article) {
        this.parsedContent = md.render(this.article.articleText)
        // Recherche et remplacement des balises <img> par des balises <video> si elles contiennent un lien MP4
        const doc = new DOMParser().parseFromString(this.parsedContent, 'text/html')
        const H2 = doc.querySelectorAll('H2')
        H2.forEach(el => {
          el.style.margin = '64px 0px 12px 0px'
        })
        const H3 = doc.querySelectorAll('H3')
        H3.forEach(el => {
          el.style.margin = '32px 0px 6px 0px'
        })
        const paragraphs = doc.getElementsByTagName('p')
        // paragraphs[0].classList.add('first-paragraph')
        const styleSheet = document.styleSheets[0] // Assurez-vous de sélectionner la feuille de style appropriée
        styleSheet.addRule('p.first-paragraph::first-letter', 'font-size: 2rem; font-weight: bold; color: var(--color-text-soft);')
        for (let i = 0; i < paragraphs.length; i++) {
          const image = paragraphs[i].querySelector('img')
          const src = image?.getAttribute('src')
          const paragraph = paragraphs[i]
          paragraph.style.marginBottom = '32px'
          if (src && src.endsWith('.mp4')) {
            const video = document.createElement('video')
            const videoContainer = document.createElement('div')
            video.src = src
            video.controls = true
            video.style.width = '100%'
            video.style.height = '100%'
            video.style.objectFit = 'cover'
            videoContainer.appendChild(video)
            videoContainer.classList.add('video')
            image.parentNode.replaceChild(videoContainer, image)
          }
          if (src && !src.endsWith('.mp4')) {
            const img = document.createElement('img')
            const imgContainer = document.createElement('div')
            imgContainer.style.marginBottom = '12px'
            img.src = src
            img.style.width = '100%'
            img.style.height = '100%'
            img.style.objectFit = 'cover'
            img.style.width = '100%'
            imgContainer.appendChild(img)
            image.parentNode.replaceChild(imgContainer, image)
          }
          if (paragraph.querySelector('a') !== null) {
            paragraph.style.marginBottom = '4px'
          }
        }
        this.parsedContent = doc.body.innerHTML
      }
    }
  },
  mounted () {
    if (this.article) {
      document.title = this.article.title
      document.querySelector('meta[name="description"]').setAttribute('content', this.article.hook)
    }
  },
  created () {
    moment.locale('fr')
    this.moment = moment
    window.scrollTo(0, 0)
    this.load()
  }
}

</script>

  <style scoped lang="scss">
  .article-container{
    display: grid;
    grid-template-columns: repeat(12, 78px);
    column-gap: 24px;
    position: relative;

    div:first-child{
        grid-column-start: 1;
        grid-column-end: 8;
    }
    .right-bar{
        background: #000;
        grid-column: 8 / span 4;
        border-radius: 0px 0px 6px 6px;
        background: rgba(236, 108, 15, 0.49);
        padding: 24px;
        height: calc(100vh - 50px);
        position: sticky;
        top: 50px;

        .article-infos{
            height: 100%;
            display: flex;
            flex-direction: column;
            .author{
                align-items: center;

                div:first-child{
                    .author-name{
                        color: var(--text-strong, #091E42);
                        font-size: 20px;
                        font-weight: 700;
                        line-height: 32px;
                        letter-spacing: -0.2px;
                    }
                    .date{
                        color: #FFF;
                        font-size: 18px;
                        font-weight: 700;
                        line-height: 32px;
                        letter-spacing: -0.18px;
                    }
                }
            }
            .tags{
                text-align: center;
                        margin-top: 18px;
                        font-size: 20px;
                        font-weight: 500;
                        line-height: 28px;
                        letter-spacing: -0.2px;
                    }
            .author-img-container{
                width: 93px;
                height: 93px;
                background: #FACEAD;
                border-radius: 9999px;

                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            .bottom-infos{
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                flex: 1;
                color: white;

                .follow-block{
                    background: #201F1F;
                    border-radius: 6px;
                    padding: 12px 0;
                    align-items: center;
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    margin-bottom: 8px;

                    p{
                        color: white;
                        margin-bottom: 8px;
                    }
                    .socials{
                        display: inline;
                        text-align: center;
                    }
                }

                .btn{
                    width: 100%;
                    /deep/ button{
                        width: 100%;
                    }
                }
            }
        }
    }
  }
  .list-articles{
        display: grid;
        // grid-template-columns: repeat(auto-fit, minmax(283px, 283px));
        grid-template-columns: repeat(4, minmax(281px, 1fr));
        gap: 24px;

        div{
            width: 100%;

            img{
                object-fit: cover;
                width: 100%;
                height: 283px;
            }
            .tags,.date{
                color: var(--color-text-soft);
                text-align: center;
            }
            .title{
                color: var(--text-default, #172B4D);
                text-align: center;
                font-size: 24px;
                font-weight: 700;
                line-height: 32px;
            }
        }
    }
    @media screen and (max-width: 1440px) {
        .list-articles{
            grid-template-columns: repeat(auto-fit, minmax(281px, 1fr));
        }
    }
    @media screen and (max-width: 1360px) {
        .article-container{
            grid-template-rows: auto;
            grid-template-columns: 1fr;
        }
    }
    @media screen and (max-width: 1280px) {
        .article-container{

            div:first-child{
                grid-column-start: 1;
                grid-column-end: 1;
            }
            .right-bar{
                margin-bottom: 16px;
                grid-row-start: 1;
                background: rgba(236, 108, 15, 0.49);
                grid-column: inherit;
                position: inherit;
                padding: 24px;
                height: inherit;
                top: inherit;

                .tags{
                    margin-bottom: 8px;
                }
                .bottom-infos{
                    margin: 0 auto;
                }

            }
        }
    }

  </style>
