<template>
  <div class="section wrap-cgv">
    <div class="text-align-center" v-if="fr">
      <h1>Crédit et mentions légales</h1>
      <p class="subtitle"> Pour le bien être de tous</p>
    </div>
    <div class="text-align-center" v-else>
      <h1>Terms of service and credits </h1>
      <p class="subtitle"> For the good of everyone</p>
    </div>
    <div v-if="fr">
      <h2>Conditions d’utilisation</h2>
      <p> L’utilisation de ce site Internet vaut acceptation des conditions mentionnées ci-après. Jiboiana se réserve la
        possibilité d’engager des poursuites judiciaires en cas de violation de ces conditions d’utilisation, notamment
        en
        cas de non-respect et d’utilisation non autorisée des noms et emblèmes de Jiboiana, en cas d’ursupation
        d’identité, de violation des droits liés à l’information, au graphisme, aux textes, vidéos, documents audio,
        photos et images et plus généralement à l’ensemble du contenu du site.</p>

      <h2>Droits d’auteur</h2>
      <p>En application du Code français de la propriété intellectuelle et plus généralement des traités et accords
        internationaux comportant des dispositions relatives à la protection des droits d’auteurs, il est interdit pour
        un
        usage autre que privé ou pédagogique de reproduire, vendre, diffuser, modifier, publier intégralement ou
        partiellement, sous quelque forme que ce soit les données, la présentation ou l’organisation du site ainsi que
        les
        œuvres protégées par les droits d’auteur qui y figurent, sans autorisation écrite particulière et préalable,
        dont
        la demande doit être adressée au webmaster ou au président de l’association.</p>

      <h2>Liens</h2>
      <p>Les responsables de services en ligne qui créent des liens vers le présent site sont tenus d’en informer le
        webmaster. Ce site Internet peut comporter des liens vers d’autres sites qui ne sont pas sous contrôle
        permanent.
        Ces liens sont considérés comme utiles et pouvant enrichir l’information présente sur le site. Jiboiana
        n’endosse
        aucune responsabilité relative à ces sites, notamment en ce qui concerne l’exactitude ou la fiabilité des
        informations, des données et des opinions qui y figurent.</p>

      <h2>Protection des données personnelles</h2>
      <p>Pour en savoir plus sur la politique de protection des données personnelles : cliquez ici</p>

      <h2>Décharge de responsabilité</h2>
      <p>Jiboiana effectue les démarches nécessaires pour assurer la fiabilité des informations contenues dans ce site.
        Cependant, elle ne peut encourir une quelconque responsabilité du fait d’erreurs, d’omissions ou des résultats
        qui
        pourraient être obtenus à la suite de l’usage des informations diffusées. Jiboiana n’est tenue qu’à une
        obligation
        de moyens concernant les informations mises à disposition sur le site. De même, elle n’est en aucun cas
        responsable de l’infection par des virus ou de tout autre problème informatique ayant des effets
        destructeurs.</p>

      <h2>Les cookies</h2>
      <p> Qu'est-ce qu'un cookie ?
        Un cookie est une information déposée sur votre disque dur par le serveur du site que vous visitez. Ils ont
        notamment pour but de collecter des informations relatives à votre navigation sur les sites et de vous adresser
        des services personnalisés.</p>

      <h2>Comment refuser les cookies ?</h2>
      <p>Nous vous rappelons que le paramétrage peut modifier vos conditions d'accès à certains services nécessitant
        l'utilisation de cookies, comme l’accès à votre compte.<br>

        Vous pouvez à tout moment choisir de désactiver ces cookies dans les paramètres de votre navigateur. Vous pouvez
        accepter ou refuser les cookies au cas par cas ou bien les refuser systématiquement une fois pour toutes.<br>

        Pour en savoir plus sur la politique d'utilisation des cookies : cliquez ici</p>

      <h2>Design graphique</h2>
      <p>Laetitia Jeanpierre-berraud</p>

      <h2>Code</h2>
      <p>Titien Schott</p>

      <h2>Photos</h2>
      <p>Géraldine Rué, Clémentine Louis, Nicolas Cortes, Morgane Boulangé, Guilherme Meneghelli (Floresta TV)</p>

      <h2>Adresse physique</h2>
      <p>Jiboiana<br>
        Association humanitaire loi 1901<br>
        61 rue de Reuilly<br>
        75012 Paris<br>
        tel +33 6 67 87 96 45</p>

      <h2>Directeur de la publication</h2>
      <p>Laetitia jeanpierre-berraud</p>
    </div>
    <div v-else>
      <h2>Terms and conditions of use</h2>
      <p>By using this website you agree to the following terms and conditions. Jiboiana reserves the right to take
        legal
        action in case of violation of these terms of use, in particular in case of non-compliance and unauthorized use
        of
        Jiboiana's names and emblems, in case of identity theft, violation of rights related to information, graphics,
        texts, videos, audio documents, photos and images and more generally to the entire content of the site.</p>

      <h2>Copyright</h2>
      <p>In application of the French Code of Intellectual Property and more generally of international treaties and
        agreements containing provisions relating to the protection of copyright, it is forbidden for any use other than
        private or educational to reproduce, sell, distribute, modify, publish in whole or in part, in any form
        whatsoever, the data, presentation or organization of the site as well as the works protected by copyright which
        appear on the site, without specific and prior written authorization, the request for which must be addressed to
        the webmaster or to the president of the association.</p>

      <h2>Links</h2>
      <p>Those responsible for online services that create links to this site are required to inform the webmaster. This
        website may contain links to other sites that are not under permanent control. These links are considered useful
        and may enrich the information on the site. Jiboiana is not responsible for the accuracy or reliability of the
        information, data, and opinions contained on these sites.</p>

      <h2>Protection of personal data</h2>
      <p>To learn more about our privacy policy: click here</p>

      <h2>Disclaimer of liability</h2>
      <p>Jiboiana takes all necessary steps to ensure the reliability of the information contained in this site.
        However,
        it cannot be held liable for any errors, omissions or results that may be obtained from the use of the
        information
        provided. Jiboiana is only bound by an obligation of means concerning the information made available on the
        site.
        Likewise, Jiboiana is in no way responsible for the infection by viruses or any other computer problem with
        destructive effects.</p>

      <h2>Cookies</h2>
      <p>What is a cookie?<br>
        A cookie is a piece of information placed on your hard drive by the server of the site you are visiting. Their
        purpose is to collect information about your navigation on the sites and to send you personalized services.</p>

      <h2>How to refuse cookies?</h2>
      <p>We remind you that the setting may change your access conditions to certain services requiring the use of
        cookies,
        such as access to your account.<br>

        You can choose to disable these cookies at any time in your browser settings. You can accept or reject cookies
        on
        a case-by-case basis or you can reject them systematically once and for all.<br>

        To learn more about our cookie policy: click here</p>

      <h2>Graphic design</h2>
      <p>Laetitia Jeanpierre-berraud</p>

      <h2>Code</h2>
      <p>Titien Schott</p>

      <h2>Photos</h2>
      <p>Géraldine Rué, Clémentine Louis, Nicolas Cortes, Morgane Boulangé, Guilherme Meneghelli (Floresta TV)</p>

      <h2>English Translation</h2>
      <p>Kelly Dochy</p>

      <h2>Physical adress</h2>
      <p>Jiboiana <br>
        Humanitarian association law 1901<br>
        61 rue de Reuilly<br>
        75012 Paris<br>
        tel +33 6 67 87 96 45</p>

      <h2>Director of the publication</h2>
      <p>Laetitia jeanpierre-berraud</p>

    </div>
  </div>
</template>

<script>
export default {
  name: 'CGV',
  computed: {
    fr () {
      return localStorage.getItem('locale').includes('fr')
    }
  },
  mounted () {
    this.load = false
    document.title = 'CGV'
    document.querySelector('meta[name="description"]').setAttribute('content', this.$lang.head.title1 + ' ' + this.$lang.head.title2)
  }
}
</script>

<style scoped>
.wrap-cgv {
  max-width: 790px;
  margin: 0 auto;
  padding: 100px 32px;
}

h2 {
  font-size: 1rem;
  line-height: 1.5rem;
  font-family: Gilroy-Bold;
  color: var(--color-text-strong);
}

.subtitle {
  margin-top: 12px;
  font-family: 'Gilroy-Bold';
  font-size: 24px;
  line-height: 32px;
}

p {
  margin-bottom: 32px;
}
</style>
