<template>
  <div class="search-container">
    <ais-instant-search :search-client="searchClient" index-name="dev_JIBOIANA">
      <ais-search-box class="input" :placeholder="'Recherchez'" :autofocus="boolean"  @reset="showResults = false" @input="handleInputChange"/>
      <ais-configure
        :hitsPerPage="4"
        :attributesToSnippet="['richText', 'hook', 'title', 'articleText', 'tags']"
      />
      <!-- <ais-highlight attribute="hook" :hit="hit" /> -->
      <div class="hits">
        <ais-hits  v-if="showResults">
          <template v-slot:item="{ item }">
          <router-link :to="'/project/'+item.objectID" >
              <label>Project</label>
              <h4>
                <ais-snippet attribute="title.fr" :hit='item'/>
              </h4>
              <p class="small">
                <ais-snippet attribute="hook.fr" :hit='item' class="small" :count="3"/>
              </p>
          </router-link>
          </template>
        </ais-hits>
        <hr />
        <ais-index index-name="dev_JiboianaArticle">
          <ais-hits  v-if="showResults">
            <template v-slot:item="{ item }">
              <router-link :to="{ name: 'article', params: { slug: item.objectID } }" style="width: 100%;">
                  <div class="flex space-between">
                    <label>Article</label>
                    <div class="flex space-between">
                      <p class="small" style="text-align: end;" v-if="authors.length > 0">{{ authors.find(author => author.id === item.author['fr'].sys.id ).name }}</p>
                      <div class="container-img-author">
                        <img :src="authors.find(author => author.id === item.author['fr'].sys.id ).pic" alt="">
                      </div>
                    </div>
                  </div>
                  <h4>
                    <ais-snippet attribute="title.fr" :hit='item'/>
                  </h4>
                  <p class="small">
                    <ais-snippet attribute="articleText.fr" :hit='item' class="small" :count="3" :truncate-text="true"/>
                </p>
                <p class="small">
                    <ais-snippet attribute="tags.fr" :hit='item' class="small" :count="3" :truncate-text="true"/>
                </p>
              </router-link>
            </template>
          </ais-hits>
        </ais-index>
        <ais-powered-by theme="dark" class="small padding-top-8" v-if="showResults"/>
      </div>
    </ais-instant-search >
  </div>
</template>

<script>
import algoliasearch from 'algoliasearch/lite'
import 'instantsearch.css/themes/satellite-min.css'
export default {
  data () {
    return {
      searchClient: algoliasearch('X3LOXZO0EA', 'c8a34cb875b7cfa64b735126eaceb442'),
      showHits: false,
      leaveFromHits: false,
      showResults: false,
      authors: []
    }
  },
  methods: {
    handleInputChange (event) {
      console.log(event.target)
      event.target.value.lenght === 0 ? this.showResults = false : this.showResults = true
      this.showResults = event.target.value.trim() !== ''
    }
  },
  created () {
    this.$contentful.getEntries({
      skip: 0,
      locale: window.localStorage.getItem('locale') === 'en' || window.localStorage.getItem('locale') === 'br' ? 'en-US' : 'fr',
      content_type: 'team'
    })
      .then(res => {
        this.authors = res.items.map(el => {
          // console.log(el)
          const author = {
            name: el.fields.name,
            pic: el.fields.ProfilPic.fields.file.url,
            id: el.sys.id
          }
          return author
        }
        )
      })
  }
}
</script>

<style lang="scss">
hr{
  display: none;
}
.container-img-author{
  width: 32px;
  height: 32px;
  object-fit: contain;
  border-radius: 999px;

  img{
    width: 100%;
  }
}
.search-container{
  display: block;
  width: 0px;
  max-width: 0%;
  height: 32px;
  position: absolute;
  right: 0;
  bottom: -46px;
  transition: 0.2s;
  &.reveal{
    width: 314px;
    max-width: 100%;

  .ais-SearchBox-form::before{
    content:"";
    height: 100%;
    width: 36px;
    left: 0;
    top:8px ;
    background: var(--color-primary);
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }
  .ais-SearchBox-form{
    opacity: 1;
  }
  }

  .ais-SearchBox-input {
    // display: none;
    border: none;
    padding-left: 45px;
    font-family: 'Gilroy-Medium';
    font-size: 16px;
    line-height: 24px;
    color: #8993A4;
}
.ais-SearchBox-form{
  background-color: inherit;
  height: 32px;
  opacity: 0;
}

&.mobile{
  position: relative;
  margin: 48px auto 0 24px;
  bottom: inherit;
  width: calc(100% - 48px);
}
}
.ais-Hits-item{
    padding: 8px;
    display: flex;
}
.hits{
  // display: block
}
.ais-SearchBox {
  padding-bottom: 8px
}
// .hits:hover{
//   display: block;
// }
// .input:hover + .hits{
//  display: block;
// }
</style>
