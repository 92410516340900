<template>
  <div class="">
    <div class="section">
      <div class="wrap large container-text">
        <div class="text-align-center" v-if="about">
          <h1 class="title-l margin-bot-24">{{ about[0].fields.title }}</h1>
          <p class="quote sub title-m margin-bot-12">{{ about[0].fields.citation }}</p>
          <p class="quote margin-bot-12 strong text-soft">{{ about[0].fields.citationAuthor }}</p>
        </div>
        <div v-html="parsedContent"></div>
        <img src="../assets/imgs/fullLogo.svg" alt="">
      </div>
    </div>
  <separator/>
    <div class="section" id="team">
      <div class="wrap large">
        <team/>
      </div>
    </div>
  </div>
</template>

<script>
import Separator from '@/components/Separator'
import Team from '@/components/Team'
import MarkdownIt from 'markdown-it'

export default {
  name: 'Jiboiana',
  components: {
    Team,
    Separator
  },
  data () {
    return {
      about: null,
      parsedContent: ''
    }
  },
  created () {
    this.$contentful.getEntries({
      skip: 0,
      locale: window.localStorage.getItem('locale') === 'en' || window.localStorage.getItem('locale') === 'br' ? 'en-US' : 'fr',
      content_type: 'about'
    })
      .then(res => {
        this.about = res.items
        this.parseContent()
      })
  },
  methods: {
    parseContent () {
      const md = new MarkdownIt()
      if (this.about && this.about.length > 0) {
        this.parsedContent = md.render(this.about[0].fields.content)
        // Recherche et remplacement des balises <img> par des balises <video> si elles contiennent un lien MP4
        const doc = new DOMParser().parseFromString(this.parsedContent, 'text/html')
        const H2 = doc.querySelectorAll('H2')
        H2.forEach(el => {
          el.style.margin = '64px 0px 12px 0px'
        })
        const paragraphs = doc.getElementsByTagName('p')
        paragraphs[0].classList.add('first-paragraph')
        const styleSheet = document.styleSheets[0] // Assurez-vous de sélectionner la feuille de style appropriée
        styleSheet.addRule('p.first-paragraph::first-letter', 'font-size: 2rem; font-weight: bold; color: var(--color-text-soft);')
        for (let i = 0; i < paragraphs.length; i++) {
          const image = paragraphs[i].querySelector('img')
          const src = image?.getAttribute('src')
          const paragraph = paragraphs[i]
          paragraph.style.marginBottom = '32px'
          // Vérifier si le paragraphe est le premier avant un H2 ou le premier d'une div
          if (
            (paragraph.previousElementSibling && paragraph.previousElementSibling.tagName === 'H2') ||
            (paragraph.parentNode && paragraph.parentNode.tagName === 'DIV' && paragraph.parentNode.firstElementChild === paragraph)
          ) {
            paragraph.classList.add('first-paragraph')
          }
          if (src && src.endsWith('.mp4')) {
            const video = document.createElement('video')
            const videoContainer = document.createElement('div')
            video.src = src
            video.controls = true
            video.style.width = '100%'
            video.style.height = '100%'
            video.style.objectFit = 'cover'
            videoContainer.appendChild(video)
            videoContainer.classList.add('video')
            image.parentNode.replaceChild(videoContainer, image)
          }
          if (src && !src.endsWith('.mp4')) {
            const img = document.createElement('img')
            const imgContainer = document.createElement('div')
            img.src = src
            img.style.width = '100%'
            img.style.height = '100%'
            img.style.objectFit = 'cover'
            img.style.width = '100%'
            imgContainer.appendChild(img)
            image.parentNode.replaceChild(imgContainer, image)
          }
        }
        this.parsedContent = doc.body.innerHTML
      }
    }
  },
  mounted () {
    document.title = 'Jiboiana'
    document.querySelector('meta[name="description"]').setAttribute('content', this.$lang.about.title)
  }
}
</script>

<style scoped lang="scss">
h2 {
  margin-bottom: 12px;
}

.container-text {
  max-width: 800px;
}

p {
  &:not(.sub) {
    color: var(--color-text-soft);
  }

  &.first-letter {
    &::first-letter {
      font-size: 2rem;
      font-weight: bold;
    }
  }

  &:not(.sub):not(.quote) {
    margin-bottom: 32px;
  }
}

section {
  margin-bottom: 64px;
}

.video {
  width: 100%;
  height: 318px;

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

img {
  max-width: 334px;
  width: 100%;
  margin: 0 auto;
  display: block;

  svg {
    width: 100%;
    object-fit: cover;
  }
}
</style>
