<template>
  <div class="container-profil">
    <div class="img-container margin-bot-12" :style="type==='team'? 'background: #FACEAD;': ''">
      <img :src="img" alt="" loading="lazy">
    </div>
    <div v-if="type === 'team'">
      <p >{{ role }}</p>
      <p class="title-m">{{ title }}</p>
    </div>
    <a v-else class="title-m" :href="link" target="_blank">{{ title }}</a>

  </div>
</template>

<script>
export default {
  name: 'Profil',
  props: {
    img: String,
    team: Boolean,
    title: String,
    role: String,
    type: String,
    link: String
  }
}
</script>

<style scoped lang="scss">
.container-profil {
  max-width: 357px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .img-container {
    overflow: hidden;
    border-radius: 1000px;
    width: 200px;
    height: 200px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

</style>
