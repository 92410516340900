<template>
  <footer class="container">

    <div class="logo-mob">
      <svg fill="none" height="29" viewBox="0 0 120 29" width="120" xmlns="http://www.w3.org/2000/svg">
        <path d="M72.6128 12.038L77.4667 2.36986L82.3205 12.038H72.6128Z" stroke="white" stroke-width="2.12659"/>
        <path d="M107.552 12.038L112.406 2.36986L117.26 12.038H107.552Z" stroke="white" stroke-width="2.12659"/>
        <path
          d="M3.97114 13.2719C2.08053 13.2719 0.757099 12.51 0.000854492 10.986L2.04615 9.81727C2.42427 10.5735 3.06594 10.9516 3.97114 10.9516C4.60134 10.9516 5.07113 10.8027 5.3805 10.5048C5.68988 10.2068 5.84456 9.80008 5.84456 9.28446V1.00014H8.19923V9.28446C8.19923 10.5449 7.80392 11.5245 7.0133 12.2235C6.22268 12.9225 5.20863 13.2719 3.97114 13.2719Z"
          fill="white"/>
        <path d="M16.1799 1.00014H18.5518V13.0313H16.1799V1.00014Z" fill="white"/>
        <path
          d="M33.8929 6.79229C34.947 7.39958 35.4741 8.31624 35.4741 9.54227C35.4741 10.5621 35.1132 11.3985 34.3913 12.0516C33.6694 12.7047 32.7814 13.0313 31.7273 13.0313H26.6226V1.00014H31.3663C32.3976 1.00014 33.2627 1.32098 33.9616 1.96264C34.672 2.59284 35.0272 3.40065 35.0272 4.38606C35.0272 5.38292 34.6491 6.185 33.8929 6.79229ZM31.3663 3.21732H28.9945V5.86417H31.3663C31.733 5.86417 32.0366 5.73813 32.2773 5.48605C32.5293 5.23397 32.6554 4.91887 32.6554 4.54075C32.6554 4.16262 32.5351 3.84752 32.2944 3.59544C32.0538 3.34336 31.7445 3.21732 31.3663 3.21732ZM31.7273 10.8141C32.1283 10.8141 32.4606 10.6824 32.7241 10.4188C32.9877 10.1438 33.1194 9.80008 33.1194 9.38758C33.1194 8.98654 32.9877 8.65426 32.7241 8.39072C32.4606 8.11572 32.1283 7.97822 31.7273 7.97822H28.9945V10.8141H31.7273Z"
          fill="white"/>
        <path
          d="M52.9961 11.4673C51.7815 12.6704 50.3034 13.2719 48.5618 13.2719C46.8201 13.2719 45.342 12.6704 44.1274 11.4673C42.9243 10.2527 42.3228 8.76884 42.3228 7.01573C42.3228 5.26261 42.9243 3.7845 44.1274 2.58138C45.342 1.36681 46.8201 0.759521 48.5618 0.759521C50.3034 0.759521 51.7815 1.36681 52.9961 2.58138C54.2107 3.7845 54.818 5.26261 54.818 7.01573C54.818 8.76884 54.2107 10.2527 52.9961 11.4673ZM45.7946 9.85164C46.5394 10.585 47.4618 10.9516 48.5618 10.9516C49.6618 10.9516 50.5841 10.585 51.3289 9.85164C52.0737 9.10686 52.4461 8.16155 52.4461 7.01573C52.4461 5.8699 52.0737 4.9246 51.3289 4.17981C50.5841 3.43502 49.6618 3.06263 48.5618 3.06263C47.4618 3.06263 46.5394 3.43502 45.7946 4.17981C45.0498 4.9246 44.6774 5.8699 44.6774 7.01573C44.6774 8.16155 45.0498 9.10686 45.7946 9.85164Z"
          fill="white"/>
        <path d="M62.3287 1.00014H64.7006V13.0313H62.3287V1.00014Z" fill="white"/>
        <path
          d="M97.0566 1.00014H99.4285V13.0313H97.6238L92.4676 5.67511V13.0313H90.0957V1.00014H91.9004L97.0566 8.33916V1.00014Z"
          fill="white"/>
        <g class="bot-logo" opacity="0.2">
          <path d="M72.612 16.8451L77.4658 26.5132L82.3196 16.8451H72.612Z" stroke="white"
                stroke-width="2.12659"/>
          <path d="M107.552 16.8451L112.405 26.5132L117.259 16.8451H107.552Z" stroke="white"
                stroke-width="2.12659"/>
          <path
            d="M3.97028 15.6109C2.07967 15.6109 0.756244 16.3729 0 17.8968L2.0453 19.0655C2.42342 18.3093 3.06508 17.9312 3.97028 17.9312C4.60049 17.9312 5.07028 18.0801 5.37965 18.378C5.68902 18.676 5.84371 19.0827 5.84371 19.5984V27.8827H8.19838V19.5984C8.19838 18.3379 7.80307 17.3583 7.01245 16.6593C6.22183 15.9604 5.20777 15.6109 3.97028 15.6109Z"
            fill="white"/>
          <path d="M16.1791 27.8827H18.5509V15.8515H16.1791V27.8827Z" fill="white"/>
          <path
            d="M33.892 22.0905C34.9462 21.4832 35.4733 20.5666 35.4733 19.3405C35.4733 18.3208 35.1123 17.4843 34.3905 16.8312C33.6686 16.1781 32.7806 15.8515 31.7264 15.8515H26.6218V27.8827H31.3655C32.3967 27.8827 33.2618 27.5618 33.9608 26.9202C34.6712 26.29 35.0264 25.4822 35.0264 24.4968C35.0264 23.4999 34.6483 22.6978 33.892 22.0905ZM31.3655 25.6655H28.9936V23.0186H31.3655C31.7321 23.0186 32.0358 23.1447 32.2764 23.3968C32.5285 23.6488 32.6545 23.9639 32.6545 24.3421C32.6545 24.7202 32.5342 25.0353 32.2936 25.2874C32.053 25.5395 31.7436 25.6655 31.3655 25.6655ZM31.7264 18.0687C32.1275 18.0687 32.4597 18.2004 32.7233 18.464C32.9868 18.739 33.1186 19.0827 33.1186 19.4952C33.1186 19.8963 32.9868 20.2286 32.7233 20.4921C32.4597 20.7671 32.1275 20.9046 31.7264 20.9046H28.9936V18.0687H31.7264Z"
            fill="white"/>
          <path
            d="M52.9953 17.4156C51.7807 16.2124 50.3026 15.6109 48.5609 15.6109C46.8193 15.6109 45.3411 16.2124 44.1266 17.4156C42.9234 18.6301 42.3219 20.114 42.3219 21.8671C42.3219 23.6202 42.9234 25.0983 44.1266 26.3014C45.3411 27.516 46.8193 28.1233 48.5609 28.1233C50.3026 28.1233 51.7807 27.516 52.9953 26.3014C54.2098 25.0983 54.8171 23.6202 54.8171 21.8671C54.8171 20.114 54.2098 18.6301 52.9953 17.4156ZM45.7937 19.0312C46.5385 18.2978 47.4609 17.9312 48.5609 17.9312C49.6609 17.9312 50.5833 18.2978 51.3281 19.0312C52.0729 19.776 52.4453 20.7213 52.4453 21.8671C52.4453 23.0129 52.0729 23.9582 51.3281 24.703C50.5833 25.4478 49.6609 25.8202 48.5609 25.8202C47.4609 25.8202 46.5385 25.4478 45.7937 24.703C45.049 23.9582 44.6766 23.0129 44.6766 21.8671C44.6766 20.7213 45.049 19.776 45.7937 19.0312Z"
            fill="white"/>
          <path d="M62.3279 27.8827H64.6997V15.8515H62.3279V27.8827Z" fill="white"/>
          <path
            d="M97.0558 27.8827H99.4276V15.8515H97.6229L92.4667 23.2077V15.8515H90.0949V27.8827H91.8995L97.0558 20.5437V27.8827Z"
            fill="white"/>
        </g>
      </svg>
    </div>
    <svg width="849" height="371" viewBox="0 0 849 371" fill="none" xmlns="http://www.w3.org/2000/svg" class="snake">
      <g opacity="0.1">
        <mask id="mask0_2808_83296" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="849"
              height="371">
          <path
            d="M848.83 183.904C810.03 232.007 749.342 219.981 723.849 207.955C723.849 207.955 716.91 205.903 712.354 205.954C706.463 206.019 697.733 209.674 697.733 209.674C691.789 213.443 619.387 252.28 582.079 237.161C544.771 222.043 546.637 170.161 552.233 146.109C548.502 130.075 532.833 101.099 500.002 113.468C467.171 125.837 517.922 219.06 524.252 252.623C538.487 368.844 450.88 378.607 404.867 366.009C366.937 347.111 303.389 282.516 352.636 175.315C414.194 41.3134 340.859 42.3823 306.001 46.4673C271.143 50.5523 286.099 129.189 302.27 225.136C320.178 337.834 217.445 349.35 170.188 340.76C86.6183 328.39 199.008 196.868 144.014 181.614C117.505 173.463 109.607 242.974 71.1024 241.527C40.4251 240.375 -1.80951 214.963 0.0599976 207.826C84.0027 226.724 57.9037 197.648 126.923 151.263C182.139 114.155 197.377 141.857 199.674 170.161C206.725 177.431 172.057 292.08 183.275 299.569C194.492 307.058 211.318 312.675 239.361 295.824C261.795 282.344 260.802 252.243 257.501 238.878C257.501 238.878 169.827 -5.07398 330.251 0.0805481C493.04 5.31109 391.809 199.365 380.617 226.852C369.424 254.339 391.809 341.956 451.502 316.186C511.194 290.417 446.859 192.25 437.532 164.763C415.147 -5.31566 619.387 56.774 597.002 146.108C579.094 217.576 647.368 194.211 647.368 194.211C647.368 194.211 693.629 185.621 714.522 171.878C771.23 130.647 827.689 162.716 848.83 183.904Z"
            fill="white"/>
        </mask>
        <g mask="url(#mask0_2808_83296)">
          <path
            d="M25.7247 1.00818H5.91143C5.73295 1.00818 5.56197 1.07998 5.43699 1.2074L-1.93429 8.72321C-2.05493 8.84622 -2.12308 9.01128 -2.12436 9.18357L-2.27228 29.0043C-2.27364 29.1868 -2.1999 29.3618 -2.06836 29.4883L5.43945 36.7074C5.56324 36.8264 5.72831 36.8929 5.90004 36.8929H25.8805C26.0591 36.8929 26.2301 36.821 26.3551 36.6936L33.5703 29.334C33.6933 29.2086 33.7616 29.0395 33.7603 28.8638L33.6123 9.17973C33.611 9.00972 33.5446 8.84668 33.4268 8.72413L26.2037 1.21212C26.0784 1.08182 25.9054 1.00818 25.7247 1.00818Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M21.8095 7.65356H9.59674C9.41826 7.65356 9.24728 7.72536 9.12231 7.85279L4.64376 12.4192C4.52312 12.5422 4.45497 12.7072 4.45368 12.8795L4.36248 25.0998C4.36112 25.2822 4.43486 25.4573 4.56641 25.5837L9.12476 29.9668C9.24856 30.0858 9.41362 30.1523 9.58536 30.1523H21.9086C22.0871 30.1523 22.2582 30.0805 22.3831 29.953L26.7624 25.4862C26.8854 25.3607 26.9537 25.1916 26.9524 25.016L26.8611 12.8757C26.8598 12.7057 26.7934 12.5426 26.6756 12.4201L22.2885 7.8575C22.1632 7.7272 21.9902 7.65356 21.8095 7.65356Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M31.229 -8.29578H1.22811C1.04963 -8.29578 0.878648 -8.22398 0.753674 -8.09656L-10.495 3.37266C-10.6156 3.49566 -10.6838 3.66072 -10.6851 3.83301L-10.909 33.8414C-10.9104 34.0239 -10.8366 34.1989 -10.7051 34.3254L0.756127 45.3458C0.879919 45.4648 1.04498 45.5313 1.21672 45.5313H31.4608C31.6394 45.5313 31.8104 45.4594 31.9354 45.332L42.9519 34.095C43.0749 33.9696 43.1432 33.8005 43.1419 33.6248L42.9179 3.82917C42.9166 3.65917 42.8502 3.49612 42.7324 3.37357L31.708 -8.09184C31.5827 -8.22214 31.4097 -8.29578 31.229 -8.29578Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M79.7794 1.00818H59.9661C59.7876 1.00818 59.6167 1.07998 59.4917 1.2074L52.1204 8.72321C51.9998 8.84622 51.9316 9.01128 51.9303 9.18357L51.7824 29.0043C51.781 29.1868 51.8548 29.3618 51.9863 29.4883L59.4941 36.7074C59.6179 36.8264 59.783 36.8929 59.9547 36.8929H79.9352C80.1137 36.8929 80.2848 36.821 80.4097 36.6936L87.625 29.334C87.748 29.2086 87.8163 29.0395 87.815 28.8638L87.667 9.17973C87.6657 9.00972 87.5993 8.84668 87.4815 8.72413L80.2584 1.21212C80.1331 1.08182 79.9601 1.00818 79.7794 1.00818Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M75.8642 7.65356H63.6514C63.473 7.65356 63.302 7.72536 63.177 7.85279L58.6984 12.4192C58.5778 12.5422 58.5097 12.7072 58.5084 12.8795L58.4172 25.0998C58.4158 25.2822 58.4896 25.4573 58.6211 25.5837L63.1795 29.9668C63.3032 30.0858 63.4683 30.1523 63.64 30.1523H75.9633C76.1418 30.1523 76.3128 30.0805 76.4378 29.953L80.8171 25.4862C80.9401 25.3607 81.0084 25.1916 81.007 25.016L80.9158 12.8757C80.9145 12.7057 80.8481 12.5426 80.7303 12.4201L76.3432 7.8575C76.2179 7.7272 76.0449 7.65356 75.8642 7.65356Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M85.2836 -8.29578H55.2828C55.1043 -8.29578 54.9333 -8.22398 54.8084 -8.09656L43.5597 3.37266C43.4391 3.49566 43.3709 3.66072 43.3696 3.83301L43.1457 33.8414C43.1443 34.0239 43.2181 34.1989 43.3496 34.3254L54.8108 45.3458C54.9346 45.4648 55.0997 45.5313 55.2714 45.5313H85.5155C85.694 45.5313 85.8651 45.4594 85.99 45.332L97.0066 34.095C97.1296 33.9696 97.1979 33.8005 97.1966 33.6248L96.9726 3.82917C96.9713 3.65917 96.9049 3.49612 96.7871 3.37357L85.7627 -8.09184C85.6374 -8.22214 85.4644 -8.29578 85.2836 -8.29578Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M133.835 1.00818H114.022C113.843 1.00818 113.672 1.07998 113.547 1.2074L106.176 8.72321C106.055 8.84622 105.987 9.01128 105.986 9.18357L105.838 29.0043C105.837 29.1868 105.91 29.3618 106.042 29.4883L113.55 36.7074C113.674 36.8264 113.839 36.8929 114.01 36.8929H133.991C134.169 36.8929 134.34 36.821 134.465 36.6936L141.681 29.334C141.804 29.2086 141.872 29.0395 141.871 28.8638L141.723 9.17973C141.721 9.00972 141.655 8.84668 141.537 8.72413L134.314 1.21212C134.189 1.08182 134.016 1.00818 133.835 1.00818Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M129.92 7.65356H117.707C117.529 7.65356 117.358 7.72536 117.233 7.85279L112.754 12.4192C112.633 12.5422 112.565 12.7072 112.564 12.8795L112.473 25.0998C112.471 25.2822 112.545 25.4573 112.677 25.5837L117.235 29.9668C117.359 30.0858 117.524 30.1523 117.696 30.1523H130.019C130.197 30.1523 130.369 30.0805 130.493 29.953L134.873 25.4862C134.996 25.3607 135.064 25.1916 135.063 25.016L134.971 12.8757C134.97 12.7057 134.904 12.5426 134.786 12.4201L130.399 7.8575C130.274 7.7272 130.101 7.65356 129.92 7.65356Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M139.339 -8.29578H109.338C109.16 -8.29578 108.989 -8.22398 108.864 -8.09656L97.6154 3.37266C97.4947 3.49566 97.4266 3.66072 97.4253 3.83301L97.2014 33.8414C97.2 34.0239 97.2737 34.1989 97.4053 34.3254L108.866 45.3458C108.99 45.4648 109.155 45.5313 109.327 45.5313H139.571C139.75 45.5313 139.921 45.4594 140.046 45.332L151.062 34.095C151.185 33.9696 151.254 33.8005 151.252 33.6248L151.028 3.82917C151.027 3.65917 150.961 3.49612 150.843 3.37357L139.818 -8.09184C139.693 -8.22214 139.52 -8.29578 139.339 -8.29578Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M187.89 1.00818H168.076C167.898 1.00818 167.727 1.07998 167.602 1.2074L160.231 8.72321C160.11 8.84622 160.042 9.01128 160.041 9.18357L159.893 29.0043C159.891 29.1868 159.965 29.3618 160.097 29.4883L167.604 36.7074C167.728 36.8264 167.893 36.8929 168.065 36.8929H188.046C188.224 36.8929 188.395 36.821 188.52 36.6936L195.735 29.334C195.858 29.2086 195.927 29.0395 195.925 28.8638L195.777 9.17973C195.776 9.00972 195.71 8.84668 195.592 8.72413L188.369 1.21212C188.243 1.08182 188.07 1.00818 187.89 1.00818Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M183.975 7.65356H171.762C171.584 7.65356 171.413 7.72536 171.288 7.85279L166.809 12.4192C166.689 12.5422 166.62 12.7072 166.619 12.8795L166.528 25.0998C166.527 25.2822 166.6 25.4573 166.732 25.5837L171.29 29.9668C171.414 30.0858 171.579 30.1523 171.751 30.1523H184.074C184.253 30.1523 184.424 30.0805 184.549 29.953L188.928 25.4862C189.051 25.3607 189.119 25.1916 189.118 25.016L189.027 12.8757C189.025 12.7057 188.959 12.5426 188.841 12.4201L184.454 7.8575C184.329 7.7272 184.156 7.65356 183.975 7.65356Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M193.394 -8.29578H163.394C163.215 -8.29578 163.044 -8.22398 162.919 -8.09656L151.671 3.37266C151.55 3.49566 151.482 3.66072 151.48 3.83301L151.257 33.8414C151.255 34.0239 151.329 34.1989 151.46 34.3254L162.922 45.3458C163.045 45.4648 163.211 45.5313 163.382 45.5313H193.626C193.805 45.5313 193.976 45.4594 194.101 45.332L205.117 34.095C205.24 33.9696 205.309 33.8005 205.307 33.6248L205.083 3.82917C205.082 3.65917 205.016 3.49612 204.898 3.37357L193.873 -8.09184C193.748 -8.22214 193.575 -8.29578 193.394 -8.29578Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M241.945 1.00818H222.132C221.953 1.00818 221.782 1.07998 221.657 1.2074L214.286 8.72321C214.165 8.84622 214.097 9.01128 214.096 9.18357L213.948 29.0043C213.947 29.1868 214.02 29.3618 214.152 29.4883L221.66 36.7074C221.783 36.8264 221.949 36.8929 222.12 36.8929H242.101C242.279 36.8929 242.45 36.821 242.575 36.6936L249.791 29.334C249.914 29.2086 249.982 29.0395 249.98 28.8638L249.832 9.17973C249.831 9.00972 249.765 8.84668 249.647 8.72413L242.424 1.21212C242.299 1.08182 242.126 1.00818 241.945 1.00818Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M238.03 7.65356H225.817C225.639 7.65356 225.468 7.72536 225.343 7.85279L220.864 12.4192C220.744 12.5422 220.676 12.7072 220.674 12.8795L220.583 25.0998C220.582 25.2822 220.656 25.4573 220.787 25.5837L225.345 29.9668C225.469 30.0858 225.634 30.1523 225.806 30.1523H238.129C238.308 30.1523 238.479 30.0805 238.604 29.953L242.983 25.4862C243.106 25.3607 243.174 25.1916 243.173 25.016L243.082 12.8757C243.08 12.7057 243.014 12.5426 242.896 12.4201L238.509 7.8575C238.384 7.7272 238.211 7.65356 238.03 7.65356Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M247.45 -8.29578H217.449C217.27 -8.29578 217.099 -8.22398 216.974 -8.09656L205.726 3.37266C205.605 3.49566 205.537 3.66072 205.536 3.83301L205.312 33.8414C205.31 34.0239 205.384 34.1989 205.516 34.3254L216.977 45.3458C217.101 45.4648 217.266 45.5313 217.437 45.5313H247.682C247.86 45.5313 248.031 45.4594 248.156 45.332L259.173 34.095C259.296 33.9696 259.364 33.8005 259.363 33.6248L259.139 3.82917C259.137 3.65917 259.071 3.49612 258.953 3.37357L247.929 -8.09184C247.803 -8.22214 247.63 -8.29578 247.45 -8.29578Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M296 1.00818H276.187C276.008 1.00818 275.837 1.07998 275.712 1.2074L268.341 8.72321C268.22 8.84622 268.152 9.01128 268.151 9.18357L268.003 29.0043C268.002 29.1868 268.075 29.3618 268.207 29.4883L275.715 36.7074C275.839 36.8264 276.004 36.8929 276.175 36.8929H296.156C296.334 36.8929 296.505 36.821 296.63 36.6936L303.846 29.334C303.969 29.2086 304.037 29.0395 304.036 28.8638L303.888 9.17973C303.886 9.00972 303.82 8.84668 303.702 8.72413L296.479 1.21212C296.354 1.08182 296.181 1.00818 296 1.00818Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M292.085 7.65356H279.872C279.694 7.65356 279.523 7.72536 279.398 7.85279L274.919 12.4192C274.799 12.5422 274.73 12.7072 274.729 12.8795L274.638 25.0998C274.637 25.2822 274.71 25.4573 274.842 25.5837L279.4 29.9668C279.524 30.0858 279.689 30.1523 279.861 30.1523H292.184C292.363 30.1523 292.534 30.0805 292.659 29.953L297.038 25.4862C297.161 25.3607 297.229 25.1916 297.228 25.016L297.136 12.8757C297.135 12.7057 297.069 12.5426 296.951 12.4201L292.564 7.8575C292.439 7.7272 292.266 7.65356 292.085 7.65356Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M301.504 -8.29578H271.503C271.325 -8.29578 271.154 -8.22398 271.029 -8.09656L259.78 3.37266C259.66 3.49566 259.592 3.66072 259.59 3.83301L259.366 33.8414C259.365 34.0239 259.439 34.1989 259.57 34.3254L271.032 45.3458C271.155 45.4648 271.32 45.5313 271.492 45.5313H301.736C301.915 45.5313 302.086 45.4594 302.211 45.332L313.227 34.095C313.35 33.9696 313.419 33.8005 313.417 33.6248L313.193 3.82917C313.192 3.65917 313.126 3.49612 313.008 3.37357L301.983 -8.09184C301.858 -8.22214 301.685 -8.29578 301.504 -8.29578Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M350.055 1.00818H330.242C330.064 1.00818 329.893 1.07998 329.768 1.2074L322.396 8.72321C322.276 8.84622 322.207 9.01128 322.206 9.18357L322.058 29.0043C322.057 29.1868 322.131 29.3618 322.262 29.4883L329.77 36.7074C329.894 36.8264 330.059 36.8929 330.231 36.8929H350.211C350.39 36.8929 350.561 36.821 350.686 36.6936L357.901 29.334C358.024 29.2086 358.092 29.0395 358.091 28.8638L357.943 9.17973C357.942 9.00972 357.875 8.84668 357.757 8.72413L350.534 1.21212C350.409 1.08182 350.236 1.00818 350.055 1.00818Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M346.14 7.65356H333.927C333.749 7.65356 333.578 7.72536 333.453 7.85279L328.974 12.4192C328.854 12.5422 328.786 12.7072 328.784 12.8795L328.693 25.0998C328.692 25.2822 328.765 25.4573 328.897 25.5837L333.455 29.9668C333.579 30.0858 333.744 30.1523 333.916 30.1523H346.239C346.418 30.1523 346.589 30.0805 346.714 29.953L351.093 25.4862C351.216 25.3607 351.284 25.1916 351.283 25.016L351.192 12.8757C351.19 12.7057 351.124 12.5426 351.006 12.4201L346.619 7.8575C346.494 7.7272 346.321 7.65356 346.14 7.65356Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M355.56 -8.29578H325.559C325.38 -8.29578 325.209 -8.22398 325.084 -8.09656L313.836 3.37266C313.715 3.49566 313.647 3.66072 313.646 3.83301L313.422 33.8414C313.42 34.0239 313.494 34.1989 313.625 34.3254L325.087 45.3458C325.21 45.4648 325.376 45.5313 325.547 45.5313H355.791C355.97 45.5313 356.141 45.4594 356.266 45.332L367.283 34.095C367.406 33.9696 367.474 33.8005 367.472 33.6248L367.248 3.82917C367.247 3.65917 367.181 3.49612 367.063 3.37357L356.039 -8.09184C355.913 -8.22214 355.74 -8.29578 355.56 -8.29578Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M404.11 1.00818H384.297C384.119 1.00818 383.948 1.07998 383.823 1.2074L376.451 8.72321C376.331 8.84622 376.263 9.01128 376.261 9.18357L376.113 29.0043C376.112 29.1868 376.186 29.3618 376.317 29.4883L383.825 36.7074C383.949 36.8264 384.114 36.8929 384.286 36.8929H404.266C404.445 36.8929 404.616 36.821 404.741 36.6936L411.956 29.334C412.079 29.2086 412.147 29.0395 412.146 28.8638L411.998 9.17973C411.997 9.00972 411.93 8.84668 411.813 8.72413L404.589 1.21212C404.464 1.08182 404.291 1.00818 404.11 1.00818Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M400.195 7.65356H387.982C387.804 7.65356 387.633 7.72536 387.508 7.85279L383.03 12.4192C382.909 12.5422 382.841 12.7072 382.839 12.8795L382.748 25.0998C382.747 25.2822 382.821 25.4573 382.952 25.5837L387.511 29.9668C387.634 30.0858 387.799 30.1523 387.971 30.1523H400.294C400.473 30.1523 400.644 30.0805 400.769 29.953L405.148 25.4862C405.271 25.3607 405.339 25.1916 405.338 25.016L405.247 12.8757C405.246 12.7057 405.179 12.5426 405.061 12.4201L400.674 7.8575C400.549 7.7272 400.376 7.65356 400.195 7.65356Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M409.615 -8.29578H379.614C379.435 -8.29578 379.264 -8.22398 379.139 -8.09656L367.891 3.37266C367.77 3.49566 367.702 3.66072 367.701 3.83301L367.477 33.8414C367.475 34.0239 367.549 34.1989 367.681 34.3254L379.142 45.3458C379.266 45.4648 379.431 45.5313 379.602 45.5313H409.847C410.025 45.5313 410.196 45.4594 410.321 45.332L421.338 34.095C421.461 33.9696 421.529 33.8005 421.528 33.6248L421.304 3.82917C421.302 3.65917 421.236 3.49612 421.118 3.37357L410.094 -8.09184C409.968 -8.22214 409.795 -8.29578 409.615 -8.29578Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M458.165 1.00818H438.352C438.173 1.00818 438.002 1.07998 437.877 1.2074L430.506 8.72321C430.386 8.84622 430.317 9.01128 430.316 9.18357L430.168 29.0043C430.167 29.1868 430.241 29.3618 430.372 29.4883L437.88 36.7074C438.004 36.8264 438.169 36.8929 438.34 36.8929H458.321C458.499 36.8929 458.67 36.821 458.795 36.6936L466.011 29.334C466.134 29.2086 466.202 29.0395 466.201 28.8638L466.053 9.17973C466.051 9.00972 465.985 8.84668 465.867 8.72413L458.644 1.21212C458.519 1.08182 458.346 1.00818 458.165 1.00818Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M454.25 7.65356H442.037C441.859 7.65356 441.688 7.72536 441.563 7.85279L437.084 12.4192C436.964 12.5422 436.895 12.7072 436.894 12.8795L436.803 25.0998C436.802 25.2822 436.875 25.4573 437.007 25.5837L441.565 29.9668C441.689 30.0858 441.854 30.1523 442.026 30.1523H454.349C454.528 30.1523 454.699 30.0805 454.824 29.953L459.203 25.4862C459.326 25.3607 459.394 25.1916 459.393 25.016L459.302 12.8757C459.3 12.7057 459.234 12.5426 459.116 12.4201L454.729 7.8575C454.604 7.7272 454.431 7.65356 454.25 7.65356Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M463.67 -8.29578H433.669C433.491 -8.29578 433.32 -8.22398 433.195 -8.09656L421.946 3.37266C421.825 3.49566 421.757 3.66072 421.756 3.83301L421.532 33.8414C421.531 34.0239 421.604 34.1989 421.736 34.3254L433.197 45.3458C433.321 45.4648 433.486 45.5313 433.658 45.5313H463.902C464.08 45.5313 464.251 45.4594 464.376 45.332L475.393 34.095C475.516 33.9696 475.584 33.8005 475.583 33.6248L475.359 3.82917C475.358 3.65917 475.291 3.49612 475.173 3.37357L464.149 -8.09184C464.024 -8.22214 463.851 -8.29578 463.67 -8.29578Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M512.22 1.00818H492.407C492.229 1.00818 492.058 1.07998 491.933 1.2074L484.561 8.72321C484.441 8.84622 484.373 9.01128 484.371 9.18357L484.223 29.0043C484.222 29.1868 484.296 29.3618 484.427 29.4883L491.935 36.7074C492.059 36.8264 492.224 36.8929 492.396 36.8929H512.376C512.555 36.8929 512.726 36.821 512.851 36.6936L520.066 29.334C520.189 29.2086 520.257 29.0395 520.256 28.8638L520.108 9.17973C520.107 9.00972 520.04 8.84668 519.922 8.72413L512.699 1.21212C512.574 1.08182 512.401 1.00818 512.22 1.00818Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M508.305 7.65356H496.092C495.914 7.65356 495.743 7.72536 495.618 7.85279L491.139 12.4192C491.019 12.5422 490.951 12.7072 490.949 12.8795L490.858 25.0998C490.857 25.2822 490.93 25.4573 491.062 25.5837L495.62 29.9668C495.744 30.0858 495.909 30.1523 496.081 30.1523H508.404C508.583 30.1523 508.754 30.0805 508.879 29.953L513.258 25.4862C513.381 25.3607 513.449 25.1916 513.448 25.016L513.357 12.8757C513.355 12.7057 513.289 12.5426 513.171 12.4201L508.784 7.8575C508.659 7.7272 508.486 7.65356 508.305 7.65356Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M517.725 -8.29578H487.724C487.546 -8.29578 487.375 -8.22398 487.25 -8.09656L476.001 3.37266C475.88 3.49566 475.812 3.66072 475.811 3.83301L475.587 33.8414C475.586 34.0239 475.659 34.1989 475.791 34.3254L487.252 45.3458C487.376 45.4648 487.541 45.5313 487.713 45.5313H517.957C518.135 45.5313 518.306 45.4594 518.431 45.332L529.448 34.095C529.571 33.9696 529.639 33.8005 529.638 33.6248L529.414 3.82917C529.413 3.65917 529.346 3.49612 529.228 3.37357L518.204 -8.09184C518.079 -8.22214 517.906 -8.29578 517.725 -8.29578Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M566.275 1.00818H546.462C546.284 1.00818 546.113 1.07998 545.988 1.2074L538.616 8.72321C538.496 8.84622 538.428 9.01128 538.426 9.18357L538.279 29.0043C538.277 29.1868 538.351 29.3618 538.482 29.4883L545.99 36.7074C546.114 36.8264 546.279 36.8929 546.451 36.8929H566.431C566.61 36.8929 566.781 36.821 566.906 36.6936L574.121 29.334C574.244 29.2086 574.312 29.0395 574.311 28.8638L574.163 9.17973C574.162 9.00972 574.095 8.84668 573.978 8.72413L566.754 1.21212C566.629 1.08182 566.456 1.00818 566.275 1.00818Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M562.36 7.65356H550.148C549.969 7.65356 549.798 7.72536 549.673 7.85279L545.195 12.4192C545.074 12.5422 545.006 12.7072 545.004 12.8795L544.913 25.0998C544.912 25.2822 544.986 25.4573 545.117 25.5837L549.676 29.9668C549.799 30.0858 549.964 30.1523 550.136 30.1523H562.459C562.638 30.1523 562.809 30.0805 562.934 29.953L567.313 25.4862C567.436 25.3607 567.504 25.1916 567.503 25.016L567.412 12.8757C567.411 12.7057 567.344 12.5426 567.226 12.4201L562.839 7.8575C562.714 7.7272 562.541 7.65356 562.36 7.65356Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M571.78 -8.29578H541.779C541.601 -8.29578 541.43 -8.22398 541.305 -8.09656L530.056 3.37266C529.936 3.49566 529.867 3.66072 529.866 3.83301L529.642 33.8414C529.641 34.0239 529.715 34.1989 529.846 34.3254L541.307 45.3458C541.431 45.4648 541.596 45.5313 541.768 45.5313H572.012C572.191 45.5313 572.362 45.4594 572.487 45.332L583.503 34.095C583.626 33.9696 583.695 33.8005 583.693 33.6248L583.469 3.82917C583.468 3.65917 583.402 3.49612 583.284 3.37357L572.259 -8.09184C572.134 -8.22214 571.961 -8.29578 571.78 -8.29578Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M620.331 1.00818H600.517C600.339 1.00818 600.168 1.07998 600.043 1.2074L592.672 8.72321C592.551 8.84622 592.483 9.01128 592.482 9.18357L592.334 29.0043C592.332 29.1868 592.406 29.3618 592.538 29.4883L600.045 36.7074C600.169 36.8264 600.334 36.8929 600.506 36.8929H620.486C620.665 36.8929 620.836 36.821 620.961 36.6936L628.176 29.334C628.299 29.2086 628.368 29.0395 628.366 28.8638L628.218 9.17973C628.217 9.00972 628.151 8.84668 628.033 8.72413L620.81 1.21212C620.684 1.08182 620.511 1.00818 620.331 1.00818Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M616.415 7.65356H604.203C604.024 7.65356 603.853 7.72536 603.728 7.85279L599.25 12.4192C599.129 12.5422 599.061 12.7072 599.06 12.8795L598.968 25.0998C598.967 25.2822 599.041 25.4573 599.172 25.5837L603.731 29.9668C603.855 30.0858 604.02 30.1523 604.191 30.1523H616.515C616.693 30.1523 616.864 30.0805 616.989 29.953L621.368 25.4862C621.491 25.3607 621.56 25.1916 621.558 25.016L621.467 12.8757C621.466 12.7057 621.399 12.5426 621.282 12.4201L616.894 7.8575C616.769 7.7272 616.596 7.65356 616.415 7.65356Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M625.835 -8.29578H595.835C595.656 -8.29578 595.485 -8.22398 595.36 -8.09656L584.111 3.37266C583.991 3.49566 583.923 3.66072 583.921 3.83301L583.697 33.8414C583.696 34.0239 583.77 34.1989 583.901 34.3254L595.363 45.3458C595.486 45.4648 595.651 45.5313 595.823 45.5313H626.067C626.246 45.5313 626.417 45.4594 626.542 45.332L637.558 34.095C637.681 33.9696 637.75 33.8005 637.748 33.6248L637.524 3.82917C637.523 3.65917 637.457 3.49612 637.339 3.37357L626.314 -8.09184C626.189 -8.22214 626.016 -8.29578 625.835 -8.29578Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M674.386 1.00818H654.573C654.394 1.00818 654.223 1.07998 654.098 1.2074L646.727 8.72321C646.606 8.84622 646.538 9.01128 646.537 9.18357L646.389 29.0043C646.387 29.1868 646.461 29.3618 646.593 29.4883L654.101 36.7074C654.224 36.8264 654.389 36.8929 654.561 36.8929H674.542C674.72 36.8929 674.891 36.821 675.016 36.6936L682.231 29.334C682.354 29.2086 682.423 29.0395 682.421 28.8638L682.273 9.17973C682.272 9.00972 682.206 8.84668 682.088 8.72413L674.865 1.21212C674.74 1.08182 674.567 1.00818 674.386 1.00818Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M670.471 7.65356H658.258C658.079 7.65356 657.908 7.72536 657.783 7.85279L653.305 12.4192C653.184 12.5422 653.116 12.7072 653.115 12.8795L653.024 25.0998C653.022 25.2822 653.096 25.4573 653.228 25.5837L657.786 29.9668C657.91 30.0858 658.075 30.1523 658.246 30.1523H670.57C670.748 30.1523 670.919 30.0805 671.044 29.953L675.424 25.4862C675.546 25.3607 675.615 25.1916 675.613 25.016L675.522 12.8757C675.521 12.7057 675.455 12.5426 675.337 12.4201L670.95 7.8575C670.824 7.7272 670.651 7.65356 670.471 7.65356Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M679.891 -8.29578H649.89C649.711 -8.29578 649.54 -8.22398 649.415 -8.09656L638.167 3.37266C638.046 3.49566 637.978 3.66072 637.977 3.83301L637.753 33.8414C637.751 34.0239 637.825 34.1989 637.957 34.3254L649.418 45.3458C649.542 45.4648 649.707 45.5313 649.878 45.5313H680.122C680.301 45.5313 680.472 45.4594 680.597 45.332L691.614 34.095C691.737 33.9696 691.805 33.8005 691.804 33.6248L691.58 3.82917C691.578 3.65917 691.512 3.49612 691.394 3.37357L680.37 -8.09184C680.244 -8.22214 680.071 -8.29578 679.891 -8.29578Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M728.441 1.00818H708.628C708.449 1.00818 708.278 1.07998 708.153 1.2074L700.782 8.72321C700.661 8.84622 700.593 9.01128 700.592 9.18357L700.444 29.0043C700.443 29.1868 700.516 29.3618 700.648 29.4883L708.156 36.7074C708.28 36.8264 708.445 36.8929 708.616 36.8929H728.597C728.775 36.8929 728.946 36.821 729.071 36.6936L736.287 29.334C736.41 29.2086 736.478 29.0395 736.477 28.8638L736.329 9.17973C736.327 9.00972 736.261 8.84668 736.143 8.72413L728.92 1.21212C728.795 1.08182 728.622 1.00818 728.441 1.00818Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M724.526 7.65356H712.313C712.135 7.65356 711.964 7.72536 711.839 7.85279L707.36 12.4192C707.239 12.5422 707.171 12.7072 707.17 12.8795L707.079 25.0998C707.077 25.2822 707.151 25.4573 707.283 25.5837L711.841 29.9668C711.965 30.0858 712.13 30.1523 712.302 30.1523H724.625C724.803 30.1523 724.974 30.0805 725.099 29.953L729.479 25.4862C729.602 25.3607 729.67 25.1916 729.669 25.016L729.577 12.8757C729.576 12.7057 729.51 12.5426 729.392 12.4201L725.005 7.8575C724.88 7.7272 724.707 7.65356 724.526 7.65356Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M733.946 -8.29578H703.945C703.766 -8.29578 703.595 -8.22398 703.47 -8.09656L692.222 3.37266C692.101 3.49566 692.033 3.66072 692.032 3.83301L691.808 33.8414C691.806 34.0239 691.88 34.1989 692.012 34.3254L703.473 45.3458C703.597 45.4648 703.762 45.5313 703.934 45.5313H734.178C734.356 45.5313 734.527 45.4594 734.652 45.332L745.669 34.095C745.792 33.9696 745.86 33.8005 745.859 33.6248L745.635 3.82917C745.633 3.65917 745.567 3.49612 745.449 3.37357L734.425 -8.09184C734.299 -8.22214 734.127 -8.29578 733.946 -8.29578Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M782.496 1.00818H762.682C762.504 1.00818 762.333 1.07998 762.208 1.2074L754.837 8.72321C754.716 8.84622 754.648 9.01128 754.647 9.18357L754.499 29.0043C754.497 29.1868 754.571 29.3618 754.703 29.4883L762.21 36.7074C762.334 36.8264 762.499 36.8929 762.671 36.8929H782.652C782.83 36.8929 783.001 36.821 783.126 36.6936L790.341 29.334C790.464 29.2086 790.533 29.0395 790.531 28.8638L790.383 9.17973C790.382 9.00972 790.316 8.84668 790.198 8.72413L782.975 1.21212C782.849 1.08182 782.676 1.00818 782.496 1.00818Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M778.58 7.65356H766.368C766.189 7.65356 766.018 7.72536 765.893 7.85279L761.415 12.4192C761.294 12.5422 761.226 12.7072 761.225 12.8795L761.133 25.0998C761.132 25.2822 761.206 25.4573 761.337 25.5837L765.896 29.9668C766.02 30.0858 766.185 30.1523 766.356 30.1523H778.68C778.858 30.1523 779.029 30.0805 779.154 29.953L783.533 25.4862C783.656 25.3607 783.725 25.1916 783.723 25.016L783.632 12.8757C783.631 12.7057 783.564 12.5426 783.447 12.4201L779.059 7.8575C778.934 7.7272 778.761 7.65356 778.58 7.65356Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M788 -8.29578H758C757.821 -8.29578 757.65 -8.22398 757.525 -8.09656L746.277 3.37266C746.156 3.49566 746.088 3.66072 746.086 3.83301L745.862 33.8414C745.861 34.0239 745.935 34.1989 746.066 34.3254L757.528 45.3458C757.651 45.4648 757.816 45.5313 757.988 45.5313H788.232C788.411 45.5313 788.582 45.4594 788.707 45.332L799.723 34.095C799.846 33.9696 799.915 33.8005 799.913 33.6248L799.689 3.82917C799.688 3.65917 799.622 3.49612 799.504 3.37357L788.479 -8.09184C788.354 -8.22214 788.181 -8.29578 788 -8.29578Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M836.551 1.00818H816.738C816.559 1.00818 816.388 1.07998 816.263 1.2074L808.892 8.72321C808.771 8.84622 808.703 9.01128 808.702 9.18357L808.554 29.0043C808.553 29.1868 808.626 29.3618 808.758 29.4883L816.266 36.7074C816.389 36.8264 816.554 36.8929 816.726 36.8929H836.707C836.885 36.8929 837.056 36.821 837.181 36.6936L844.396 29.334C844.519 29.2086 844.588 29.0395 844.586 28.8638L844.438 9.17973C844.437 9.00972 844.371 8.84668 844.253 8.72413L837.03 1.21212C836.905 1.08182 836.732 1.00818 836.551 1.00818Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M832.636 7.65356H820.423C820.245 7.65356 820.074 7.72536 819.949 7.85279L815.47 12.4192C815.35 12.5422 815.282 12.7072 815.28 12.8795L815.189 25.0998C815.188 25.2822 815.262 25.4573 815.393 25.5837L819.951 29.9668C820.075 30.0858 820.24 30.1523 820.412 30.1523H832.735C832.914 30.1523 833.085 30.0805 833.21 29.953L837.589 25.4862C837.712 25.3607 837.78 25.1916 837.779 25.016L837.688 12.8757C837.686 12.7057 837.62 12.5426 837.502 12.4201L833.115 7.8575C832.99 7.7272 832.817 7.65356 832.636 7.65356Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M842.056 -8.29578H812.055C811.876 -8.29578 811.705 -8.22398 811.58 -8.09656L800.332 3.37266C800.211 3.49566 800.143 3.66072 800.142 3.83301L799.918 33.8414C799.916 34.0239 799.99 34.1989 800.122 34.3254L811.583 45.3458C811.707 45.4648 811.872 45.5313 812.043 45.5313H842.287C842.466 45.5313 842.637 45.4594 842.762 45.332L853.779 34.095C853.902 33.9696 853.97 33.8005 853.969 33.6248L853.745 3.82917C853.743 3.65917 853.677 3.49612 853.559 3.37357L842.535 -8.09184C842.409 -8.22214 842.236 -8.29578 842.056 -8.29578Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M25.7247 66.0099H5.91143C5.73295 66.0099 5.56197 66.0817 5.43699 66.2091L-1.93429 73.7249C-2.05493 73.8479 -2.12308 74.013 -2.12436 74.1853L-2.27228 94.006C-2.27364 94.1885 -2.1999 94.3635 -2.06836 94.49L5.43945 101.709C5.56324 101.828 5.72831 101.895 5.90004 101.895H25.8805C26.0591 101.895 26.2301 101.823 26.3551 101.695L33.5703 94.3357C33.6933 94.2103 33.7616 94.0412 33.7603 93.8655L33.6123 74.1814C33.611 74.0114 33.5446 73.8484 33.4268 73.7258L26.2037 66.2138C26.0784 66.0835 25.9054 66.0099 25.7247 66.0099Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M21.8095 72.6553H9.59674C9.41826 72.6553 9.24728 72.7271 9.12231 72.8545L4.64376 77.4209C4.52312 77.5439 4.45497 77.7089 4.45368 77.8812L4.36248 90.1015C4.36112 90.284 4.43486 90.459 4.56641 90.5855L9.12476 94.9685C9.24856 95.0875 9.41362 95.154 9.58536 95.154H21.9086C22.0871 95.154 22.2582 95.0822 22.3831 94.9547L26.7624 90.4879C26.8854 90.3624 26.9537 90.1934 26.9524 90.0177L26.8611 77.8774C26.8598 77.7074 26.7934 77.5443 26.6756 77.4218L22.2885 72.8592C22.1632 72.7289 21.9902 72.6553 21.8095 72.6553Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M31.229 56.7059H1.22811C1.04963 56.7059 0.878648 56.7777 0.753674 56.9052L-10.495 68.3744C-10.6156 68.4974 -10.6838 68.6624 -10.6851 68.8347L-10.909 98.8431C-10.9104 99.0256 -10.8366 99.2006 -10.7051 99.3271L0.756127 110.347C0.879919 110.466 1.04498 110.533 1.21672 110.533H31.4608C31.6394 110.533 31.8104 110.461 31.9354 110.334L42.9519 99.0968C43.0749 98.9713 43.1432 98.8022 43.1419 98.6265L42.9179 68.8309C42.9166 68.6609 42.8502 68.4978 42.7324 68.3753L31.708 56.9099C31.5827 56.7796 31.4097 56.7059 31.229 56.7059Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M79.7794 66.0099H59.9661C59.7876 66.0099 59.6167 66.0817 59.4917 66.2091L52.1204 73.7249C51.9998 73.8479 51.9316 74.013 51.9303 74.1853L51.7824 94.006C51.781 94.1885 51.8548 94.3635 51.9863 94.49L59.4941 101.709C59.6179 101.828 59.783 101.895 59.9547 101.895H79.9352C80.1137 101.895 80.2848 101.823 80.4097 101.695L87.625 94.3357C87.748 94.2103 87.8163 94.0412 87.815 93.8655L87.667 74.1814C87.6657 74.0114 87.5993 73.8484 87.4815 73.7258L80.2584 66.2138C80.1331 66.0835 79.9601 66.0099 79.7794 66.0099Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M75.8642 72.6553H63.6514C63.473 72.6553 63.302 72.7271 63.177 72.8545L58.6984 77.4209C58.5778 77.5439 58.5097 77.7089 58.5084 77.8812L58.4172 90.1015C58.4158 90.284 58.4896 90.459 58.6211 90.5855L63.1795 94.9685C63.3032 95.0875 63.4683 95.154 63.64 95.154H75.9633C76.1418 95.154 76.3128 95.0822 76.4378 94.9547L80.8171 90.4879C80.9401 90.3624 81.0084 90.1934 81.007 90.0177L80.9158 77.8774C80.9145 77.7074 80.8481 77.5443 80.7303 77.4218L76.3432 72.8592C76.2179 72.7289 76.0449 72.6553 75.8642 72.6553Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M85.2836 56.7059H55.2828C55.1043 56.7059 54.9333 56.7777 54.8084 56.9052L43.5597 68.3744C43.4391 68.4974 43.3709 68.6624 43.3696 68.8347L43.1457 98.8431C43.1443 99.0256 43.2181 99.2006 43.3496 99.3271L54.8108 110.347C54.9346 110.466 55.0997 110.533 55.2714 110.533H85.5155C85.694 110.533 85.8651 110.461 85.99 110.334L97.0066 99.0968C97.1296 98.9713 97.1979 98.8022 97.1966 98.6265L96.9726 68.8309C96.9713 68.6609 96.9049 68.4978 96.7871 68.3753L85.7627 56.9099C85.6374 56.7796 85.4644 56.7059 85.2836 56.7059Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M133.835 66.0099H114.022C113.843 66.0099 113.672 66.0817 113.547 66.2091L106.176 73.7249C106.055 73.8479 105.987 74.013 105.986 74.1853L105.838 94.006C105.837 94.1885 105.91 94.3635 106.042 94.49L113.55 101.709C113.674 101.828 113.839 101.895 114.01 101.895H133.991C134.169 101.895 134.34 101.823 134.465 101.695L141.681 94.3357C141.804 94.2103 141.872 94.0412 141.871 93.8655L141.723 74.1814C141.721 74.0114 141.655 73.8484 141.537 73.7258L134.314 66.2138C134.189 66.0835 134.016 66.0099 133.835 66.0099Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M129.92 72.6553H117.707C117.529 72.6553 117.358 72.7271 117.233 72.8545L112.754 77.4209C112.633 77.5439 112.565 77.7089 112.564 77.8812L112.473 90.1015C112.471 90.284 112.545 90.459 112.677 90.5855L117.235 94.9685C117.359 95.0875 117.524 95.154 117.696 95.154H130.019C130.197 95.154 130.369 95.0822 130.493 94.9547L134.873 90.4879C134.996 90.3624 135.064 90.1934 135.063 90.0177L134.971 77.8774C134.97 77.7074 134.904 77.5443 134.786 77.4218L130.399 72.8592C130.274 72.7289 130.101 72.6553 129.92 72.6553Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M139.339 56.7059H109.338C109.16 56.7059 108.989 56.7777 108.864 56.9052L97.6154 68.3744C97.4947 68.4974 97.4266 68.6624 97.4253 68.8347L97.2014 98.8431C97.2 99.0256 97.2737 99.2006 97.4053 99.3271L108.866 110.347C108.99 110.466 109.155 110.533 109.327 110.533H139.571C139.75 110.533 139.921 110.461 140.046 110.334L151.062 99.0968C151.185 98.9713 151.254 98.8022 151.252 98.6265L151.028 68.8309C151.027 68.6609 150.961 68.4978 150.843 68.3753L139.818 56.9099C139.693 56.7796 139.52 56.7059 139.339 56.7059Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M187.89 66.0099H168.076C167.898 66.0099 167.727 66.0817 167.602 66.2091L160.231 73.7249C160.11 73.8479 160.042 74.013 160.041 74.1853L159.893 94.006C159.891 94.1885 159.965 94.3635 160.097 94.49L167.604 101.709C167.728 101.828 167.893 101.895 168.065 101.895H188.046C188.224 101.895 188.395 101.823 188.52 101.695L195.735 94.3357C195.858 94.2103 195.927 94.0412 195.925 93.8655L195.777 74.1814C195.776 74.0114 195.71 73.8484 195.592 73.7258L188.369 66.2138C188.243 66.0835 188.07 66.0099 187.89 66.0099Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M183.975 72.6553H171.762C171.584 72.6553 171.413 72.7271 171.288 72.8545L166.809 77.4209C166.689 77.5439 166.62 77.7089 166.619 77.8812L166.528 90.1015C166.527 90.284 166.6 90.459 166.732 90.5855L171.29 94.9685C171.414 95.0875 171.579 95.154 171.751 95.154H184.074C184.253 95.154 184.424 95.0822 184.549 94.9547L188.928 90.4879C189.051 90.3624 189.119 90.1934 189.118 90.0177L189.027 77.8774C189.025 77.7074 188.959 77.5443 188.841 77.4218L184.454 72.8592C184.329 72.7289 184.156 72.6553 183.975 72.6553Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M193.394 56.7059H163.394C163.215 56.7059 163.044 56.7777 162.919 56.9052L151.671 68.3744C151.55 68.4974 151.482 68.6624 151.48 68.8347L151.257 98.8431C151.255 99.0256 151.329 99.2006 151.46 99.3271L162.922 110.347C163.045 110.466 163.211 110.533 163.382 110.533H193.626C193.805 110.533 193.976 110.461 194.101 110.334L205.117 99.0968C205.24 98.9713 205.309 98.8022 205.307 98.6265L205.083 68.8309C205.082 68.6609 205.016 68.4978 204.898 68.3753L193.873 56.9099C193.748 56.7796 193.575 56.7059 193.394 56.7059Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M241.945 66.0099H222.132C221.953 66.0099 221.782 66.0817 221.657 66.2091L214.286 73.7249C214.165 73.8479 214.097 74.013 214.096 74.1853L213.948 94.006C213.947 94.1885 214.02 94.3635 214.152 94.49L221.66 101.709C221.783 101.828 221.949 101.895 222.12 101.895H242.101C242.279 101.895 242.45 101.823 242.575 101.695L249.791 94.3357C249.914 94.2103 249.982 94.0412 249.98 93.8655L249.832 74.1814C249.831 74.0114 249.765 73.8484 249.647 73.7258L242.424 66.2138C242.299 66.0835 242.126 66.0099 241.945 66.0099Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M238.03 72.6553H225.817C225.639 72.6553 225.468 72.7271 225.343 72.8545L220.864 77.4209C220.744 77.5439 220.676 77.7089 220.674 77.8812L220.583 90.1015C220.582 90.284 220.656 90.459 220.787 90.5855L225.345 94.9685C225.469 95.0875 225.634 95.154 225.806 95.154H238.129C238.308 95.154 238.479 95.0822 238.604 94.9547L242.983 90.4879C243.106 90.3624 243.174 90.1934 243.173 90.0177L243.082 77.8774C243.08 77.7074 243.014 77.5443 242.896 77.4218L238.509 72.8592C238.384 72.7289 238.211 72.6553 238.03 72.6553Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M247.45 56.7059H217.449C217.27 56.7059 217.099 56.7777 216.974 56.9052L205.726 68.3744C205.605 68.4974 205.537 68.6624 205.536 68.8347L205.312 98.8431C205.31 99.0256 205.384 99.2006 205.516 99.3271L216.977 110.347C217.101 110.466 217.266 110.533 217.437 110.533H247.682C247.86 110.533 248.031 110.461 248.156 110.334L259.173 99.0968C259.296 98.9713 259.364 98.8022 259.363 98.6265L259.139 68.8309C259.137 68.6609 259.071 68.4978 258.953 68.3753L247.929 56.9099C247.803 56.7796 247.63 56.7059 247.45 56.7059Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M296 66.0099H276.187C276.008 66.0099 275.837 66.0817 275.712 66.2091L268.341 73.7249C268.22 73.8479 268.152 74.013 268.151 74.1853L268.003 94.006C268.002 94.1885 268.075 94.3635 268.207 94.49L275.715 101.709C275.839 101.828 276.004 101.895 276.175 101.895H296.156C296.334 101.895 296.505 101.823 296.63 101.695L303.846 94.3357C303.969 94.2103 304.037 94.0412 304.036 93.8655L303.888 74.1814C303.886 74.0114 303.82 73.8484 303.702 73.7258L296.479 66.2138C296.354 66.0835 296.181 66.0099 296 66.0099Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M292.085 72.6553H279.872C279.694 72.6553 279.523 72.7271 279.398 72.8545L274.919 77.4209C274.799 77.5439 274.73 77.7089 274.729 77.8812L274.638 90.1015C274.637 90.284 274.71 90.459 274.842 90.5855L279.4 94.9685C279.524 95.0875 279.689 95.154 279.861 95.154H292.184C292.363 95.154 292.534 95.0822 292.659 94.9547L297.038 90.4879C297.161 90.3624 297.229 90.1934 297.228 90.0177L297.136 77.8774C297.135 77.7074 297.069 77.5443 296.951 77.4218L292.564 72.8592C292.439 72.7289 292.266 72.6553 292.085 72.6553Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M301.504 56.7059H271.503C271.325 56.7059 271.154 56.7777 271.029 56.9052L259.78 68.3744C259.66 68.4974 259.592 68.6624 259.59 68.8347L259.366 98.8431C259.365 99.0256 259.439 99.2006 259.57 99.3271L271.032 110.347C271.155 110.466 271.32 110.533 271.492 110.533H301.736C301.915 110.533 302.086 110.461 302.211 110.334L313.227 99.0968C313.35 98.9713 313.419 98.8022 313.417 98.6265L313.193 68.8309C313.192 68.6609 313.126 68.4978 313.008 68.3753L301.983 56.9099C301.858 56.7796 301.685 56.7059 301.504 56.7059Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M350.055 66.0099H330.242C330.064 66.0099 329.893 66.0817 329.768 66.2091L322.396 73.7249C322.276 73.8479 322.207 74.013 322.206 74.1853L322.058 94.006C322.057 94.1885 322.131 94.3635 322.262 94.49L329.77 101.709C329.894 101.828 330.059 101.895 330.231 101.895H350.211C350.39 101.895 350.561 101.823 350.686 101.695L357.901 94.3357C358.024 94.2103 358.092 94.0412 358.091 93.8655L357.943 74.1814C357.942 74.0114 357.875 73.8484 357.757 73.7258L350.534 66.2138C350.409 66.0835 350.236 66.0099 350.055 66.0099Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M346.14 72.6553H333.927C333.749 72.6553 333.578 72.7271 333.453 72.8545L328.974 77.4209C328.854 77.5439 328.786 77.7089 328.784 77.8812L328.693 90.1015C328.692 90.284 328.765 90.459 328.897 90.5855L333.455 94.9685C333.579 95.0875 333.744 95.154 333.916 95.154H346.239C346.418 95.154 346.589 95.0822 346.714 94.9547L351.093 90.4879C351.216 90.3624 351.284 90.1934 351.283 90.0177L351.192 77.8774C351.19 77.7074 351.124 77.5443 351.006 77.4218L346.619 72.8592C346.494 72.7289 346.321 72.6553 346.14 72.6553Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M355.56 56.7059H325.559C325.38 56.7059 325.209 56.7777 325.084 56.9052L313.836 68.3744C313.715 68.4974 313.647 68.6624 313.646 68.8347L313.422 98.8431C313.42 99.0256 313.494 99.2006 313.625 99.3271L325.087 110.347C325.21 110.466 325.376 110.533 325.547 110.533H355.791C355.97 110.533 356.141 110.461 356.266 110.334L367.283 99.0968C367.406 98.9713 367.474 98.8022 367.472 98.6265L367.248 68.8309C367.247 68.6609 367.181 68.4978 367.063 68.3753L356.039 56.9099C355.913 56.7796 355.74 56.7059 355.56 56.7059Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M404.11 66.0099H384.297C384.119 66.0099 383.948 66.0817 383.823 66.2091L376.451 73.7249C376.331 73.8479 376.263 74.013 376.261 74.1853L376.113 94.006C376.112 94.1885 376.186 94.3635 376.317 94.49L383.825 101.709C383.949 101.828 384.114 101.895 384.286 101.895H404.266C404.445 101.895 404.616 101.823 404.741 101.695L411.956 94.3357C412.079 94.2103 412.147 94.0412 412.146 93.8655L411.998 74.1814C411.997 74.0114 411.93 73.8484 411.813 73.7258L404.589 66.2138C404.464 66.0835 404.291 66.0099 404.11 66.0099Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M400.195 72.6553H387.982C387.804 72.6553 387.633 72.7271 387.508 72.8545L383.03 77.4209C382.909 77.5439 382.841 77.7089 382.839 77.8812L382.748 90.1015C382.747 90.284 382.821 90.459 382.952 90.5855L387.511 94.9685C387.634 95.0875 387.799 95.154 387.971 95.154H400.294C400.473 95.154 400.644 95.0822 400.769 94.9547L405.148 90.4879C405.271 90.3624 405.339 90.1934 405.338 90.0177L405.247 77.8774C405.246 77.7074 405.179 77.5443 405.061 77.4218L400.674 72.8592C400.549 72.7289 400.376 72.6553 400.195 72.6553Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M409.615 56.7059H379.614C379.435 56.7059 379.264 56.7777 379.139 56.9052L367.891 68.3744C367.77 68.4974 367.702 68.6624 367.701 68.8347L367.477 98.8431C367.475 99.0256 367.549 99.2006 367.681 99.3271L379.142 110.347C379.266 110.466 379.431 110.533 379.602 110.533H409.847C410.025 110.533 410.196 110.461 410.321 110.334L421.338 99.0968C421.461 98.9713 421.529 98.8022 421.528 98.6265L421.304 68.8309C421.302 68.6609 421.236 68.4978 421.118 68.3753L410.094 56.9099C409.968 56.7796 409.795 56.7059 409.615 56.7059Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M458.165 66.0099H438.352C438.173 66.0099 438.002 66.0817 437.877 66.2091L430.506 73.7249C430.386 73.8479 430.317 74.013 430.316 74.1853L430.168 94.006C430.167 94.1885 430.241 94.3635 430.372 94.49L437.88 101.709C438.004 101.828 438.169 101.895 438.34 101.895H458.321C458.499 101.895 458.67 101.823 458.795 101.695L466.011 94.3357C466.134 94.2103 466.202 94.0412 466.201 93.8655L466.053 74.1814C466.051 74.0114 465.985 73.8484 465.867 73.7258L458.644 66.2138C458.519 66.0835 458.346 66.0099 458.165 66.0099Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M454.25 72.6553H442.037C441.859 72.6553 441.688 72.7271 441.563 72.8545L437.084 77.4209C436.964 77.5439 436.895 77.7089 436.894 77.8812L436.803 90.1015C436.802 90.284 436.875 90.459 437.007 90.5855L441.565 94.9685C441.689 95.0875 441.854 95.154 442.026 95.154H454.349C454.528 95.154 454.699 95.0822 454.824 94.9547L459.203 90.4879C459.326 90.3624 459.394 90.1934 459.393 90.0177L459.302 77.8774C459.3 77.7074 459.234 77.5443 459.116 77.4218L454.729 72.8592C454.604 72.7289 454.431 72.6553 454.25 72.6553Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M463.67 56.7059H433.669C433.491 56.7059 433.32 56.7777 433.195 56.9052L421.946 68.3744C421.825 68.4974 421.757 68.6624 421.756 68.8347L421.532 98.8431C421.531 99.0256 421.604 99.2006 421.736 99.3271L433.197 110.347C433.321 110.466 433.486 110.533 433.658 110.533H463.902C464.08 110.533 464.251 110.461 464.376 110.334L475.393 99.0968C475.516 98.9713 475.584 98.8022 475.583 98.6265L475.359 68.8309C475.358 68.6609 475.291 68.4978 475.173 68.3753L464.149 56.9099C464.024 56.7796 463.851 56.7059 463.67 56.7059Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M512.22 66.0099H492.407C492.229 66.0099 492.058 66.0817 491.933 66.2091L484.561 73.7249C484.441 73.8479 484.373 74.013 484.371 74.1853L484.223 94.006C484.222 94.1885 484.296 94.3635 484.427 94.49L491.935 101.709C492.059 101.828 492.224 101.895 492.396 101.895H512.376C512.555 101.895 512.726 101.823 512.851 101.695L520.066 94.3357C520.189 94.2103 520.257 94.0412 520.256 93.8655L520.108 74.1814C520.107 74.0114 520.04 73.8484 519.922 73.7258L512.699 66.2138C512.574 66.0835 512.401 66.0099 512.22 66.0099Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M508.305 72.6553H496.092C495.914 72.6553 495.743 72.7271 495.618 72.8545L491.139 77.4209C491.019 77.5439 490.951 77.7089 490.949 77.8812L490.858 90.1015C490.857 90.284 490.93 90.459 491.062 90.5855L495.62 94.9685C495.744 95.0875 495.909 95.154 496.081 95.154H508.404C508.583 95.154 508.754 95.0822 508.879 94.9547L513.258 90.4879C513.381 90.3624 513.449 90.1934 513.448 90.0177L513.357 77.8774C513.355 77.7074 513.289 77.5443 513.171 77.4218L508.784 72.8592C508.659 72.7289 508.486 72.6553 508.305 72.6553Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M517.725 56.7059H487.724C487.546 56.7059 487.375 56.7777 487.25 56.9052L476.001 68.3744C475.88 68.4974 475.812 68.6624 475.811 68.8347L475.587 98.8431C475.586 99.0256 475.659 99.2006 475.791 99.3271L487.252 110.347C487.376 110.466 487.541 110.533 487.713 110.533H517.957C518.135 110.533 518.306 110.461 518.431 110.334L529.448 99.0968C529.571 98.9713 529.639 98.8022 529.638 98.6265L529.414 68.8309C529.413 68.6609 529.346 68.4978 529.228 68.3753L518.204 56.9099C518.079 56.7796 517.906 56.7059 517.725 56.7059Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M566.275 66.0099H546.462C546.284 66.0099 546.113 66.0817 545.988 66.2091L538.616 73.7249C538.496 73.8479 538.428 74.013 538.426 74.1853L538.279 94.006C538.277 94.1885 538.351 94.3635 538.482 94.49L545.99 101.709C546.114 101.828 546.279 101.895 546.451 101.895H566.431C566.61 101.895 566.781 101.823 566.906 101.695L574.121 94.3357C574.244 94.2103 574.312 94.0412 574.311 93.8655L574.163 74.1814C574.162 74.0114 574.095 73.8484 573.978 73.7258L566.754 66.2138C566.629 66.0835 566.456 66.0099 566.275 66.0099Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M562.36 72.6553H550.148C549.969 72.6553 549.798 72.7271 549.673 72.8545L545.195 77.4209C545.074 77.5439 545.006 77.7089 545.004 77.8812L544.913 90.1015C544.912 90.284 544.986 90.459 545.117 90.5855L549.676 94.9685C549.799 95.0875 549.964 95.154 550.136 95.154H562.459C562.638 95.154 562.809 95.0822 562.934 94.9547L567.313 90.4879C567.436 90.3624 567.504 90.1934 567.503 90.0177L567.412 77.8774C567.411 77.7074 567.344 77.5443 567.226 77.4218L562.839 72.8592C562.714 72.7289 562.541 72.6553 562.36 72.6553Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M571.78 56.7059H541.779C541.601 56.7059 541.43 56.7777 541.305 56.9052L530.056 68.3744C529.936 68.4974 529.867 68.6624 529.866 68.8347L529.642 98.8431C529.641 99.0256 529.715 99.2006 529.846 99.3271L541.307 110.347C541.431 110.466 541.596 110.533 541.768 110.533H572.012C572.191 110.533 572.362 110.461 572.487 110.334L583.503 99.0968C583.626 98.9713 583.695 98.8022 583.693 98.6265L583.469 68.8309C583.468 68.6609 583.402 68.4978 583.284 68.3753L572.259 56.9099C572.134 56.7796 571.961 56.7059 571.78 56.7059Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M620.331 66.0099H600.517C600.339 66.0099 600.168 66.0817 600.043 66.2091L592.672 73.7249C592.551 73.8479 592.483 74.013 592.482 74.1853L592.334 94.006C592.332 94.1885 592.406 94.3635 592.538 94.49L600.045 101.709C600.169 101.828 600.334 101.895 600.506 101.895H620.486C620.665 101.895 620.836 101.823 620.961 101.695L628.176 94.3357C628.299 94.2103 628.368 94.0412 628.366 93.8655L628.218 74.1814C628.217 74.0114 628.151 73.8484 628.033 73.7258L620.81 66.2138C620.684 66.0835 620.511 66.0099 620.331 66.0099Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M616.415 72.6553H604.203C604.024 72.6553 603.853 72.7271 603.728 72.8545L599.25 77.4209C599.129 77.5439 599.061 77.7089 599.06 77.8812L598.968 90.1015C598.967 90.284 599.041 90.459 599.172 90.5855L603.731 94.9685C603.855 95.0875 604.02 95.154 604.191 95.154H616.515C616.693 95.154 616.864 95.0822 616.989 94.9547L621.368 90.4879C621.491 90.3624 621.56 90.1934 621.558 90.0177L621.467 77.8774C621.466 77.7074 621.399 77.5443 621.282 77.4218L616.894 72.8592C616.769 72.7289 616.596 72.6553 616.415 72.6553Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M625.835 56.7059H595.835C595.656 56.7059 595.485 56.7777 595.36 56.9052L584.111 68.3744C583.991 68.4974 583.923 68.6624 583.921 68.8347L583.697 98.8431C583.696 99.0256 583.77 99.2006 583.901 99.3271L595.363 110.347C595.486 110.466 595.651 110.533 595.823 110.533H626.067C626.246 110.533 626.417 110.461 626.542 110.334L637.558 99.0968C637.681 98.9713 637.75 98.8022 637.748 98.6265L637.524 68.8309C637.523 68.6609 637.457 68.4978 637.339 68.3753L626.314 56.9099C626.189 56.7796 626.016 56.7059 625.835 56.7059Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M674.386 66.0099H654.573C654.394 66.0099 654.223 66.0817 654.098 66.2091L646.727 73.7249C646.606 73.8479 646.538 74.013 646.537 74.1853L646.389 94.006C646.387 94.1885 646.461 94.3635 646.593 94.49L654.101 101.709C654.224 101.828 654.389 101.895 654.561 101.895H674.542C674.72 101.895 674.891 101.823 675.016 101.695L682.231 94.3357C682.354 94.2103 682.423 94.0412 682.421 93.8655L682.273 74.1814C682.272 74.0114 682.206 73.8484 682.088 73.7258L674.865 66.2138C674.74 66.0835 674.567 66.0099 674.386 66.0099Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M670.471 72.6553H658.258C658.079 72.6553 657.908 72.7271 657.783 72.8545L653.305 77.4209C653.184 77.5439 653.116 77.7089 653.115 77.8812L653.024 90.1015C653.022 90.284 653.096 90.459 653.228 90.5855L657.786 94.9685C657.91 95.0875 658.075 95.154 658.246 95.154H670.57C670.748 95.154 670.919 95.0822 671.044 94.9547L675.424 90.4879C675.546 90.3624 675.615 90.1934 675.613 90.0177L675.522 77.8774C675.521 77.7074 675.455 77.5443 675.337 77.4218L670.95 72.8592C670.824 72.7289 670.651 72.6553 670.471 72.6553Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M679.891 56.7059H649.89C649.711 56.7059 649.54 56.7777 649.415 56.9052L638.167 68.3744C638.046 68.4974 637.978 68.6624 637.977 68.8347L637.753 98.8431C637.751 99.0256 637.825 99.2006 637.957 99.3271L649.418 110.347C649.542 110.466 649.707 110.533 649.878 110.533H680.122C680.301 110.533 680.472 110.461 680.597 110.334L691.614 99.0968C691.737 98.9713 691.805 98.8022 691.804 98.6265L691.58 68.8309C691.578 68.6609 691.512 68.4978 691.394 68.3753L680.37 56.9099C680.244 56.7796 680.071 56.7059 679.891 56.7059Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M728.441 66.0099H708.628C708.449 66.0099 708.278 66.0817 708.153 66.2091L700.782 73.7249C700.661 73.8479 700.593 74.013 700.592 74.1853L700.444 94.006C700.443 94.1885 700.516 94.3635 700.648 94.49L708.156 101.709C708.28 101.828 708.445 101.895 708.616 101.895H728.597C728.775 101.895 728.946 101.823 729.071 101.695L736.287 94.3357C736.41 94.2103 736.478 94.0412 736.477 93.8655L736.329 74.1814C736.327 74.0114 736.261 73.8484 736.143 73.7258L728.92 66.2138C728.795 66.0835 728.622 66.0099 728.441 66.0099Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M724.526 72.6553H712.313C712.135 72.6553 711.964 72.7271 711.839 72.8545L707.36 77.4209C707.239 77.5439 707.171 77.7089 707.17 77.8812L707.079 90.1015C707.077 90.284 707.151 90.459 707.283 90.5855L711.841 94.9685C711.965 95.0875 712.13 95.154 712.302 95.154H724.625C724.803 95.154 724.974 95.0822 725.099 94.9547L729.479 90.4879C729.602 90.3624 729.67 90.1934 729.669 90.0177L729.577 77.8774C729.576 77.7074 729.51 77.5443 729.392 77.4218L725.005 72.8592C724.88 72.7289 724.707 72.6553 724.526 72.6553Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M733.946 56.7059H703.945C703.766 56.7059 703.595 56.7777 703.47 56.9052L692.222 68.3744C692.101 68.4974 692.033 68.6624 692.032 68.8347L691.808 98.8431C691.806 99.0256 691.88 99.2006 692.012 99.3271L703.473 110.347C703.597 110.466 703.762 110.533 703.934 110.533H734.178C734.356 110.533 734.527 110.461 734.652 110.334L745.669 99.0968C745.792 98.9713 745.86 98.8022 745.859 98.6265L745.635 68.8309C745.633 68.6609 745.567 68.4978 745.449 68.3753L734.425 56.9099C734.299 56.7796 734.127 56.7059 733.946 56.7059Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M782.496 66.0099H762.682C762.504 66.0099 762.333 66.0817 762.208 66.2091L754.837 73.7249C754.716 73.8479 754.648 74.013 754.647 74.1853L754.499 94.006C754.497 94.1885 754.571 94.3635 754.703 94.49L762.21 101.709C762.334 101.828 762.499 101.895 762.671 101.895H782.652C782.83 101.895 783.001 101.823 783.126 101.695L790.341 94.3357C790.464 94.2103 790.533 94.0412 790.531 93.8655L790.383 74.1814C790.382 74.0114 790.316 73.8484 790.198 73.7258L782.975 66.2138C782.849 66.0835 782.676 66.0099 782.496 66.0099Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M778.58 72.6553H766.368C766.189 72.6553 766.018 72.7271 765.893 72.8545L761.415 77.4209C761.294 77.5439 761.226 77.7089 761.225 77.8812L761.133 90.1015C761.132 90.284 761.206 90.459 761.337 90.5855L765.896 94.9685C766.02 95.0875 766.185 95.154 766.356 95.154H778.68C778.858 95.154 779.029 95.0822 779.154 94.9547L783.533 90.4879C783.656 90.3624 783.725 90.1934 783.723 90.0177L783.632 77.8774C783.631 77.7074 783.564 77.5443 783.447 77.4218L779.059 72.8592C778.934 72.7289 778.761 72.6553 778.58 72.6553Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M788 56.7059H758C757.821 56.7059 757.65 56.7777 757.525 56.9052L746.277 68.3744C746.156 68.4974 746.088 68.6624 746.086 68.8347L745.862 98.8431C745.861 99.0256 745.935 99.2006 746.066 99.3271L757.528 110.347C757.651 110.466 757.816 110.533 757.988 110.533H788.232C788.411 110.533 788.582 110.461 788.707 110.334L799.723 99.0968C799.846 98.9713 799.915 98.8022 799.913 98.6265L799.689 68.8309C799.688 68.6609 799.622 68.4978 799.504 68.3753L788.479 56.9099C788.354 56.7796 788.181 56.7059 788 56.7059Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M836.551 66.0099H816.738C816.559 66.0099 816.388 66.0817 816.263 66.2091L808.892 73.7249C808.771 73.8479 808.703 74.013 808.702 74.1853L808.554 94.006C808.553 94.1885 808.626 94.3635 808.758 94.49L816.266 101.709C816.389 101.828 816.554 101.895 816.726 101.895H836.707C836.885 101.895 837.056 101.823 837.181 101.695L844.396 94.3357C844.519 94.2103 844.588 94.0412 844.586 93.8655L844.438 74.1814C844.437 74.0114 844.371 73.8484 844.253 73.7258L837.03 66.2138C836.905 66.0835 836.732 66.0099 836.551 66.0099Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M832.636 72.6553H820.423C820.245 72.6553 820.074 72.7271 819.949 72.8545L815.47 77.4209C815.35 77.5439 815.282 77.7089 815.28 77.8812L815.189 90.1015C815.188 90.284 815.262 90.459 815.393 90.5855L819.951 94.9685C820.075 95.0875 820.24 95.154 820.412 95.154H832.735C832.914 95.154 833.085 95.0822 833.21 94.9547L837.589 90.4879C837.712 90.3624 837.78 90.1934 837.779 90.0177L837.688 77.8774C837.686 77.7074 837.62 77.5443 837.502 77.4218L833.115 72.8592C832.99 72.7289 832.817 72.6553 832.636 72.6553Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M842.056 56.7059H812.055C811.876 56.7059 811.705 56.7777 811.58 56.9052L800.332 68.3744C800.211 68.4974 800.143 68.6624 800.142 68.8347L799.918 98.8431C799.916 99.0256 799.99 99.2006 800.122 99.3271L811.583 110.347C811.707 110.466 811.872 110.533 812.043 110.533H842.287C842.466 110.533 842.637 110.461 842.762 110.334L853.779 99.0968C853.902 98.9713 853.97 98.8022 853.969 98.6265L853.745 68.8309C853.743 68.6609 853.677 68.4978 853.559 68.3753L842.535 56.9099C842.409 56.7796 842.236 56.7059 842.056 56.7059Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M25.7247 131.012H5.91143C5.73295 131.012 5.56197 131.083 5.43699 131.211L-1.93429 138.727C-2.05493 138.85 -2.12308 139.015 -2.12436 139.187L-2.27228 159.008C-2.27364 159.19 -2.1999 159.365 -2.06836 159.492L5.43945 166.711C5.56324 166.83 5.72831 166.896 5.90004 166.896H25.8805C26.0591 166.896 26.2301 166.824 26.3551 166.697L33.5703 159.337C33.6933 159.212 33.7616 159.043 33.7603 158.867L33.6123 139.183C33.611 139.013 33.5446 138.85 33.4268 138.728L26.2037 131.216C26.0784 131.085 25.9054 131.012 25.7247 131.012Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M21.8095 137.657H9.59674C9.41826 137.657 9.24728 137.729 9.12231 137.856L4.64376 142.423C4.52312 142.546 4.45497 142.711 4.45368 142.883L4.36248 155.103C4.36112 155.286 4.43486 155.461 4.56641 155.587L9.12476 159.97C9.24856 160.089 9.41362 160.156 9.58536 160.156H21.9086C22.0871 160.156 22.2582 160.084 22.3831 159.956L26.7624 155.49C26.8854 155.364 26.9537 155.195 26.9524 155.019L26.8611 142.879C26.8598 142.709 26.7934 142.546 26.6756 142.423L22.2885 137.861C22.1632 137.731 21.9902 137.657 21.8095 137.657Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M31.229 121.708H1.22811C1.04963 121.708 0.878648 121.779 0.753674 121.907L-10.495 133.376C-10.6156 133.499 -10.6838 133.664 -10.6851 133.836L-10.909 163.845C-10.9104 164.027 -10.8366 164.202 -10.7051 164.329L0.756127 175.349C0.879919 175.468 1.04498 175.535 1.21672 175.535H31.4608C31.6394 175.535 31.8104 175.463 31.9354 175.335L42.9519 164.098C43.0749 163.973 43.1432 163.804 43.1419 163.628L42.9179 133.833C42.9166 133.663 42.8502 133.5 42.7324 133.377L31.708 121.912C31.5827 121.781 31.4097 121.708 31.229 121.708Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M79.7794 131.012H59.9661C59.7876 131.012 59.6167 131.083 59.4917 131.211L52.1204 138.727C51.9998 138.85 51.9316 139.015 51.9303 139.187L51.7824 159.008C51.781 159.19 51.8548 159.365 51.9863 159.492L59.4941 166.711C59.6179 166.83 59.783 166.896 59.9547 166.896H79.9352C80.1137 166.896 80.2848 166.824 80.4097 166.697L87.625 159.337C87.748 159.212 87.8163 159.043 87.815 158.867L87.667 139.183C87.6657 139.013 87.5993 138.85 87.4815 138.728L80.2584 131.216C80.1331 131.085 79.9601 131.012 79.7794 131.012Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M75.8642 137.657H63.6514C63.473 137.657 63.302 137.729 63.177 137.856L58.6984 142.423C58.5778 142.546 58.5097 142.711 58.5084 142.883L58.4172 155.103C58.4158 155.286 58.4896 155.461 58.6211 155.587L63.1795 159.97C63.3032 160.089 63.4683 160.156 63.64 160.156H75.9633C76.1418 160.156 76.3128 160.084 76.4378 159.956L80.8171 155.49C80.9401 155.364 81.0084 155.195 81.007 155.019L80.9158 142.879C80.9145 142.709 80.8481 142.546 80.7303 142.423L76.3432 137.861C76.2179 137.731 76.0449 137.657 75.8642 137.657Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M85.2836 121.708H55.2828C55.1043 121.708 54.9333 121.779 54.8084 121.907L43.5597 133.376C43.4391 133.499 43.3709 133.664 43.3696 133.836L43.1457 163.845C43.1443 164.027 43.2181 164.202 43.3496 164.329L54.8108 175.349C54.9346 175.468 55.0997 175.535 55.2714 175.535H85.5155C85.694 175.535 85.8651 175.463 85.99 175.335L97.0066 164.098C97.1296 163.973 97.1979 163.804 97.1966 163.628L96.9726 133.833C96.9713 133.663 96.9049 133.5 96.7871 133.377L85.7627 121.912C85.6374 121.781 85.4644 121.708 85.2836 121.708Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M133.835 131.012H114.022C113.843 131.012 113.672 131.083 113.547 131.211L106.176 138.727C106.055 138.85 105.987 139.015 105.986 139.187L105.838 159.008C105.837 159.19 105.91 159.365 106.042 159.492L113.55 166.711C113.674 166.83 113.839 166.896 114.01 166.896H133.991C134.169 166.896 134.34 166.824 134.465 166.697L141.681 159.337C141.804 159.212 141.872 159.043 141.871 158.867L141.723 139.183C141.721 139.013 141.655 138.85 141.537 138.728L134.314 131.216C134.189 131.085 134.016 131.012 133.835 131.012Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M129.92 137.657H117.707C117.529 137.657 117.358 137.729 117.233 137.856L112.754 142.423C112.633 142.546 112.565 142.711 112.564 142.883L112.473 155.103C112.471 155.286 112.545 155.461 112.677 155.587L117.235 159.97C117.359 160.089 117.524 160.156 117.696 160.156H130.019C130.197 160.156 130.369 160.084 130.493 159.956L134.873 155.49C134.996 155.364 135.064 155.195 135.063 155.019L134.971 142.879C134.97 142.709 134.904 142.546 134.786 142.423L130.399 137.861C130.274 137.731 130.101 137.657 129.92 137.657Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M139.339 121.708H109.338C109.16 121.708 108.989 121.779 108.864 121.907L97.6154 133.376C97.4947 133.499 97.4266 133.664 97.4253 133.836L97.2014 163.845C97.2 164.027 97.2737 164.202 97.4053 164.329L108.866 175.349C108.99 175.468 109.155 175.535 109.327 175.535H139.571C139.75 175.535 139.921 175.463 140.046 175.335L151.062 164.098C151.185 163.973 151.254 163.804 151.252 163.628L151.028 133.833C151.027 133.663 150.961 133.5 150.843 133.377L139.818 121.912C139.693 121.781 139.52 121.708 139.339 121.708Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M187.89 131.012H168.076C167.898 131.012 167.727 131.083 167.602 131.211L160.231 138.727C160.11 138.85 160.042 139.015 160.041 139.187L159.893 159.008C159.891 159.19 159.965 159.365 160.097 159.492L167.604 166.711C167.728 166.83 167.893 166.896 168.065 166.896H188.046C188.224 166.896 188.395 166.824 188.52 166.697L195.735 159.337C195.858 159.212 195.927 159.043 195.925 158.867L195.777 139.183C195.776 139.013 195.71 138.85 195.592 138.728L188.369 131.216C188.243 131.085 188.07 131.012 187.89 131.012Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M183.975 137.657H171.762C171.584 137.657 171.413 137.729 171.288 137.856L166.809 142.423C166.689 142.546 166.62 142.711 166.619 142.883L166.528 155.103C166.527 155.286 166.6 155.461 166.732 155.587L171.29 159.97C171.414 160.089 171.579 160.156 171.751 160.156H184.074C184.253 160.156 184.424 160.084 184.549 159.956L188.928 155.49C189.051 155.364 189.119 155.195 189.118 155.019L189.027 142.879C189.025 142.709 188.959 142.546 188.841 142.423L184.454 137.861C184.329 137.731 184.156 137.657 183.975 137.657Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M193.394 121.708H163.394C163.215 121.708 163.044 121.779 162.919 121.907L151.671 133.376C151.55 133.499 151.482 133.664 151.48 133.836L151.257 163.845C151.255 164.027 151.329 164.202 151.46 164.329L162.922 175.349C163.045 175.468 163.211 175.535 163.382 175.535H193.626C193.805 175.535 193.976 175.463 194.101 175.335L205.117 164.098C205.24 163.973 205.309 163.804 205.307 163.628L205.083 133.833C205.082 133.663 205.016 133.5 204.898 133.377L193.873 121.912C193.748 121.781 193.575 121.708 193.394 121.708Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M241.945 131.012H222.132C221.953 131.012 221.782 131.083 221.657 131.211L214.286 138.727C214.165 138.85 214.097 139.015 214.096 139.187L213.948 159.008C213.947 159.19 214.02 159.365 214.152 159.492L221.66 166.711C221.783 166.83 221.949 166.896 222.12 166.896H242.101C242.279 166.896 242.45 166.824 242.575 166.697L249.791 159.337C249.914 159.212 249.982 159.043 249.98 158.867L249.832 139.183C249.831 139.013 249.765 138.85 249.647 138.728L242.424 131.216C242.299 131.085 242.126 131.012 241.945 131.012Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M238.03 137.657H225.817C225.639 137.657 225.468 137.729 225.343 137.856L220.864 142.423C220.744 142.546 220.676 142.711 220.674 142.883L220.583 155.103C220.582 155.286 220.656 155.461 220.787 155.587L225.345 159.97C225.469 160.089 225.634 160.156 225.806 160.156H238.129C238.308 160.156 238.479 160.084 238.604 159.956L242.983 155.49C243.106 155.364 243.174 155.195 243.173 155.019L243.082 142.879C243.08 142.709 243.014 142.546 242.896 142.423L238.509 137.861C238.384 137.731 238.211 137.657 238.03 137.657Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M247.45 121.708H217.449C217.27 121.708 217.099 121.779 216.974 121.907L205.726 133.376C205.605 133.499 205.537 133.664 205.536 133.836L205.312 163.845C205.31 164.027 205.384 164.202 205.516 164.329L216.977 175.349C217.101 175.468 217.266 175.535 217.437 175.535H247.682C247.86 175.535 248.031 175.463 248.156 175.335L259.173 164.098C259.296 163.973 259.364 163.804 259.363 163.628L259.139 133.833C259.137 133.663 259.071 133.5 258.953 133.377L247.929 121.912C247.803 121.781 247.63 121.708 247.45 121.708Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M296 131.012H276.187C276.008 131.012 275.837 131.083 275.712 131.211L268.341 138.727C268.22 138.85 268.152 139.015 268.151 139.187L268.003 159.008C268.002 159.19 268.075 159.365 268.207 159.492L275.715 166.711C275.839 166.83 276.004 166.896 276.175 166.896H296.156C296.334 166.896 296.505 166.824 296.63 166.697L303.846 159.337C303.969 159.212 304.037 159.043 304.036 158.867L303.888 139.183C303.886 139.013 303.82 138.85 303.702 138.728L296.479 131.216C296.354 131.085 296.181 131.012 296 131.012Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M292.085 137.657H279.872C279.694 137.657 279.523 137.729 279.398 137.856L274.919 142.423C274.799 142.546 274.73 142.711 274.729 142.883L274.638 155.103C274.637 155.286 274.71 155.461 274.842 155.587L279.4 159.97C279.524 160.089 279.689 160.156 279.861 160.156H292.184C292.363 160.156 292.534 160.084 292.659 159.956L297.038 155.49C297.161 155.364 297.229 155.195 297.228 155.019L297.136 142.879C297.135 142.709 297.069 142.546 296.951 142.423L292.564 137.861C292.439 137.731 292.266 137.657 292.085 137.657Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M301.504 121.708H271.503C271.325 121.708 271.154 121.779 271.029 121.907L259.78 133.376C259.66 133.499 259.592 133.664 259.59 133.836L259.366 163.845C259.365 164.027 259.439 164.202 259.57 164.329L271.032 175.349C271.155 175.468 271.32 175.535 271.492 175.535H301.736C301.915 175.535 302.086 175.463 302.211 175.335L313.227 164.098C313.35 163.973 313.419 163.804 313.417 163.628L313.193 133.833C313.192 133.663 313.126 133.5 313.008 133.377L301.983 121.912C301.858 121.781 301.685 121.708 301.504 121.708Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M350.055 131.012H330.242C330.064 131.012 329.893 131.083 329.768 131.211L322.396 138.727C322.276 138.85 322.207 139.015 322.206 139.187L322.058 159.008C322.057 159.19 322.131 159.365 322.262 159.492L329.77 166.711C329.894 166.83 330.059 166.896 330.231 166.896H350.211C350.39 166.896 350.561 166.824 350.686 166.697L357.901 159.337C358.024 159.212 358.092 159.043 358.091 158.867L357.943 139.183C357.942 139.013 357.875 138.85 357.757 138.728L350.534 131.216C350.409 131.085 350.236 131.012 350.055 131.012Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M346.14 137.657H333.927C333.749 137.657 333.578 137.729 333.453 137.856L328.974 142.423C328.854 142.546 328.786 142.711 328.784 142.883L328.693 155.103C328.692 155.286 328.765 155.461 328.897 155.587L333.455 159.97C333.579 160.089 333.744 160.156 333.916 160.156H346.239C346.418 160.156 346.589 160.084 346.714 159.956L351.093 155.49C351.216 155.364 351.284 155.195 351.283 155.019L351.192 142.879C351.19 142.709 351.124 142.546 351.006 142.423L346.619 137.861C346.494 137.731 346.321 137.657 346.14 137.657Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M355.56 121.708H325.559C325.38 121.708 325.209 121.779 325.084 121.907L313.836 133.376C313.715 133.499 313.647 133.664 313.646 133.836L313.422 163.845C313.42 164.027 313.494 164.202 313.625 164.329L325.087 175.349C325.21 175.468 325.376 175.535 325.547 175.535H355.791C355.97 175.535 356.141 175.463 356.266 175.335L367.283 164.098C367.406 163.973 367.474 163.804 367.472 163.628L367.248 133.833C367.247 133.663 367.181 133.5 367.063 133.377L356.039 121.912C355.913 121.781 355.74 121.708 355.56 121.708Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M404.11 131.012H384.297C384.119 131.012 383.948 131.083 383.823 131.211L376.451 138.727C376.331 138.85 376.263 139.015 376.261 139.187L376.113 159.008C376.112 159.19 376.186 159.365 376.317 159.492L383.825 166.711C383.949 166.83 384.114 166.896 384.286 166.896H404.266C404.445 166.896 404.616 166.824 404.741 166.697L411.956 159.337C412.079 159.212 412.147 159.043 412.146 158.867L411.998 139.183C411.997 139.013 411.93 138.85 411.813 138.728L404.589 131.216C404.464 131.085 404.291 131.012 404.11 131.012Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M400.195 137.657H387.982C387.804 137.657 387.633 137.729 387.508 137.856L383.03 142.423C382.909 142.546 382.841 142.711 382.839 142.883L382.748 155.103C382.747 155.286 382.821 155.461 382.952 155.587L387.511 159.97C387.634 160.089 387.799 160.156 387.971 160.156H400.294C400.473 160.156 400.644 160.084 400.769 159.956L405.148 155.49C405.271 155.364 405.339 155.195 405.338 155.019L405.247 142.879C405.246 142.709 405.179 142.546 405.061 142.423L400.674 137.861C400.549 137.731 400.376 137.657 400.195 137.657Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M409.615 121.708H379.614C379.435 121.708 379.264 121.779 379.139 121.907L367.891 133.376C367.77 133.499 367.702 133.664 367.701 133.836L367.477 163.845C367.475 164.027 367.549 164.202 367.681 164.329L379.142 175.349C379.266 175.468 379.431 175.535 379.602 175.535H409.847C410.025 175.535 410.196 175.463 410.321 175.335L421.338 164.098C421.461 163.973 421.529 163.804 421.528 163.628L421.304 133.833C421.302 133.663 421.236 133.5 421.118 133.377L410.094 121.912C409.968 121.781 409.795 121.708 409.615 121.708Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M458.165 131.012H438.352C438.173 131.012 438.002 131.083 437.877 131.211L430.506 138.727C430.386 138.85 430.317 139.015 430.316 139.187L430.168 159.008C430.167 159.19 430.241 159.365 430.372 159.492L437.88 166.711C438.004 166.83 438.169 166.896 438.34 166.896H458.321C458.499 166.896 458.67 166.824 458.795 166.697L466.011 159.337C466.134 159.212 466.202 159.043 466.201 158.867L466.053 139.183C466.051 139.013 465.985 138.85 465.867 138.728L458.644 131.216C458.519 131.085 458.346 131.012 458.165 131.012Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M454.25 137.657H442.037C441.859 137.657 441.688 137.729 441.563 137.856L437.084 142.423C436.964 142.546 436.895 142.711 436.894 142.883L436.803 155.103C436.802 155.286 436.875 155.461 437.007 155.587L441.565 159.97C441.689 160.089 441.854 160.156 442.026 160.156H454.349C454.528 160.156 454.699 160.084 454.824 159.956L459.203 155.49C459.326 155.364 459.394 155.195 459.393 155.019L459.302 142.879C459.3 142.709 459.234 142.546 459.116 142.423L454.729 137.861C454.604 137.731 454.431 137.657 454.25 137.657Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M463.67 121.708H433.669C433.491 121.708 433.32 121.779 433.195 121.907L421.946 133.376C421.825 133.499 421.757 133.664 421.756 133.836L421.532 163.845C421.531 164.027 421.604 164.202 421.736 164.329L433.197 175.349C433.321 175.468 433.486 175.535 433.658 175.535H463.902C464.08 175.535 464.251 175.463 464.376 175.335L475.393 164.098C475.516 163.973 475.584 163.804 475.583 163.628L475.359 133.833C475.358 133.663 475.291 133.5 475.173 133.377L464.149 121.912C464.024 121.781 463.851 121.708 463.67 121.708Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M512.22 131.012H492.407C492.229 131.012 492.058 131.083 491.933 131.211L484.561 138.727C484.441 138.85 484.373 139.015 484.371 139.187L484.223 159.008C484.222 159.19 484.296 159.365 484.427 159.492L491.935 166.711C492.059 166.83 492.224 166.896 492.396 166.896H512.376C512.555 166.896 512.726 166.824 512.851 166.697L520.066 159.337C520.189 159.212 520.257 159.043 520.256 158.867L520.108 139.183C520.107 139.013 520.04 138.85 519.922 138.728L512.699 131.216C512.574 131.085 512.401 131.012 512.22 131.012Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M508.305 137.657H496.092C495.914 137.657 495.743 137.729 495.618 137.856L491.139 142.423C491.019 142.546 490.951 142.711 490.949 142.883L490.858 155.103C490.857 155.286 490.93 155.461 491.062 155.587L495.62 159.97C495.744 160.089 495.909 160.156 496.081 160.156H508.404C508.583 160.156 508.754 160.084 508.879 159.956L513.258 155.49C513.381 155.364 513.449 155.195 513.448 155.019L513.357 142.879C513.355 142.709 513.289 142.546 513.171 142.423L508.784 137.861C508.659 137.731 508.486 137.657 508.305 137.657Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M517.725 121.708H487.724C487.546 121.708 487.375 121.779 487.25 121.907L476.001 133.376C475.88 133.499 475.812 133.664 475.811 133.836L475.587 163.845C475.586 164.027 475.659 164.202 475.791 164.329L487.252 175.349C487.376 175.468 487.541 175.535 487.713 175.535H517.957C518.135 175.535 518.306 175.463 518.431 175.335L529.448 164.098C529.571 163.973 529.639 163.804 529.638 163.628L529.414 133.833C529.413 133.663 529.346 133.5 529.228 133.377L518.204 121.912C518.079 121.781 517.906 121.708 517.725 121.708Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M566.275 131.012H546.462C546.284 131.012 546.113 131.083 545.988 131.211L538.616 138.727C538.496 138.85 538.428 139.015 538.426 139.187L538.279 159.008C538.277 159.19 538.351 159.365 538.482 159.492L545.99 166.711C546.114 166.83 546.279 166.896 546.451 166.896H566.431C566.61 166.896 566.781 166.824 566.906 166.697L574.121 159.337C574.244 159.212 574.312 159.043 574.311 158.867L574.163 139.183C574.162 139.013 574.095 138.85 573.978 138.728L566.754 131.216C566.629 131.085 566.456 131.012 566.275 131.012Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M562.36 137.657H550.148C549.969 137.657 549.798 137.729 549.673 137.856L545.195 142.423C545.074 142.546 545.006 142.711 545.004 142.883L544.913 155.103C544.912 155.286 544.986 155.461 545.117 155.587L549.676 159.97C549.799 160.089 549.964 160.156 550.136 160.156H562.459C562.638 160.156 562.809 160.084 562.934 159.956L567.313 155.49C567.436 155.364 567.504 155.195 567.503 155.019L567.412 142.879C567.411 142.709 567.344 142.546 567.226 142.423L562.839 137.861C562.714 137.731 562.541 137.657 562.36 137.657Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M571.78 121.708H541.779C541.601 121.708 541.43 121.779 541.305 121.907L530.056 133.376C529.936 133.499 529.867 133.664 529.866 133.836L529.642 163.845C529.641 164.027 529.715 164.202 529.846 164.329L541.307 175.349C541.431 175.468 541.596 175.535 541.768 175.535H572.012C572.191 175.535 572.362 175.463 572.487 175.335L583.503 164.098C583.626 163.973 583.695 163.804 583.693 163.628L583.469 133.833C583.468 133.663 583.402 133.5 583.284 133.377L572.259 121.912C572.134 121.781 571.961 121.708 571.78 121.708Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M620.331 131.012H600.517C600.339 131.012 600.168 131.083 600.043 131.211L592.672 138.727C592.551 138.85 592.483 139.015 592.482 139.187L592.334 159.008C592.332 159.19 592.406 159.365 592.538 159.492L600.045 166.711C600.169 166.83 600.334 166.896 600.506 166.896H620.486C620.665 166.896 620.836 166.824 620.961 166.697L628.176 159.337C628.299 159.212 628.368 159.043 628.366 158.867L628.218 139.183C628.217 139.013 628.151 138.85 628.033 138.728L620.81 131.216C620.684 131.085 620.511 131.012 620.331 131.012Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M616.415 137.657H604.203C604.024 137.657 603.853 137.729 603.728 137.856L599.25 142.423C599.129 142.546 599.061 142.711 599.06 142.883L598.968 155.103C598.967 155.286 599.041 155.461 599.172 155.587L603.731 159.97C603.855 160.089 604.02 160.156 604.191 160.156H616.515C616.693 160.156 616.864 160.084 616.989 159.956L621.368 155.49C621.491 155.364 621.56 155.195 621.558 155.019L621.467 142.879C621.466 142.709 621.399 142.546 621.282 142.423L616.894 137.861C616.769 137.731 616.596 137.657 616.415 137.657Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M625.835 121.708H595.835C595.656 121.708 595.485 121.779 595.36 121.907L584.111 133.376C583.991 133.499 583.923 133.664 583.921 133.836L583.697 163.845C583.696 164.027 583.77 164.202 583.901 164.329L595.363 175.349C595.486 175.468 595.651 175.535 595.823 175.535H626.067C626.246 175.535 626.417 175.463 626.542 175.335L637.558 164.098C637.681 163.973 637.75 163.804 637.748 163.628L637.524 133.833C637.523 133.663 637.457 133.5 637.339 133.377L626.314 121.912C626.189 121.781 626.016 121.708 625.835 121.708Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M674.386 131.012H654.573C654.394 131.012 654.223 131.083 654.098 131.211L646.727 138.727C646.606 138.85 646.538 139.015 646.537 139.187L646.389 159.008C646.387 159.19 646.461 159.365 646.593 159.492L654.101 166.711C654.224 166.83 654.389 166.896 654.561 166.896H674.542C674.72 166.896 674.891 166.824 675.016 166.697L682.231 159.337C682.354 159.212 682.423 159.043 682.421 158.867L682.273 139.183C682.272 139.013 682.206 138.85 682.088 138.728L674.865 131.216C674.74 131.085 674.567 131.012 674.386 131.012Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M670.471 137.657H658.258C658.079 137.657 657.908 137.729 657.783 137.856L653.305 142.423C653.184 142.546 653.116 142.711 653.115 142.883L653.024 155.103C653.022 155.286 653.096 155.461 653.228 155.587L657.786 159.97C657.91 160.089 658.075 160.156 658.246 160.156H670.57C670.748 160.156 670.919 160.084 671.044 159.956L675.424 155.49C675.546 155.364 675.615 155.195 675.613 155.019L675.522 142.879C675.521 142.709 675.455 142.546 675.337 142.423L670.95 137.861C670.824 137.731 670.651 137.657 670.471 137.657Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M679.891 121.708H649.89C649.711 121.708 649.54 121.779 649.415 121.907L638.167 133.376C638.046 133.499 637.978 133.664 637.977 133.836L637.753 163.845C637.751 164.027 637.825 164.202 637.957 164.329L649.418 175.349C649.542 175.468 649.707 175.535 649.878 175.535H680.122C680.301 175.535 680.472 175.463 680.597 175.335L691.614 164.098C691.737 163.973 691.805 163.804 691.804 163.628L691.58 133.833C691.578 133.663 691.512 133.5 691.394 133.377L680.37 121.912C680.244 121.781 680.071 121.708 679.891 121.708Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M728.441 131.012H708.628C708.449 131.012 708.278 131.083 708.153 131.211L700.782 138.727C700.661 138.85 700.593 139.015 700.592 139.187L700.444 159.008C700.443 159.19 700.516 159.365 700.648 159.492L708.156 166.711C708.28 166.83 708.445 166.896 708.616 166.896H728.597C728.775 166.896 728.946 166.824 729.071 166.697L736.287 159.337C736.41 159.212 736.478 159.043 736.477 158.867L736.329 139.183C736.327 139.013 736.261 138.85 736.143 138.728L728.92 131.216C728.795 131.085 728.622 131.012 728.441 131.012Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M724.526 137.657H712.313C712.135 137.657 711.964 137.729 711.839 137.856L707.36 142.423C707.239 142.546 707.171 142.711 707.17 142.883L707.079 155.103C707.077 155.286 707.151 155.461 707.283 155.587L711.841 159.97C711.965 160.089 712.13 160.156 712.302 160.156H724.625C724.803 160.156 724.974 160.084 725.099 159.956L729.479 155.49C729.602 155.364 729.67 155.195 729.669 155.019L729.577 142.879C729.576 142.709 729.51 142.546 729.392 142.423L725.005 137.861C724.88 137.731 724.707 137.657 724.526 137.657Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M733.946 121.708H703.945C703.766 121.708 703.595 121.779 703.47 121.907L692.222 133.376C692.101 133.499 692.033 133.664 692.032 133.836L691.808 163.845C691.806 164.027 691.88 164.202 692.012 164.329L703.473 175.349C703.597 175.468 703.762 175.535 703.934 175.535H734.178C734.356 175.535 734.527 175.463 734.652 175.335L745.669 164.098C745.792 163.973 745.86 163.804 745.859 163.628L745.635 133.833C745.633 133.663 745.567 133.5 745.449 133.377L734.425 121.912C734.299 121.781 734.127 121.708 733.946 121.708Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M782.496 131.012H762.682C762.504 131.012 762.333 131.083 762.208 131.211L754.837 138.727C754.716 138.85 754.648 139.015 754.647 139.187L754.499 159.008C754.497 159.19 754.571 159.365 754.703 159.492L762.21 166.711C762.334 166.83 762.499 166.896 762.671 166.896H782.652C782.83 166.896 783.001 166.824 783.126 166.697L790.341 159.337C790.464 159.212 790.533 159.043 790.531 158.867L790.383 139.183C790.382 139.013 790.316 138.85 790.198 138.728L782.975 131.216C782.849 131.085 782.676 131.012 782.496 131.012Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M778.58 137.657H766.368C766.189 137.657 766.018 137.729 765.893 137.856L761.415 142.423C761.294 142.546 761.226 142.711 761.225 142.883L761.133 155.103C761.132 155.286 761.206 155.461 761.337 155.587L765.896 159.97C766.02 160.089 766.185 160.156 766.356 160.156H778.68C778.858 160.156 779.029 160.084 779.154 159.956L783.533 155.49C783.656 155.364 783.725 155.195 783.723 155.019L783.632 142.879C783.631 142.709 783.564 142.546 783.447 142.423L779.059 137.861C778.934 137.731 778.761 137.657 778.58 137.657Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M788 121.708H758C757.821 121.708 757.65 121.779 757.525 121.907L746.277 133.376C746.156 133.499 746.088 133.664 746.086 133.836L745.862 163.845C745.861 164.027 745.935 164.202 746.066 164.329L757.528 175.349C757.651 175.468 757.816 175.535 757.988 175.535H788.232C788.411 175.535 788.582 175.463 788.707 175.335L799.723 164.098C799.846 163.973 799.915 163.804 799.913 163.628L799.689 133.833C799.688 133.663 799.622 133.5 799.504 133.377L788.479 121.912C788.354 121.781 788.181 121.708 788 121.708Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M836.551 131.012H816.738C816.559 131.012 816.388 131.083 816.263 131.211L808.892 138.727C808.771 138.85 808.703 139.015 808.702 139.187L808.554 159.008C808.553 159.19 808.626 159.365 808.758 159.492L816.266 166.711C816.389 166.83 816.554 166.896 816.726 166.896H836.707C836.885 166.896 837.056 166.824 837.181 166.697L844.396 159.337C844.519 159.212 844.588 159.043 844.586 158.867L844.438 139.183C844.437 139.013 844.371 138.85 844.253 138.728L837.03 131.216C836.905 131.085 836.732 131.012 836.551 131.012Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M832.636 137.657H820.423C820.245 137.657 820.074 137.729 819.949 137.856L815.47 142.423C815.35 142.546 815.282 142.711 815.28 142.883L815.189 155.103C815.188 155.286 815.262 155.461 815.393 155.587L819.951 159.97C820.075 160.089 820.24 160.156 820.412 160.156H832.735C832.914 160.156 833.085 160.084 833.21 159.956L837.589 155.49C837.712 155.364 837.78 155.195 837.779 155.019L837.688 142.879C837.686 142.709 837.62 142.546 837.502 142.423L833.115 137.861C832.99 137.731 832.817 137.657 832.636 137.657Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M842.056 121.708H812.055C811.876 121.708 811.705 121.779 811.58 121.907L800.332 133.376C800.211 133.499 800.143 133.664 800.142 133.836L799.918 163.845C799.916 164.027 799.99 164.202 800.122 164.329L811.583 175.349C811.707 175.468 811.872 175.535 812.043 175.535H842.287C842.466 175.535 842.637 175.463 842.762 175.335L853.779 164.098C853.902 163.973 853.97 163.804 853.969 163.628L853.745 133.833C853.743 133.663 853.677 133.5 853.559 133.377L842.535 121.912C842.409 121.781 842.236 121.708 842.056 121.708Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M25.7247 196.013H5.91143C5.73295 196.013 5.56197 196.085 5.43699 196.212L-1.93429 203.728C-2.05493 203.851 -2.12308 204.016 -2.12436 204.189L-2.27228 224.009C-2.27364 224.192 -2.1999 224.367 -2.06836 224.493L5.43945 231.712C5.56324 231.831 5.72831 231.898 5.90004 231.898H25.8805C26.0591 231.898 26.2301 231.826 26.3551 231.699L33.5703 224.339C33.6933 224.214 33.7616 224.045 33.7603 223.869L33.6123 204.185C33.611 204.015 33.5446 203.852 33.4268 203.729L26.2037 196.217C26.0784 196.087 25.9054 196.013 25.7247 196.013Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M21.8095 202.659H9.59674C9.41826 202.659 9.24728 202.73 9.12231 202.858L4.64376 207.424C4.52312 207.547 4.45497 207.712 4.45368 207.885L4.36248 220.105C4.36112 220.287 4.43486 220.462 4.56641 220.589L9.12476 224.972C9.24856 225.091 9.41362 225.157 9.58536 225.157H21.9086C22.0871 225.157 22.2582 225.086 22.3831 224.958L26.7624 220.491C26.8854 220.366 26.9537 220.197 26.9524 220.021L26.8611 207.881C26.8598 207.711 26.7934 207.548 26.6756 207.425L22.2885 202.863C22.1632 202.732 21.9902 202.659 21.8095 202.659Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M31.229 186.709H1.22811C1.04963 186.709 0.878648 186.781 0.753674 186.908L-10.495 198.378C-10.6156 198.501 -10.6838 198.666 -10.6851 198.838L-10.909 228.846C-10.9104 229.029 -10.8366 229.204 -10.7051 229.33L0.756127 240.351C0.879919 240.47 1.04498 240.536 1.21672 240.536H31.4608C31.6394 240.536 31.8104 240.464 31.9354 240.337L42.9519 229.1C43.0749 228.975 43.1432 228.806 43.1419 228.63L42.9179 198.834C42.9166 198.664 42.8502 198.501 42.7324 198.379L31.708 186.913C31.5827 186.783 31.4097 186.709 31.229 186.709Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M79.7794 196.013H59.9661C59.7876 196.013 59.6167 196.085 59.4917 196.212L52.1204 203.728C51.9998 203.851 51.9316 204.016 51.9303 204.189L51.7824 224.009C51.781 224.192 51.8548 224.367 51.9863 224.493L59.4941 231.712C59.6179 231.831 59.783 231.898 59.9547 231.898H79.9352C80.1137 231.898 80.2848 231.826 80.4097 231.699L87.625 224.339C87.748 224.214 87.8163 224.045 87.815 223.869L87.667 204.185C87.6657 204.015 87.5993 203.852 87.4815 203.729L80.2584 196.217C80.1331 196.087 79.9601 196.013 79.7794 196.013Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M75.8642 202.659H63.6514C63.473 202.659 63.302 202.73 63.177 202.858L58.6984 207.424C58.5778 207.547 58.5097 207.712 58.5084 207.885L58.4172 220.105C58.4158 220.287 58.4896 220.462 58.6211 220.589L63.1795 224.972C63.3032 225.091 63.4683 225.157 63.64 225.157H75.9633C76.1418 225.157 76.3128 225.085 76.4378 224.958L80.8171 220.491C80.9401 220.366 81.0084 220.197 81.007 220.021L80.9158 207.881C80.9145 207.711 80.8481 207.548 80.7303 207.425L76.3432 202.863C76.2179 202.732 76.0449 202.659 75.8642 202.659Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M85.2836 186.709H55.2828C55.1043 186.709 54.9333 186.781 54.8084 186.908L43.5597 198.378C43.4391 198.501 43.3709 198.666 43.3696 198.838L43.1457 228.846C43.1443 229.029 43.2181 229.204 43.3496 229.33L54.8108 240.351C54.9346 240.47 55.0997 240.536 55.2714 240.536H85.5155C85.694 240.536 85.8651 240.464 85.99 240.337L97.0066 229.1C97.1296 228.975 97.1979 228.806 97.1966 228.63L96.9726 198.834C96.9713 198.664 96.9049 198.501 96.7871 198.379L85.7627 186.913C85.6374 186.783 85.4644 186.709 85.2836 186.709Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M133.835 196.013H114.022C113.843 196.013 113.672 196.085 113.547 196.212L106.176 203.728C106.055 203.851 105.987 204.016 105.986 204.189L105.838 224.009C105.837 224.192 105.91 224.367 106.042 224.493L113.55 231.712C113.674 231.831 113.839 231.898 114.01 231.898H133.991C134.169 231.898 134.34 231.826 134.465 231.699L141.681 224.339C141.804 224.214 141.872 224.045 141.871 223.869L141.723 204.185C141.721 204.015 141.655 203.852 141.537 203.729L134.314 196.217C134.189 196.087 134.016 196.013 133.835 196.013Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M129.92 202.659H117.707C117.529 202.659 117.358 202.73 117.233 202.858L112.754 207.424C112.633 207.547 112.565 207.712 112.564 207.885L112.473 220.105C112.471 220.287 112.545 220.462 112.677 220.589L117.235 224.972C117.359 225.091 117.524 225.157 117.696 225.157H130.019C130.197 225.157 130.369 225.085 130.493 224.958L134.873 220.491C134.996 220.366 135.064 220.197 135.063 220.021L134.971 207.881C134.97 207.711 134.904 207.548 134.786 207.425L130.399 202.863C130.274 202.732 130.101 202.659 129.92 202.659Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M139.339 186.709H109.338C109.16 186.709 108.989 186.781 108.864 186.908L97.6154 198.378C97.4947 198.501 97.4266 198.666 97.4253 198.838L97.2014 228.846C97.2 229.029 97.2737 229.204 97.4053 229.33L108.866 240.351C108.99 240.47 109.155 240.536 109.327 240.536H139.571C139.75 240.536 139.921 240.464 140.046 240.337L151.062 229.1C151.185 228.975 151.254 228.806 151.252 228.63L151.028 198.834C151.027 198.664 150.961 198.501 150.843 198.379L139.818 186.913C139.693 186.783 139.52 186.709 139.339 186.709Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M187.89 196.013H168.076C167.898 196.013 167.727 196.085 167.602 196.212L160.231 203.728C160.11 203.851 160.042 204.016 160.041 204.189L159.893 224.009C159.891 224.192 159.965 224.367 160.097 224.493L167.604 231.712C167.728 231.831 167.893 231.898 168.065 231.898H188.046C188.224 231.898 188.395 231.826 188.52 231.699L195.735 224.339C195.858 224.214 195.927 224.045 195.925 223.869L195.777 204.185C195.776 204.015 195.71 203.852 195.592 203.729L188.369 196.217C188.243 196.087 188.07 196.013 187.89 196.013Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M183.975 202.659H171.762C171.584 202.659 171.413 202.73 171.288 202.858L166.809 207.424C166.689 207.547 166.62 207.712 166.619 207.885L166.528 220.105C166.527 220.287 166.6 220.462 166.732 220.589L171.29 224.972C171.414 225.091 171.579 225.157 171.751 225.157H184.074C184.253 225.157 184.424 225.085 184.549 224.958L188.928 220.491C189.051 220.366 189.119 220.197 189.118 220.021L189.027 207.881C189.025 207.711 188.959 207.548 188.841 207.425L184.454 202.863C184.329 202.732 184.156 202.659 183.975 202.659Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M193.394 186.709H163.394C163.215 186.709 163.044 186.781 162.919 186.908L151.671 198.378C151.55 198.501 151.482 198.666 151.48 198.838L151.257 228.846C151.255 229.029 151.329 229.204 151.46 229.33L162.922 240.351C163.045 240.47 163.211 240.536 163.382 240.536H193.626C193.805 240.536 193.976 240.464 194.101 240.337L205.117 229.1C205.24 228.975 205.309 228.806 205.307 228.63L205.083 198.834C205.082 198.664 205.016 198.501 204.898 198.379L193.873 186.913C193.748 186.783 193.575 186.709 193.394 186.709Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M241.945 196.013H222.132C221.953 196.013 221.782 196.085 221.657 196.212L214.286 203.728C214.165 203.851 214.097 204.016 214.096 204.189L213.948 224.009C213.947 224.192 214.02 224.367 214.152 224.493L221.66 231.712C221.783 231.831 221.949 231.898 222.12 231.898H242.101C242.279 231.898 242.45 231.826 242.575 231.699L249.791 224.339C249.914 224.214 249.982 224.045 249.98 223.869L249.832 204.185C249.831 204.015 249.765 203.852 249.647 203.729L242.424 196.217C242.299 196.087 242.126 196.013 241.945 196.013Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M238.03 202.659H225.817C225.639 202.659 225.468 202.73 225.343 202.858L220.864 207.424C220.744 207.547 220.676 207.712 220.674 207.885L220.583 220.105C220.582 220.287 220.656 220.462 220.787 220.589L225.345 224.972C225.469 225.091 225.634 225.157 225.806 225.157H238.129C238.308 225.157 238.479 225.085 238.604 224.958L242.983 220.491C243.106 220.366 243.174 220.197 243.173 220.021L243.082 207.881C243.08 207.711 243.014 207.548 242.896 207.425L238.509 202.863C238.384 202.732 238.211 202.659 238.03 202.659Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M247.45 186.709H217.449C217.27 186.709 217.099 186.781 216.974 186.908L205.726 198.378C205.605 198.501 205.537 198.666 205.536 198.838L205.312 228.846C205.31 229.029 205.384 229.204 205.516 229.33L216.977 240.351C217.101 240.47 217.266 240.536 217.437 240.536H247.682C247.86 240.536 248.031 240.464 248.156 240.337L259.173 229.1C259.296 228.975 259.364 228.806 259.363 228.63L259.139 198.834C259.137 198.664 259.071 198.501 258.953 198.379L247.929 186.913C247.803 186.783 247.63 186.709 247.45 186.709Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M296 196.013H276.187C276.008 196.013 275.837 196.085 275.712 196.212L268.341 203.728C268.22 203.851 268.152 204.016 268.151 204.189L268.003 224.009C268.002 224.192 268.075 224.367 268.207 224.493L275.715 231.712C275.839 231.831 276.004 231.898 276.175 231.898H296.156C296.334 231.898 296.505 231.826 296.63 231.699L303.846 224.339C303.969 224.214 304.037 224.045 304.036 223.869L303.888 204.185C303.886 204.015 303.82 203.852 303.702 203.729L296.479 196.217C296.354 196.087 296.181 196.013 296 196.013Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M292.085 202.659H279.872C279.694 202.659 279.523 202.73 279.398 202.858L274.919 207.424C274.799 207.547 274.73 207.712 274.729 207.885L274.638 220.105C274.637 220.287 274.71 220.462 274.842 220.589L279.4 224.972C279.524 225.091 279.689 225.157 279.861 225.157H292.184C292.363 225.157 292.534 225.085 292.659 224.958L297.038 220.491C297.161 220.366 297.229 220.197 297.228 220.021L297.136 207.881C297.135 207.711 297.069 207.548 296.951 207.425L292.564 202.863C292.439 202.732 292.266 202.659 292.085 202.659Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M301.504 186.709H271.503C271.325 186.709 271.154 186.781 271.029 186.908L259.78 198.378C259.66 198.501 259.592 198.666 259.59 198.838L259.366 228.846C259.365 229.029 259.439 229.204 259.57 229.33L271.032 240.351C271.155 240.47 271.32 240.536 271.492 240.536H301.736C301.915 240.536 302.086 240.464 302.211 240.337L313.227 229.1C313.35 228.975 313.419 228.806 313.417 228.63L313.193 198.834C313.192 198.664 313.126 198.501 313.008 198.379L301.983 186.913C301.858 186.783 301.685 186.709 301.504 186.709Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M350.055 196.013H330.242C330.064 196.013 329.893 196.085 329.768 196.212L322.396 203.728C322.276 203.851 322.207 204.016 322.206 204.189L322.058 224.009C322.057 224.192 322.131 224.367 322.262 224.493L329.77 231.712C329.894 231.831 330.059 231.898 330.231 231.898H350.211C350.39 231.898 350.561 231.826 350.686 231.699L357.901 224.339C358.024 224.214 358.092 224.045 358.091 223.869L357.943 204.185C357.942 204.015 357.875 203.852 357.757 203.729L350.534 196.217C350.409 196.087 350.236 196.013 350.055 196.013Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M346.14 202.659H333.927C333.749 202.659 333.578 202.73 333.453 202.858L328.974 207.424C328.854 207.547 328.786 207.712 328.784 207.885L328.693 220.105C328.692 220.287 328.765 220.462 328.897 220.589L333.455 224.972C333.579 225.091 333.744 225.157 333.916 225.157H346.239C346.418 225.157 346.589 225.085 346.714 224.958L351.093 220.491C351.216 220.366 351.284 220.197 351.283 220.021L351.192 207.881C351.19 207.711 351.124 207.548 351.006 207.425L346.619 202.863C346.494 202.732 346.321 202.659 346.14 202.659Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M355.56 186.709H325.559C325.38 186.709 325.209 186.781 325.084 186.908L313.836 198.378C313.715 198.501 313.647 198.666 313.646 198.838L313.422 228.846C313.42 229.029 313.494 229.204 313.625 229.33L325.087 240.351C325.21 240.47 325.376 240.536 325.547 240.536H355.791C355.97 240.536 356.141 240.464 356.266 240.337L367.283 229.1C367.406 228.975 367.474 228.806 367.472 228.63L367.248 198.834C367.247 198.664 367.181 198.501 367.063 198.379L356.039 186.913C355.913 186.783 355.74 186.709 355.56 186.709Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M404.11 196.013H384.297C384.119 196.013 383.948 196.085 383.823 196.212L376.451 203.728C376.331 203.851 376.263 204.016 376.261 204.189L376.113 224.009C376.112 224.192 376.186 224.367 376.317 224.493L383.825 231.712C383.949 231.831 384.114 231.898 384.286 231.898H404.266C404.445 231.898 404.616 231.826 404.741 231.699L411.956 224.339C412.079 224.214 412.147 224.045 412.146 223.869L411.998 204.185C411.997 204.015 411.93 203.852 411.813 203.729L404.589 196.217C404.464 196.087 404.291 196.013 404.11 196.013Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M400.195 202.659H387.982C387.804 202.659 387.633 202.73 387.508 202.858L383.03 207.424C382.909 207.547 382.841 207.712 382.839 207.885L382.748 220.105C382.747 220.287 382.821 220.462 382.952 220.589L387.511 224.972C387.634 225.091 387.799 225.157 387.971 225.157H400.294C400.473 225.157 400.644 225.085 400.769 224.958L405.148 220.491C405.271 220.366 405.339 220.197 405.338 220.021L405.247 207.881C405.246 207.711 405.179 207.548 405.061 207.425L400.674 202.863C400.549 202.732 400.376 202.659 400.195 202.659Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M409.615 186.709H379.614C379.435 186.709 379.264 186.781 379.139 186.908L367.891 198.378C367.77 198.501 367.702 198.666 367.701 198.838L367.477 228.846C367.475 229.029 367.549 229.204 367.681 229.33L379.142 240.351C379.266 240.47 379.431 240.536 379.602 240.536H409.847C410.025 240.536 410.196 240.464 410.321 240.337L421.338 229.1C421.461 228.975 421.529 228.806 421.528 228.63L421.304 198.834C421.302 198.664 421.236 198.501 421.118 198.379L410.094 186.913C409.968 186.783 409.795 186.709 409.615 186.709Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M458.165 196.013H438.352C438.173 196.013 438.002 196.085 437.877 196.212L430.506 203.728C430.386 203.851 430.317 204.016 430.316 204.189L430.168 224.009C430.167 224.192 430.241 224.367 430.372 224.493L437.88 231.712C438.004 231.831 438.169 231.898 438.34 231.898H458.321C458.499 231.898 458.67 231.826 458.795 231.699L466.011 224.339C466.134 224.214 466.202 224.045 466.201 223.869L466.053 204.185C466.051 204.015 465.985 203.852 465.867 203.729L458.644 196.217C458.519 196.087 458.346 196.013 458.165 196.013Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M454.25 202.659H442.037C441.859 202.659 441.688 202.73 441.563 202.858L437.084 207.424C436.964 207.547 436.895 207.712 436.894 207.885L436.803 220.105C436.802 220.287 436.875 220.462 437.007 220.589L441.565 224.972C441.689 225.091 441.854 225.157 442.026 225.157H454.349C454.528 225.157 454.699 225.085 454.824 224.958L459.203 220.491C459.326 220.366 459.394 220.197 459.393 220.021L459.302 207.881C459.3 207.711 459.234 207.548 459.116 207.425L454.729 202.863C454.604 202.732 454.431 202.659 454.25 202.659Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M463.67 186.709H433.669C433.491 186.709 433.32 186.781 433.195 186.908L421.946 198.378C421.825 198.501 421.757 198.666 421.756 198.838L421.532 228.846C421.531 229.029 421.604 229.204 421.736 229.33L433.197 240.351C433.321 240.47 433.486 240.536 433.658 240.536H463.902C464.08 240.536 464.251 240.464 464.376 240.337L475.393 229.1C475.516 228.975 475.584 228.806 475.583 228.63L475.359 198.834C475.358 198.664 475.291 198.501 475.173 198.379L464.149 186.913C464.024 186.783 463.851 186.709 463.67 186.709Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M512.22 196.013H492.407C492.229 196.013 492.058 196.085 491.933 196.212L484.561 203.728C484.441 203.851 484.373 204.016 484.371 204.189L484.223 224.009C484.222 224.192 484.296 224.367 484.427 224.493L491.935 231.712C492.059 231.831 492.224 231.898 492.396 231.898H512.376C512.555 231.898 512.726 231.826 512.851 231.699L520.066 224.339C520.189 224.214 520.257 224.045 520.256 223.869L520.108 204.185C520.107 204.015 520.04 203.852 519.922 203.729L512.699 196.217C512.574 196.087 512.401 196.013 512.22 196.013Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M508.305 202.659H496.092C495.914 202.659 495.743 202.73 495.618 202.858L491.139 207.424C491.019 207.547 490.951 207.712 490.949 207.885L490.858 220.105C490.857 220.287 490.93 220.462 491.062 220.589L495.62 224.972C495.744 225.091 495.909 225.157 496.081 225.157H508.404C508.583 225.157 508.754 225.085 508.879 224.958L513.258 220.491C513.381 220.366 513.449 220.197 513.448 220.021L513.357 207.881C513.355 207.711 513.289 207.548 513.171 207.425L508.784 202.863C508.659 202.732 508.486 202.659 508.305 202.659Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M517.725 186.709H487.724C487.546 186.709 487.375 186.781 487.25 186.908L476.001 198.378C475.88 198.501 475.812 198.666 475.811 198.838L475.587 228.846C475.586 229.029 475.659 229.204 475.791 229.33L487.252 240.351C487.376 240.47 487.541 240.536 487.713 240.536H517.957C518.135 240.536 518.306 240.464 518.431 240.337L529.448 229.1C529.571 228.975 529.639 228.806 529.638 228.63L529.414 198.834C529.413 198.664 529.346 198.501 529.228 198.379L518.204 186.913C518.079 186.783 517.906 186.709 517.725 186.709Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M566.275 196.013H546.462C546.284 196.013 546.113 196.085 545.988 196.212L538.616 203.728C538.496 203.851 538.428 204.016 538.426 204.189L538.279 224.009C538.277 224.192 538.351 224.367 538.482 224.493L545.99 231.712C546.114 231.831 546.279 231.898 546.451 231.898H566.431C566.61 231.898 566.781 231.826 566.906 231.699L574.121 224.339C574.244 224.214 574.312 224.045 574.311 223.869L574.163 204.185C574.162 204.015 574.095 203.852 573.978 203.729L566.754 196.217C566.629 196.087 566.456 196.013 566.275 196.013Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M562.36 202.659H550.148C549.969 202.659 549.798 202.73 549.673 202.858L545.195 207.424C545.074 207.547 545.006 207.712 545.004 207.885L544.913 220.105C544.912 220.287 544.986 220.462 545.117 220.589L549.676 224.972C549.799 225.091 549.964 225.157 550.136 225.157H562.459C562.638 225.157 562.809 225.085 562.934 224.958L567.313 220.491C567.436 220.366 567.504 220.197 567.503 220.021L567.412 207.881C567.411 207.711 567.344 207.548 567.226 207.425L562.839 202.863C562.714 202.732 562.541 202.659 562.36 202.659Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M571.78 186.709H541.779C541.601 186.709 541.43 186.781 541.305 186.908L530.056 198.378C529.936 198.501 529.867 198.666 529.866 198.838L529.642 228.846C529.641 229.029 529.715 229.204 529.846 229.33L541.307 240.351C541.431 240.47 541.596 240.536 541.768 240.536H572.012C572.191 240.536 572.362 240.464 572.487 240.337L583.503 229.1C583.626 228.975 583.695 228.806 583.693 228.63L583.469 198.834C583.468 198.664 583.402 198.501 583.284 198.379L572.259 186.913C572.134 186.783 571.961 186.709 571.78 186.709Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M620.331 196.013H600.517C600.339 196.013 600.168 196.085 600.043 196.212L592.672 203.728C592.551 203.851 592.483 204.016 592.482 204.189L592.334 224.009C592.332 224.192 592.406 224.367 592.538 224.493L600.045 231.712C600.169 231.831 600.334 231.898 600.506 231.898H620.486C620.665 231.898 620.836 231.826 620.961 231.699L628.176 224.339C628.299 224.214 628.368 224.045 628.366 223.869L628.218 204.185C628.217 204.015 628.151 203.852 628.033 203.729L620.81 196.217C620.684 196.087 620.511 196.013 620.331 196.013Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M616.415 202.659H604.203C604.024 202.659 603.853 202.73 603.728 202.858L599.25 207.424C599.129 207.547 599.061 207.712 599.06 207.885L598.968 220.105C598.967 220.287 599.041 220.462 599.172 220.589L603.731 224.972C603.855 225.091 604.02 225.157 604.191 225.157H616.515C616.693 225.157 616.864 225.085 616.989 224.958L621.368 220.491C621.491 220.366 621.56 220.197 621.558 220.021L621.467 207.881C621.466 207.711 621.399 207.548 621.282 207.425L616.894 202.863C616.769 202.732 616.596 202.659 616.415 202.659Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M625.835 186.709H595.835C595.656 186.709 595.485 186.781 595.36 186.908L584.111 198.378C583.991 198.501 583.923 198.666 583.921 198.838L583.697 228.846C583.696 229.029 583.77 229.204 583.901 229.33L595.363 240.351C595.486 240.47 595.651 240.536 595.823 240.536H626.067C626.246 240.536 626.417 240.464 626.542 240.337L637.558 229.1C637.681 228.975 637.75 228.806 637.748 228.63L637.524 198.834C637.523 198.664 637.457 198.501 637.339 198.379L626.314 186.913C626.189 186.783 626.016 186.709 625.835 186.709Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M674.386 196.013H654.573C654.394 196.013 654.223 196.085 654.098 196.212L646.727 203.728C646.606 203.851 646.538 204.016 646.537 204.189L646.389 224.009C646.387 224.192 646.461 224.367 646.593 224.493L654.101 231.712C654.224 231.831 654.389 231.898 654.561 231.898H674.542C674.72 231.898 674.891 231.826 675.016 231.699L682.231 224.339C682.354 224.214 682.423 224.045 682.421 223.869L682.273 204.185C682.272 204.015 682.206 203.852 682.088 203.729L674.865 196.217C674.74 196.087 674.567 196.013 674.386 196.013Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M670.471 202.659H658.258C658.079 202.659 657.908 202.73 657.783 202.858L653.305 207.424C653.184 207.547 653.116 207.712 653.115 207.885L653.024 220.105C653.022 220.287 653.096 220.462 653.228 220.589L657.786 224.972C657.91 225.091 658.075 225.157 658.246 225.157H670.57C670.748 225.157 670.919 225.085 671.044 224.958L675.424 220.491C675.546 220.366 675.615 220.197 675.613 220.021L675.522 207.881C675.521 207.711 675.455 207.548 675.337 207.425L670.95 202.863C670.824 202.732 670.651 202.659 670.471 202.659Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M679.891 186.709H649.89C649.711 186.709 649.54 186.781 649.415 186.908L638.167 198.378C638.046 198.501 637.978 198.666 637.977 198.838L637.753 228.846C637.751 229.029 637.825 229.204 637.957 229.33L649.418 240.351C649.542 240.47 649.707 240.536 649.878 240.536H680.122C680.301 240.536 680.472 240.464 680.597 240.337L691.614 229.1C691.737 228.975 691.805 228.806 691.804 228.63L691.58 198.834C691.578 198.664 691.512 198.501 691.394 198.379L680.37 186.913C680.244 186.783 680.071 186.709 679.891 186.709Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M728.441 196.013H708.628C708.449 196.013 708.278 196.085 708.153 196.212L700.782 203.728C700.661 203.851 700.593 204.016 700.592 204.189L700.444 224.009C700.443 224.192 700.516 224.367 700.648 224.493L708.156 231.712C708.28 231.831 708.445 231.898 708.616 231.898H728.597C728.775 231.898 728.946 231.826 729.071 231.699L736.287 224.339C736.41 224.214 736.478 224.045 736.477 223.869L736.329 204.185C736.327 204.015 736.261 203.852 736.143 203.729L728.92 196.217C728.795 196.087 728.622 196.013 728.441 196.013Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M724.526 202.659H712.313C712.135 202.659 711.964 202.73 711.839 202.858L707.36 207.424C707.239 207.547 707.171 207.712 707.17 207.885L707.079 220.105C707.077 220.287 707.151 220.462 707.283 220.589L711.841 224.972C711.965 225.091 712.13 225.157 712.302 225.157H724.625C724.803 225.157 724.974 225.085 725.099 224.958L729.479 220.491C729.602 220.366 729.67 220.197 729.669 220.021L729.577 207.881C729.576 207.711 729.51 207.548 729.392 207.425L725.005 202.863C724.88 202.732 724.707 202.659 724.526 202.659Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M733.946 186.709H703.945C703.766 186.709 703.595 186.781 703.47 186.908L692.222 198.378C692.101 198.501 692.033 198.666 692.032 198.838L691.808 228.846C691.806 229.029 691.88 229.204 692.012 229.33L703.473 240.351C703.597 240.47 703.762 240.536 703.934 240.536H734.178C734.356 240.536 734.527 240.464 734.652 240.337L745.669 229.1C745.792 228.975 745.86 228.806 745.859 228.63L745.635 198.834C745.633 198.664 745.567 198.501 745.449 198.379L734.425 186.913C734.299 186.783 734.127 186.709 733.946 186.709Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M782.496 196.013H762.682C762.504 196.013 762.333 196.085 762.208 196.212L754.837 203.728C754.716 203.851 754.648 204.016 754.647 204.189L754.499 224.009C754.497 224.192 754.571 224.367 754.703 224.493L762.21 231.712C762.334 231.831 762.499 231.898 762.671 231.898H782.652C782.83 231.898 783.001 231.826 783.126 231.699L790.341 224.339C790.464 224.214 790.533 224.045 790.531 223.869L790.383 204.185C790.382 204.015 790.316 203.852 790.198 203.729L782.975 196.217C782.849 196.087 782.676 196.013 782.496 196.013Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M778.58 202.659H766.368C766.189 202.659 766.018 202.73 765.893 202.858L761.415 207.424C761.294 207.547 761.226 207.712 761.225 207.885L761.133 220.105C761.132 220.287 761.206 220.462 761.337 220.589L765.896 224.972C766.02 225.091 766.185 225.157 766.356 225.157H778.68C778.858 225.157 779.029 225.085 779.154 224.958L783.533 220.491C783.656 220.366 783.725 220.197 783.723 220.021L783.632 207.881C783.631 207.711 783.564 207.548 783.447 207.425L779.059 202.863C778.934 202.732 778.761 202.659 778.58 202.659Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M788 186.709H758C757.821 186.709 757.65 186.781 757.525 186.908L746.277 198.378C746.156 198.501 746.088 198.666 746.086 198.838L745.862 228.846C745.861 229.029 745.935 229.204 746.066 229.33L757.528 240.351C757.651 240.47 757.816 240.536 757.988 240.536H788.232C788.411 240.536 788.582 240.464 788.707 240.337L799.723 229.1C799.846 228.975 799.915 228.806 799.913 228.63L799.689 198.834C799.688 198.664 799.622 198.501 799.504 198.379L788.479 186.913C788.354 186.783 788.181 186.709 788 186.709Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M836.551 196.013H816.738C816.559 196.013 816.388 196.085 816.263 196.212L808.892 203.728C808.771 203.851 808.703 204.016 808.702 204.189L808.554 224.009C808.553 224.192 808.626 224.367 808.758 224.493L816.266 231.712C816.389 231.831 816.554 231.898 816.726 231.898H836.707C836.885 231.898 837.056 231.826 837.181 231.699L844.396 224.339C844.519 224.214 844.588 224.045 844.586 223.869L844.438 204.185C844.437 204.015 844.371 203.852 844.253 203.729L837.03 196.217C836.905 196.087 836.732 196.013 836.551 196.013Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M832.636 202.659H820.423C820.245 202.659 820.074 202.73 819.949 202.858L815.47 207.424C815.35 207.547 815.282 207.712 815.28 207.885L815.189 220.105C815.188 220.287 815.262 220.462 815.393 220.589L819.951 224.972C820.075 225.091 820.24 225.157 820.412 225.157H832.735C832.914 225.157 833.085 225.085 833.21 224.958L837.589 220.491C837.712 220.366 837.78 220.197 837.779 220.021L837.688 207.881C837.686 207.711 837.62 207.548 837.502 207.425L833.115 202.863C832.99 202.732 832.817 202.659 832.636 202.659Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M842.056 186.709H812.055C811.876 186.709 811.705 186.781 811.58 186.908L800.332 198.378C800.211 198.501 800.143 198.666 800.142 198.838L799.918 228.846C799.916 229.029 799.99 229.204 800.122 229.33L811.583 240.351C811.707 240.47 811.872 240.536 812.043 240.536H842.287C842.466 240.536 842.637 240.464 842.762 240.337L853.779 229.1C853.902 228.975 853.97 228.806 853.969 228.63L853.745 198.834C853.743 198.664 853.677 198.501 853.559 198.379L842.535 186.913C842.409 186.783 842.236 186.709 842.056 186.709Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M25.7247 261.015H5.91143C5.73295 261.015 5.56197 261.087 5.43699 261.214L-1.93429 268.73C-2.05493 268.853 -2.12308 269.018 -2.12436 269.19L-2.27228 289.011C-2.27364 289.194 -2.1999 289.369 -2.06836 289.495L5.43945 296.714C5.56324 296.833 5.72831 296.9 5.90004 296.9H25.8805C26.0591 296.9 26.2301 296.828 26.3551 296.7L33.5703 289.341C33.6933 289.215 33.7616 289.046 33.7603 288.871L33.6123 269.186C33.611 269.016 33.5446 268.853 33.4268 268.731L26.2037 261.219C26.0784 261.089 25.9054 261.015 25.7247 261.015Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M21.8095 267.66H9.59674C9.41826 267.66 9.24728 267.732 9.12231 267.859L4.64376 272.426C4.52312 272.549 4.45497 272.714 4.45368 272.886L4.36248 285.106C4.36112 285.289 4.43486 285.464 4.56641 285.59L9.12476 289.973C9.24856 290.093 9.41362 290.159 9.58536 290.159H21.9086C22.0871 290.159 22.2582 290.087 22.3831 289.96L26.7624 285.493C26.8854 285.367 26.9537 285.198 26.9524 285.023L26.8611 272.882C26.8598 272.712 26.7934 272.549 26.6756 272.427L22.2885 267.864C22.1632 267.734 21.9902 267.66 21.8095 267.66Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M31.229 251.711H1.22811C1.04963 251.711 0.878648 251.783 0.753674 251.91L-10.495 263.379C-10.6156 263.502 -10.6838 263.667 -10.6851 263.84L-10.909 293.848C-10.9104 294.031 -10.8366 294.206 -10.7051 294.332L0.756127 305.352C0.879919 305.471 1.04498 305.538 1.21672 305.538H31.4608C31.6394 305.538 31.8104 305.466 31.9354 305.339L42.9519 294.102C43.0749 293.976 43.1432 293.807 43.1419 293.632L42.9179 263.836C42.9166 263.666 42.8502 263.503 42.7324 263.38L31.708 251.915C31.5827 251.785 31.4097 251.711 31.229 251.711Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M88.0489 261.015H68.2356C68.0572 261.015 67.8862 261.087 67.7612 261.214L60.3899 268.73C60.2693 268.853 60.2011 269.018 60.1999 269.19L60.0519 289.011C60.0506 289.194 60.1243 289.369 60.2559 289.495L67.7637 296.714C67.8875 296.833 68.0525 296.9 68.2243 296.9H88.2047C88.3833 296.9 88.5543 296.828 88.6793 296.7L95.8945 289.341C96.0175 289.215 96.0858 289.046 96.0845 288.871L95.9365 269.186C95.9352 269.016 95.8688 268.853 95.751 268.731L88.5279 261.219C88.4026 261.089 88.2297 261.015 88.0489 261.015Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M84.1337 267.66H71.921C71.7425 267.66 71.5715 267.732 71.4465 267.859L66.968 272.426C66.8473 272.549 66.7792 272.714 66.7779 272.886L66.6867 285.106C66.6853 285.289 66.7591 285.464 66.8906 285.59L71.449 289.973C71.5728 290.093 71.7378 290.159 71.9096 290.159H84.2328C84.4114 290.159 84.5824 290.087 84.7074 289.96L89.0866 285.493C89.2096 285.367 89.2779 285.198 89.2766 285.023L89.1853 272.882C89.184 272.712 89.1176 272.549 88.9998 272.427L84.6127 267.864C84.4874 267.734 84.3145 267.66 84.1337 267.66Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M93.5532 251.711H63.5523C63.3738 251.711 63.2029 251.783 63.0779 251.91L51.8292 263.379C51.7086 263.502 51.6404 263.667 51.6392 263.84L51.4152 293.848C51.4139 294.031 51.4876 294.206 51.6191 294.332L63.0803 305.352C63.2041 305.471 63.3692 305.538 63.5409 305.538H93.7851C93.9636 305.538 94.1346 305.466 94.2596 305.339L105.276 294.102C105.399 293.976 105.467 293.807 105.466 293.632L105.242 263.836C105.241 263.666 105.174 263.503 105.057 263.38L94.0322 251.915C93.9069 251.785 93.7339 251.711 93.5532 251.711Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M150.373 261.015H130.56C130.381 261.015 130.21 261.087 130.085 261.214L122.714 268.73C122.594 268.853 122.525 269.018 122.524 269.19L122.376 289.011C122.375 289.194 122.449 289.369 122.58 289.495L130.088 296.714C130.212 296.833 130.377 296.9 130.548 296.9H150.529C150.707 296.9 150.879 296.828 151.003 296.7L158.219 289.341C158.342 289.215 158.41 289.046 158.409 288.871L158.261 269.186C158.259 269.016 158.193 268.853 158.075 268.731L150.852 261.219C150.727 261.089 150.554 261.015 150.373 261.015Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M146.458 267.66H134.245C134.067 267.66 133.896 267.732 133.771 267.859L129.292 272.426C129.172 272.549 129.103 272.714 129.102 272.886L129.011 285.106C129.01 285.289 129.083 285.464 129.215 285.59L133.773 289.973C133.897 290.093 134.062 290.159 134.234 290.159H146.557C146.736 290.159 146.907 290.087 147.032 289.96L151.411 285.493C151.534 285.367 151.602 285.198 151.601 285.023L151.51 272.882C151.508 272.712 151.442 272.549 151.324 272.427L146.937 267.864C146.812 267.734 146.639 267.66 146.458 267.66Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M155.877 251.711H125.877C125.698 251.711 125.527 251.783 125.402 251.91L114.153 263.379C114.033 263.502 113.965 263.667 113.963 263.84L113.739 293.848C113.738 294.031 113.812 294.206 113.943 294.332L125.405 305.352C125.528 305.471 125.693 305.538 125.865 305.538H156.109C156.288 305.538 156.459 305.466 156.584 305.339L167.6 294.102C167.723 293.976 167.792 293.807 167.79 293.632L167.566 263.836C167.565 263.666 167.499 263.503 167.381 263.38L156.356 251.915C156.231 251.785 156.058 251.711 155.877 251.711Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M212.697 261.015H192.884C192.706 261.015 192.535 261.087 192.41 261.214L185.038 268.73C184.918 268.853 184.85 269.018 184.848 269.19L184.7 289.011C184.699 289.194 184.773 289.369 184.904 289.495L192.412 296.714C192.536 296.833 192.701 296.9 192.873 296.9H212.853C213.032 296.9 213.203 296.828 213.328 296.7L220.543 289.341C220.666 289.215 220.734 289.046 220.733 288.871L220.585 269.186C220.584 269.016 220.517 268.853 220.399 268.731L213.176 261.219C213.051 261.089 212.878 261.015 212.697 261.015Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M208.783 267.66H196.57C196.391 267.66 196.22 267.732 196.095 267.859L191.617 272.426C191.496 272.549 191.428 272.714 191.427 272.886L191.336 285.106C191.334 285.289 191.408 285.464 191.54 285.59L196.098 289.973C196.222 290.093 196.387 290.159 196.559 290.159H208.882C209.06 290.159 209.231 290.087 209.356 289.96L213.736 285.493C213.859 285.367 213.927 285.198 213.925 285.023L213.834 272.882C213.833 272.712 213.767 272.549 213.649 272.427L209.262 267.864C209.136 267.734 208.963 267.66 208.783 267.66Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M218.202 251.711H188.201C188.023 251.711 187.852 251.783 187.727 251.91L176.478 263.379C176.358 263.502 176.289 263.667 176.288 263.84L176.064 293.848C176.063 294.031 176.137 294.206 176.268 294.332L187.729 305.352C187.853 305.471 188.018 305.538 188.19 305.538H218.434C218.613 305.538 218.784 305.466 218.909 305.339L229.925 294.102C230.048 293.976 230.116 293.807 230.115 293.632L229.891 263.836C229.89 263.666 229.823 263.503 229.706 263.38L218.681 251.915C218.556 251.785 218.383 251.711 218.202 251.711Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M275.022 261.015H255.208C255.03 261.015 254.859 261.087 254.734 261.214L247.363 268.73C247.242 268.853 247.174 269.018 247.173 269.19L247.025 289.011C247.023 289.194 247.097 289.369 247.229 289.495L254.736 296.714C254.86 296.833 255.025 296.9 255.197 296.9H275.177C275.356 296.9 275.527 296.828 275.652 296.7L282.867 289.341C282.99 289.215 283.058 289.046 283.057 288.871L282.909 269.186C282.908 269.016 282.841 268.853 282.724 268.731L275.501 261.219C275.375 261.089 275.202 261.015 275.022 261.015Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M271.107 267.66H258.894C258.716 267.66 258.545 267.732 258.42 267.859L253.941 272.426C253.82 272.549 253.752 272.714 253.751 272.886L253.66 285.106C253.658 285.289 253.732 285.464 253.864 285.59L258.422 289.973C258.546 290.093 258.711 290.159 258.883 290.159H271.206C271.385 290.159 271.556 290.087 271.681 289.96L276.06 285.493C276.183 285.367 276.251 285.198 276.25 285.023L276.158 272.882C276.157 272.712 276.091 272.549 275.973 272.427L271.586 267.864C271.461 267.734 271.288 267.66 271.107 267.66Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M280.526 251.711H250.525C250.347 251.711 250.176 251.783 250.051 251.91L238.802 263.379C238.682 263.502 238.614 263.667 238.612 263.84L238.388 293.848C238.387 294.031 238.461 294.206 238.592 294.332L250.053 305.352C250.177 305.471 250.342 305.538 250.514 305.538H280.758C280.937 305.538 281.108 305.466 281.233 305.339L292.249 294.102C292.372 293.976 292.441 293.807 292.439 293.632L292.215 263.836C292.214 263.666 292.148 263.503 292.03 263.38L281.005 251.915C280.88 251.785 280.707 251.711 280.526 251.711Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M337.346 261.015H317.533C317.355 261.015 317.184 261.087 317.059 261.214L309.687 268.73C309.567 268.853 309.499 269.018 309.497 269.19L309.349 289.011C309.348 289.194 309.422 289.369 309.553 289.495L317.061 296.714C317.185 296.833 317.35 296.9 317.522 296.9H337.502C337.681 296.9 337.852 296.828 337.977 296.7L345.192 289.341C345.315 289.215 345.383 289.046 345.382 288.871L345.234 269.186C345.233 269.016 345.166 268.853 345.048 268.731L337.825 261.219C337.7 261.089 337.527 261.015 337.346 261.015Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M333.431 267.66H321.218C321.04 267.66 320.869 267.732 320.744 267.859L316.265 272.426C316.145 272.549 316.077 272.714 316.075 272.886L315.984 285.106C315.983 285.289 316.056 285.464 316.188 285.59L320.746 289.973C320.87 290.093 321.035 290.159 321.207 290.159H333.53C333.709 290.159 333.88 290.087 334.005 289.96L338.384 285.493C338.507 285.367 338.575 285.198 338.574 285.023L338.483 272.882C338.481 272.712 338.415 272.549 338.297 272.427L333.91 267.864C333.785 267.734 333.612 267.66 333.431 267.66Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M342.851 251.711H312.85C312.671 251.711 312.5 251.783 312.375 251.91L301.127 263.379C301.006 263.502 300.938 263.667 300.937 263.84L300.713 293.848C300.711 294.031 300.785 294.206 300.917 294.332L312.378 305.352C312.502 305.471 312.667 305.538 312.838 305.538H343.082C343.261 305.538 343.432 305.466 343.557 305.339L354.574 294.102C354.697 293.976 354.765 293.807 354.764 293.632L354.539 263.836C354.538 263.666 354.472 263.503 354.354 263.38L343.33 251.915C343.204 251.785 343.031 251.711 342.851 251.711Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M399.671 261.015H379.858C379.679 261.015 379.508 261.087 379.383 261.214L372.012 268.73C371.891 268.853 371.823 269.018 371.822 269.19L371.674 289.011C371.673 289.194 371.746 289.369 371.878 289.495L379.386 296.714C379.51 296.833 379.675 296.9 379.846 296.9H399.827C400.005 296.9 400.176 296.828 400.301 296.7L407.517 289.341C407.64 289.215 407.708 289.046 407.707 288.871L407.559 269.186C407.557 269.016 407.491 268.853 407.373 268.731L400.15 261.219C400.025 261.089 399.852 261.015 399.671 261.015Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M395.756 267.66H383.543C383.365 267.66 383.194 267.732 383.069 267.859L378.59 272.426C378.469 272.549 378.401 272.714 378.4 272.886L378.309 285.106C378.307 285.289 378.381 285.464 378.513 285.59L383.071 289.973C383.195 290.093 383.36 290.159 383.532 290.159H395.855C396.033 290.159 396.204 290.087 396.329 289.96L400.709 285.493C400.832 285.367 400.9 285.198 400.899 285.023L400.807 272.882C400.806 272.712 400.74 272.549 400.622 272.427L396.235 267.864C396.109 267.734 395.937 267.66 395.756 267.66Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M405.175 251.711H375.174C374.996 251.711 374.825 251.783 374.7 251.91L363.451 263.379C363.331 263.502 363.263 263.667 363.261 263.84L363.037 293.848C363.036 294.031 363.11 294.206 363.241 294.332L374.702 305.352C374.826 305.471 374.991 305.538 375.163 305.538H405.407C405.586 305.538 405.757 305.466 405.882 305.339L416.898 294.102C417.021 293.976 417.09 293.807 417.088 293.632L416.864 263.836C416.863 263.666 416.797 263.503 416.679 263.38L405.654 251.915C405.529 251.785 405.356 251.711 405.175 251.711Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M461.995 261.015H442.182C442.003 261.015 441.832 261.087 441.708 261.214L434.336 268.73C434.216 268.853 434.147 269.018 434.146 269.19L433.998 289.011C433.997 289.194 434.071 289.369 434.202 289.495L441.71 296.714C441.834 296.833 441.999 296.9 442.171 296.9H462.151C462.33 296.9 462.501 296.828 462.626 296.7L469.841 289.341C469.964 289.215 470.032 289.046 470.031 288.871L469.883 269.186C469.882 269.016 469.815 268.853 469.697 268.731L462.474 261.219C462.349 261.089 462.176 261.015 461.995 261.015Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M458.08 267.66H445.867C445.689 267.66 445.518 267.732 445.393 267.859L440.914 272.426C440.794 272.549 440.725 272.714 440.724 272.886L440.633 285.106C440.632 285.289 440.705 285.464 440.837 285.59L445.395 289.973C445.519 290.093 445.684 290.159 445.856 290.159H458.179C458.358 290.159 458.529 290.087 458.654 289.96L463.033 285.493C463.156 285.367 463.224 285.198 463.223 285.023L463.132 272.882C463.13 272.712 463.064 272.549 462.946 272.427L458.559 267.864C458.434 267.734 458.261 267.66 458.08 267.66Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M467.499 251.711H437.499C437.32 251.711 437.149 251.783 437.024 251.91L425.776 263.379C425.655 263.502 425.587 263.667 425.585 263.84L425.362 293.848C425.36 294.031 425.434 294.206 425.565 294.332L437.027 305.352C437.15 305.471 437.315 305.538 437.487 305.538H467.731C467.91 305.538 468.081 305.466 468.206 305.339L479.222 294.102C479.345 293.976 479.414 293.807 479.412 293.632L479.188 263.836C479.187 263.666 479.121 263.503 479.003 263.38L467.978 251.915C467.853 251.785 467.68 251.711 467.499 251.711Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M524.319 261.015H504.506C504.327 261.015 504.156 261.087 504.031 261.214L496.66 268.73C496.539 268.853 496.471 269.018 496.47 269.19L496.322 289.011C496.321 289.194 496.394 289.369 496.526 289.495L504.034 296.714C504.157 296.833 504.323 296.9 504.494 296.9H524.475C524.653 296.9 524.824 296.828 524.949 296.7L532.165 289.341C532.288 289.215 532.356 289.046 532.355 288.871L532.207 269.186C532.205 269.016 532.139 268.853 532.021 268.731L524.798 261.219C524.673 261.089 524.5 261.015 524.319 261.015Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M520.404 267.66H508.191C508.013 267.66 507.842 267.732 507.717 267.859L503.238 272.426C503.117 272.549 503.049 272.714 503.048 272.886L502.957 285.106C502.955 285.289 503.029 285.464 503.161 285.59L507.719 289.973C507.843 290.093 508.008 290.159 508.18 290.159H520.503C520.681 290.159 520.852 290.087 520.977 289.96L525.357 285.493C525.48 285.367 525.548 285.198 525.547 285.023L525.455 272.882C525.454 272.712 525.388 272.549 525.27 272.427L520.883 267.864C520.757 267.734 520.584 267.66 520.404 267.66Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M529.824 251.711H499.823C499.644 251.711 499.473 251.783 499.348 251.91L488.1 263.379C487.979 263.502 487.911 263.667 487.91 263.84L487.686 293.848C487.684 294.031 487.758 294.206 487.89 294.332L499.351 305.352C499.475 305.471 499.64 305.538 499.811 305.538H530.056C530.234 305.538 530.405 305.466 530.53 305.339L541.547 294.102C541.67 293.976 541.738 293.807 541.737 293.632L541.513 263.836C541.511 263.666 541.445 263.503 541.327 263.38L530.303 251.915C530.177 251.785 530.004 251.711 529.824 251.711Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M586.643 261.015H566.83C566.651 261.015 566.48 261.087 566.355 261.214L558.984 268.73C558.864 268.853 558.795 269.018 558.794 269.19L558.646 289.011C558.645 289.194 558.719 289.369 558.85 289.495L566.358 296.714C566.482 296.833 566.647 296.9 566.818 296.9H586.799C586.978 296.9 587.149 296.828 587.274 296.7L594.489 289.341C594.612 289.215 594.68 289.046 594.679 288.871L594.531 269.186C594.529 269.016 594.463 268.853 594.345 268.731L587.122 261.219C586.997 261.089 586.824 261.015 586.643 261.015Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M582.728 267.66H570.515C570.337 267.66 570.166 267.732 570.041 267.859L565.562 272.426C565.442 272.549 565.373 272.714 565.372 272.886L565.281 285.106C565.28 285.289 565.353 285.464 565.485 285.59L570.043 289.973C570.167 290.093 570.332 290.159 570.504 290.159H582.827C583.006 290.159 583.177 290.087 583.302 289.96L587.681 285.493C587.804 285.367 587.872 285.198 587.871 285.023L587.78 272.882C587.778 272.712 587.712 272.549 587.594 272.427L583.207 267.864C583.082 267.734 582.909 267.66 582.728 267.66Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M592.148 251.711H562.147C561.969 251.711 561.798 251.783 561.673 251.91L550.424 263.379C550.303 263.502 550.235 263.667 550.234 263.84L550.01 293.848C550.009 294.031 550.082 294.206 550.214 294.332L561.675 305.352C561.799 305.471 561.964 305.538 562.136 305.538H592.38C592.558 305.538 592.729 305.466 592.854 305.339L603.871 294.102C603.994 293.976 604.062 293.807 604.061 293.632L603.837 263.836C603.836 263.666 603.769 263.503 603.651 263.38L592.627 251.915C592.502 251.785 592.329 251.711 592.148 251.711Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M648.968 261.015H629.155C628.976 261.015 628.805 261.087 628.68 261.214L621.309 268.73C621.188 268.853 621.12 269.018 621.119 269.19L620.971 289.011C620.97 289.194 621.043 289.369 621.175 289.495L628.683 296.714C628.806 296.833 628.971 296.9 629.143 296.9H649.124C649.302 296.9 649.473 296.828 649.598 296.7L656.813 289.341C656.936 289.215 657.005 289.046 657.003 288.871L656.855 269.186C656.854 269.016 656.788 268.853 656.67 268.731L649.447 261.219C649.322 261.089 649.149 261.015 648.968 261.015Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M645.053 267.66H632.84C632.661 267.66 632.49 267.732 632.365 267.859L627.887 272.426C627.766 272.549 627.698 272.714 627.697 272.886L627.606 285.106C627.604 285.289 627.678 285.464 627.81 285.59L632.368 289.973C632.492 290.093 632.657 290.159 632.829 290.159H645.152C645.33 290.159 645.501 290.087 645.626 289.96L650.006 285.493C650.129 285.367 650.197 285.198 650.196 285.023L650.104 272.882C650.103 272.712 650.037 272.549 649.919 272.427L645.532 267.864C645.406 267.734 645.233 267.66 645.053 267.66Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M654.473 251.711H624.472C624.293 251.711 624.122 251.783 623.997 251.91L612.749 263.379C612.628 263.502 612.56 263.667 612.559 263.84L612.335 293.848C612.333 294.031 612.407 294.206 612.539 294.332L624 305.352C624.124 305.471 624.289 305.538 624.46 305.538H654.704C654.883 305.538 655.054 305.466 655.179 305.339L666.196 294.102C666.319 293.976 666.387 293.807 666.386 293.632L666.162 263.836C666.16 263.666 666.094 263.503 665.976 263.38L654.952 251.915C654.826 251.785 654.653 251.711 654.473 251.711Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M711.292 261.015H691.479C691.3 261.015 691.129 261.087 691.004 261.214L683.633 268.73C683.512 268.853 683.444 269.018 683.443 269.19L683.295 289.011C683.294 289.194 683.367 289.369 683.499 289.495L691.007 296.714C691.131 296.833 691.296 296.9 691.467 296.9H711.448C711.626 296.9 711.797 296.828 711.922 296.7L719.138 289.341C719.261 289.215 719.329 289.046 719.328 288.871L719.18 269.186C719.178 269.016 719.112 268.853 718.994 268.731L711.771 261.219C711.646 261.089 711.473 261.015 711.292 261.015Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M707.377 267.66H695.164C694.986 267.66 694.815 267.732 694.69 267.859L690.211 272.426C690.091 272.549 690.022 272.714 690.021 272.886L689.93 285.106C689.929 285.289 690.002 285.464 690.134 285.59L694.692 289.973C694.816 290.093 694.981 290.159 695.153 290.159H707.476C707.655 290.159 707.826 290.087 707.951 289.96L712.33 285.493C712.453 285.367 712.521 285.198 712.52 285.023L712.428 272.882C712.427 272.712 712.361 272.549 712.243 272.427L707.856 267.864C707.731 267.734 707.558 267.66 707.377 267.66Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M716.797 251.711H686.796C686.617 251.711 686.447 251.783 686.322 251.91L675.073 263.379C674.952 263.502 674.884 263.667 674.883 263.84L674.659 293.848C674.658 294.031 674.731 294.206 674.863 294.332L686.324 305.352C686.448 305.471 686.613 305.538 686.785 305.538H717.029C717.207 305.538 717.378 305.466 717.503 305.339L728.52 294.102C728.643 293.976 728.711 293.807 728.71 293.632L728.486 263.836C728.484 263.666 728.418 263.503 728.3 263.38L717.276 251.915C717.151 251.785 716.978 251.711 716.797 251.711Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M773.616 261.015H753.803C753.625 261.015 753.454 261.087 753.329 261.214L745.957 268.73C745.837 268.853 745.769 269.018 745.767 269.19L745.619 289.011C745.618 289.194 745.692 289.369 745.823 289.495L753.331 296.714C753.455 296.833 753.62 296.9 753.792 296.9H773.772C773.951 296.9 774.122 296.828 774.247 296.7L781.462 289.341C781.585 289.215 781.653 289.046 781.652 288.871L781.504 269.186C781.503 269.016 781.436 268.853 781.318 268.731L774.095 261.219C773.97 261.089 773.797 261.015 773.616 261.015Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M769.701 267.66H757.488C757.31 267.66 757.139 267.732 757.014 267.859L752.535 272.426C752.415 272.549 752.347 272.714 752.345 272.886L752.254 285.106C752.253 285.289 752.326 285.464 752.458 285.59L757.016 289.973C757.14 290.093 757.305 290.159 757.477 290.159H769.8C769.979 290.159 770.15 290.087 770.275 289.96L774.654 285.493C774.777 285.367 774.845 285.198 774.844 285.023L774.753 272.882C774.751 272.712 774.685 272.549 774.567 272.427L770.18 267.864C770.055 267.734 769.882 267.66 769.701 267.66Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M779.121 251.711H749.12C748.942 251.711 748.771 251.783 748.646 251.91L737.397 263.379C737.276 263.502 737.208 263.667 737.207 263.84L736.983 293.848C736.982 294.031 737.055 294.206 737.187 294.332L748.648 305.352C748.772 305.471 748.937 305.538 749.109 305.538H779.353C779.531 305.538 779.702 305.466 779.827 305.339L790.844 294.102C790.967 293.976 791.035 293.807 791.034 293.632L790.81 263.836C790.809 263.666 790.742 263.503 790.624 263.38L779.6 251.915C779.475 251.785 779.302 251.711 779.121 251.711Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M835.94 261.015H816.127C815.949 261.015 815.778 261.087 815.653 261.214L808.282 268.73C808.161 268.853 808.093 269.018 808.091 269.19L807.944 289.011C807.942 289.194 808.016 289.369 808.147 289.495L815.655 296.714C815.779 296.833 815.944 296.9 816.116 296.9H836.096C836.275 296.9 836.446 296.828 836.571 296.7L843.786 289.341C843.909 289.215 843.977 289.046 843.976 288.871L843.828 269.186C843.827 269.016 843.76 268.853 843.643 268.731L836.42 261.219C836.294 261.089 836.121 261.015 835.94 261.015Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M832.025 267.66H819.813C819.634 267.66 819.463 267.732 819.338 267.859L814.86 272.426C814.739 272.549 814.671 272.714 814.67 272.886L814.578 285.106C814.577 285.289 814.651 285.464 814.782 285.59L819.341 289.973C819.464 290.093 819.629 290.159 819.801 290.159H832.124C832.303 290.159 832.474 290.087 832.599 289.96L836.978 285.493C837.101 285.367 837.169 285.198 837.168 285.023L837.077 272.882C837.076 272.712 837.009 272.549 836.891 272.427L832.504 267.864C832.379 267.734 832.206 267.66 832.025 267.66Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M841.445 251.711H811.444C811.266 251.711 811.095 251.783 810.97 251.91L799.721 263.379C799.601 263.502 799.533 263.667 799.531 263.84L799.307 293.848C799.306 294.031 799.38 294.206 799.511 294.332L810.972 305.352C811.096 305.471 811.261 305.538 811.433 305.538H841.677C841.856 305.538 842.027 305.466 842.152 305.339L853.168 294.102C853.291 293.976 853.36 293.807 853.358 293.632L853.134 263.836C853.133 263.666 853.067 263.503 852.949 263.38L841.924 251.915C841.799 251.785 841.626 251.711 841.445 251.711Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M25.7247 326.017H5.91143C5.73295 326.017 5.56197 326.088 5.43699 326.216L-1.93429 333.732C-2.05493 333.855 -2.12308 334.02 -2.12436 334.192L-2.27228 354.013C-2.27364 354.195 -2.1999 354.37 -2.06836 354.497L5.43945 361.716C5.56324 361.835 5.72831 361.901 5.90004 361.901H25.8805C26.0591 361.901 26.2301 361.829 26.3551 361.702L33.5703 354.342C33.6933 354.217 33.7616 354.048 33.7603 353.872L33.6123 334.188C33.611 334.018 33.5446 333.855 33.4268 333.733L26.2037 326.221C26.0784 326.09 25.9054 326.017 25.7247 326.017Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M21.8095 332.662H9.59674C9.41826 332.662 9.24728 332.734 9.12231 332.861L4.64376 337.428C4.52312 337.551 4.45497 337.716 4.45368 337.888L4.36248 350.108C4.36112 350.291 4.43486 350.466 4.56641 350.592L9.12476 354.975C9.24856 355.094 9.41362 355.161 9.58536 355.161H21.9086C22.0871 355.161 22.2582 355.089 22.3831 354.961L26.7624 350.495C26.8854 350.369 26.9537 350.2 26.9524 350.024L26.8611 337.884C26.8598 337.714 26.7934 337.551 26.6756 337.428L22.2885 332.866C22.1632 332.736 21.9902 332.662 21.8095 332.662Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M31.229 316.713H1.22811C1.04963 316.713 0.878648 316.784 0.753674 316.912L-10.495 328.381C-10.6156 328.504 -10.6838 328.669 -10.6851 328.841L-10.909 358.85C-10.9104 359.032 -10.8366 359.207 -10.7051 359.334L0.756127 370.354C0.879919 370.473 1.04498 370.54 1.21672 370.54H31.4608C31.6394 370.54 31.8104 370.468 31.9354 370.34L42.9519 359.103C43.0749 358.978 43.1432 358.809 43.1419 358.633L42.9179 328.838C42.9166 328.668 42.8502 328.505 42.7324 328.382L31.708 316.917C31.5827 316.786 31.4097 316.713 31.229 316.713Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M88.0489 326.017H68.2356C68.0572 326.017 67.8862 326.088 67.7612 326.216L60.3899 333.732C60.2693 333.855 60.2011 334.02 60.1999 334.192L60.0519 354.013C60.0506 354.195 60.1243 354.37 60.2559 354.497L67.7637 361.716C67.8875 361.835 68.0525 361.901 68.2243 361.901H88.2047C88.3833 361.901 88.5543 361.829 88.6793 361.702L95.8945 354.342C96.0175 354.217 96.0858 354.048 96.0845 353.872L95.9365 334.188C95.9352 334.018 95.8688 333.855 95.751 333.733L88.5279 326.221C88.4026 326.09 88.2297 326.017 88.0489 326.017Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M84.1337 332.662H71.921C71.7425 332.662 71.5715 332.734 71.4465 332.861L66.968 337.428C66.8473 337.551 66.7792 337.716 66.7779 337.888L66.6867 350.108C66.6853 350.291 66.7591 350.466 66.8906 350.592L71.449 354.975C71.5728 355.094 71.7378 355.161 71.9096 355.161H84.2328C84.4114 355.161 84.5824 355.089 84.7074 354.961L89.0866 350.495C89.2096 350.369 89.2779 350.2 89.2766 350.024L89.1853 337.884C89.184 337.714 89.1176 337.551 88.9998 337.428L84.6127 332.866C84.4874 332.736 84.3145 332.662 84.1337 332.662Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M93.5532 316.713H63.5523C63.3738 316.713 63.2029 316.784 63.0779 316.912L51.8292 328.381C51.7086 328.504 51.6404 328.669 51.6392 328.841L51.4152 358.85C51.4139 359.032 51.4876 359.207 51.6191 359.334L63.0803 370.354C63.2041 370.473 63.3692 370.54 63.5409 370.54H93.7851C93.9636 370.54 94.1346 370.468 94.2596 370.34L105.276 359.103C105.399 358.978 105.467 358.809 105.466 358.633L105.242 328.838C105.241 328.668 105.174 328.505 105.057 328.382L94.0322 316.917C93.9069 316.786 93.7339 316.713 93.5532 316.713Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M150.373 326.017H130.56C130.381 326.017 130.21 326.088 130.085 326.216L122.714 333.732C122.594 333.855 122.525 334.02 122.524 334.192L122.376 354.013C122.375 354.195 122.449 354.37 122.58 354.497L130.088 361.716C130.212 361.835 130.377 361.901 130.548 361.901H150.529C150.707 361.901 150.879 361.829 151.003 361.702L158.219 354.342C158.342 354.217 158.41 354.048 158.409 353.872L158.261 334.188C158.259 334.018 158.193 333.855 158.075 333.733L150.852 326.221C150.727 326.09 150.554 326.017 150.373 326.017Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M146.458 332.662H134.245C134.067 332.662 133.896 332.734 133.771 332.861L129.292 337.428C129.172 337.551 129.103 337.716 129.102 337.888L129.011 350.108C129.01 350.291 129.083 350.466 129.215 350.592L133.773 354.975C133.897 355.094 134.062 355.161 134.234 355.161H146.557C146.736 355.161 146.907 355.089 147.032 354.961L151.411 350.495C151.534 350.369 151.602 350.2 151.601 350.024L151.51 337.884C151.508 337.714 151.442 337.551 151.324 337.428L146.937 332.866C146.812 332.736 146.639 332.662 146.458 332.662Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M155.877 316.713H125.877C125.698 316.713 125.527 316.784 125.402 316.912L114.153 328.381C114.033 328.504 113.965 328.669 113.963 328.841L113.739 358.85C113.738 359.032 113.812 359.207 113.943 359.334L125.405 370.354C125.528 370.473 125.693 370.54 125.865 370.54H156.109C156.288 370.54 156.459 370.468 156.584 370.34L167.6 359.103C167.723 358.978 167.792 358.809 167.79 358.633L167.566 328.838C167.565 328.668 167.499 328.505 167.381 328.382L156.356 316.917C156.231 316.786 156.058 316.713 155.877 316.713Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M212.697 326.017H192.884C192.706 326.017 192.535 326.088 192.41 326.216L185.038 333.732C184.918 333.855 184.85 334.02 184.848 334.192L184.7 354.013C184.699 354.195 184.773 354.37 184.904 354.497L192.412 361.716C192.536 361.835 192.701 361.901 192.873 361.901H212.853C213.032 361.901 213.203 361.829 213.328 361.702L220.543 354.342C220.666 354.217 220.734 354.048 220.733 353.872L220.585 334.188C220.584 334.018 220.517 333.855 220.399 333.733L213.176 326.221C213.051 326.09 212.878 326.017 212.697 326.017Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M208.783 332.662H196.57C196.391 332.662 196.22 332.734 196.095 332.861L191.617 337.428C191.496 337.551 191.428 337.716 191.427 337.888L191.336 350.108C191.334 350.291 191.408 350.466 191.54 350.592L196.098 354.975C196.222 355.094 196.387 355.161 196.559 355.161H208.882C209.06 355.161 209.231 355.089 209.356 354.961L213.736 350.495C213.859 350.369 213.927 350.2 213.925 350.024L213.834 337.884C213.833 337.714 213.767 337.551 213.649 337.428L209.262 332.866C209.136 332.736 208.963 332.662 208.783 332.662Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M218.202 316.713H188.201C188.023 316.713 187.852 316.784 187.727 316.912L176.478 328.381C176.358 328.504 176.289 328.669 176.288 328.841L176.064 358.85C176.063 359.032 176.137 359.207 176.268 359.334L187.729 370.354C187.853 370.473 188.018 370.54 188.19 370.54H218.434C218.613 370.54 218.784 370.468 218.909 370.34L229.925 359.103C230.048 358.978 230.116 358.809 230.115 358.633L229.891 328.838C229.89 328.668 229.823 328.505 229.706 328.382L218.681 316.917C218.556 316.786 218.383 316.713 218.202 316.713Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M275.022 326.017H255.208C255.03 326.017 254.859 326.088 254.734 326.216L247.363 333.732C247.242 333.855 247.174 334.02 247.173 334.192L247.025 354.013C247.023 354.195 247.097 354.37 247.229 354.497L254.736 361.716C254.86 361.835 255.025 361.901 255.197 361.901H275.177C275.356 361.901 275.527 361.829 275.652 361.702L282.867 354.342C282.99 354.217 283.058 354.048 283.057 353.872L282.909 334.188C282.908 334.018 282.841 333.855 282.724 333.733L275.501 326.221C275.375 326.09 275.202 326.017 275.022 326.017Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M271.107 332.662H258.894C258.716 332.662 258.545 332.734 258.42 332.861L253.941 337.428C253.82 337.551 253.752 337.716 253.751 337.888L253.66 350.108C253.658 350.291 253.732 350.466 253.864 350.592L258.422 354.975C258.546 355.094 258.711 355.161 258.883 355.161H271.206C271.385 355.161 271.556 355.089 271.681 354.961L276.06 350.495C276.183 350.369 276.251 350.2 276.25 350.024L276.158 337.884C276.157 337.714 276.091 337.551 275.973 337.428L271.586 332.866C271.461 332.736 271.288 332.662 271.107 332.662Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M280.526 316.713H250.525C250.347 316.713 250.176 316.784 250.051 316.912L238.802 328.381C238.682 328.504 238.614 328.669 238.612 328.841L238.388 358.85C238.387 359.032 238.461 359.207 238.592 359.334L250.053 370.354C250.177 370.473 250.342 370.54 250.514 370.54H280.758C280.937 370.54 281.108 370.468 281.233 370.34L292.249 359.103C292.372 358.978 292.441 358.809 292.439 358.633L292.215 328.838C292.214 328.668 292.148 328.505 292.03 328.382L281.005 316.917C280.88 316.786 280.707 316.713 280.526 316.713Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M337.346 326.017H317.533C317.355 326.017 317.184 326.088 317.059 326.216L309.687 333.732C309.567 333.855 309.499 334.02 309.497 334.192L309.349 354.013C309.348 354.195 309.422 354.37 309.553 354.497L317.061 361.716C317.185 361.835 317.35 361.901 317.522 361.901H337.502C337.681 361.901 337.852 361.829 337.977 361.702L345.192 354.342C345.315 354.217 345.383 354.048 345.382 353.872L345.234 334.188C345.233 334.018 345.166 333.855 345.048 333.733L337.825 326.221C337.7 326.09 337.527 326.017 337.346 326.017Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M333.431 332.662H321.218C321.04 332.662 320.869 332.734 320.744 332.861L316.265 337.428C316.145 337.551 316.077 337.716 316.075 337.888L315.984 350.108C315.983 350.291 316.056 350.466 316.188 350.592L320.746 354.975C320.87 355.094 321.035 355.161 321.207 355.161H333.53C333.709 355.161 333.88 355.089 334.005 354.961L338.384 350.495C338.507 350.369 338.575 350.2 338.574 350.024L338.483 337.884C338.481 337.714 338.415 337.551 338.297 337.428L333.91 332.866C333.785 332.736 333.612 332.662 333.431 332.662Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M342.851 316.713H312.85C312.671 316.713 312.5 316.784 312.375 316.912L301.127 328.381C301.006 328.504 300.938 328.669 300.937 328.841L300.713 358.85C300.711 359.032 300.785 359.207 300.917 359.334L312.378 370.354C312.502 370.473 312.667 370.54 312.838 370.54H343.082C343.261 370.54 343.432 370.468 343.557 370.34L354.574 359.103C354.697 358.978 354.765 358.809 354.764 358.633L354.539 328.838C354.538 328.668 354.472 328.505 354.354 328.382L343.33 316.917C343.204 316.786 343.031 316.713 342.851 316.713Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M399.671 326.017H379.858C379.679 326.017 379.508 326.088 379.383 326.216L372.012 333.732C371.891 333.855 371.823 334.02 371.822 334.192L371.674 354.013C371.673 354.195 371.746 354.37 371.878 354.497L379.386 361.716C379.51 361.835 379.675 361.901 379.846 361.901H399.827C400.005 361.901 400.176 361.829 400.301 361.702L407.517 354.342C407.64 354.217 407.708 354.048 407.707 353.872L407.559 334.188C407.557 334.018 407.491 333.855 407.373 333.733L400.15 326.221C400.025 326.09 399.852 326.017 399.671 326.017Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M395.756 332.662H383.543C383.365 332.662 383.194 332.734 383.069 332.861L378.59 337.428C378.469 337.551 378.401 337.716 378.4 337.888L378.309 350.108C378.307 350.291 378.381 350.466 378.513 350.592L383.071 354.975C383.195 355.094 383.36 355.161 383.532 355.161H395.855C396.033 355.161 396.204 355.089 396.329 354.961L400.709 350.495C400.832 350.369 400.9 350.2 400.899 350.024L400.807 337.884C400.806 337.714 400.74 337.551 400.622 337.428L396.235 332.866C396.109 332.736 395.937 332.662 395.756 332.662Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M405.175 316.713H375.174C374.996 316.713 374.825 316.784 374.7 316.912L363.451 328.381C363.331 328.504 363.263 328.669 363.261 328.841L363.037 358.85C363.036 359.032 363.11 359.207 363.241 359.334L374.702 370.354C374.826 370.473 374.991 370.54 375.163 370.54H405.407C405.586 370.54 405.757 370.468 405.882 370.34L416.898 359.103C417.021 358.978 417.09 358.809 417.088 358.633L416.864 328.838C416.863 328.668 416.797 328.505 416.679 328.382L405.654 316.917C405.529 316.786 405.356 316.713 405.175 316.713Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M461.995 326.017H442.182C442.003 326.017 441.832 326.088 441.708 326.216L434.336 333.732C434.216 333.855 434.147 334.02 434.146 334.192L433.998 354.013C433.997 354.195 434.071 354.37 434.202 354.497L441.71 361.716C441.834 361.835 441.999 361.901 442.171 361.901H462.151C462.33 361.901 462.501 361.829 462.626 361.702L469.841 354.342C469.964 354.217 470.032 354.048 470.031 353.872L469.883 334.188C469.882 334.018 469.815 333.855 469.697 333.733L462.474 326.221C462.349 326.09 462.176 326.017 461.995 326.017Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M458.08 332.662H445.867C445.689 332.662 445.518 332.734 445.393 332.861L440.914 337.428C440.794 337.551 440.725 337.716 440.724 337.888L440.633 350.108C440.632 350.291 440.705 350.466 440.837 350.592L445.395 354.975C445.519 355.094 445.684 355.161 445.856 355.161H458.179C458.358 355.161 458.529 355.089 458.654 354.961L463.033 350.495C463.156 350.369 463.224 350.2 463.223 350.024L463.132 337.884C463.13 337.714 463.064 337.551 462.946 337.428L458.559 332.866C458.434 332.736 458.261 332.662 458.08 332.662Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M467.499 316.713H437.499C437.32 316.713 437.149 316.784 437.024 316.912L425.776 328.381C425.655 328.504 425.587 328.669 425.585 328.841L425.362 358.85C425.36 359.032 425.434 359.207 425.565 359.334L437.027 370.354C437.15 370.473 437.315 370.54 437.487 370.54H467.731C467.91 370.54 468.081 370.468 468.206 370.34L479.222 359.103C479.345 358.978 479.414 358.809 479.412 358.633L479.188 328.838C479.187 328.668 479.121 328.505 479.003 328.382L467.978 316.917C467.853 316.786 467.68 316.713 467.499 316.713Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M524.319 326.017H504.506C504.327 326.017 504.156 326.088 504.031 326.216L496.66 333.732C496.539 333.855 496.471 334.02 496.47 334.192L496.322 354.013C496.321 354.195 496.394 354.37 496.526 354.497L504.034 361.716C504.157 361.835 504.323 361.901 504.494 361.901H524.475C524.653 361.901 524.824 361.829 524.949 361.702L532.165 354.342C532.288 354.217 532.356 354.048 532.355 353.872L532.207 334.188C532.205 334.018 532.139 333.855 532.021 333.733L524.798 326.221C524.673 326.09 524.5 326.017 524.319 326.017Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M520.404 332.662H508.191C508.013 332.662 507.842 332.734 507.717 332.861L503.238 337.428C503.117 337.551 503.049 337.716 503.048 337.888L502.957 350.108C502.955 350.291 503.029 350.466 503.161 350.592L507.719 354.975C507.843 355.094 508.008 355.161 508.18 355.161H520.503C520.681 355.161 520.852 355.089 520.977 354.961L525.357 350.495C525.48 350.369 525.548 350.2 525.547 350.024L525.455 337.884C525.454 337.714 525.388 337.551 525.27 337.428L520.883 332.866C520.757 332.736 520.584 332.662 520.404 332.662Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M529.824 316.713H499.823C499.644 316.713 499.473 316.784 499.348 316.912L488.1 328.381C487.979 328.504 487.911 328.669 487.91 328.841L487.686 358.85C487.684 359.032 487.758 359.207 487.89 359.334L499.351 370.354C499.475 370.473 499.64 370.54 499.811 370.54H530.056C530.234 370.54 530.405 370.468 530.53 370.34L541.547 359.103C541.67 358.978 541.738 358.809 541.737 358.633L541.513 328.838C541.511 328.668 541.445 328.505 541.327 328.382L530.303 316.917C530.177 316.786 530.004 316.713 529.824 316.713Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M586.643 326.017H566.83C566.651 326.017 566.48 326.088 566.355 326.216L558.984 333.732C558.864 333.855 558.795 334.02 558.794 334.192L558.646 354.013C558.645 354.195 558.719 354.37 558.85 354.497L566.358 361.716C566.482 361.835 566.647 361.901 566.818 361.901H586.799C586.978 361.901 587.149 361.829 587.274 361.702L594.489 354.342C594.612 354.217 594.68 354.048 594.679 353.872L594.531 334.188C594.529 334.018 594.463 333.855 594.345 333.733L587.122 326.221C586.997 326.09 586.824 326.017 586.643 326.017Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M582.728 332.662H570.515C570.337 332.662 570.166 332.734 570.041 332.861L565.562 337.428C565.442 337.551 565.373 337.716 565.372 337.888L565.281 350.108C565.28 350.291 565.353 350.466 565.485 350.592L570.043 354.975C570.167 355.094 570.332 355.161 570.504 355.161H582.827C583.006 355.161 583.177 355.089 583.302 354.961L587.681 350.495C587.804 350.369 587.872 350.2 587.871 350.024L587.78 337.884C587.778 337.714 587.712 337.551 587.594 337.428L583.207 332.866C583.082 332.736 582.909 332.662 582.728 332.662Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M592.148 316.713H562.147C561.969 316.713 561.798 316.784 561.673 316.912L550.424 328.381C550.303 328.504 550.235 328.669 550.234 328.841L550.01 358.85C550.009 359.032 550.082 359.207 550.214 359.334L561.675 370.354C561.799 370.473 561.964 370.54 562.136 370.54H592.38C592.558 370.54 592.729 370.468 592.854 370.34L603.871 359.103C603.994 358.978 604.062 358.809 604.061 358.633L603.837 328.838C603.836 328.668 603.769 328.505 603.651 328.382L592.627 316.917C592.502 316.786 592.329 316.713 592.148 316.713Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M648.968 326.017H629.155C628.976 326.017 628.805 326.088 628.68 326.216L621.309 333.732C621.188 333.855 621.12 334.02 621.119 334.192L620.971 354.013C620.97 354.195 621.043 354.37 621.175 354.497L628.683 361.716C628.806 361.835 628.971 361.901 629.143 361.901H649.124C649.302 361.901 649.473 361.829 649.598 361.702L656.813 354.342C656.936 354.217 657.005 354.048 657.003 353.872L656.855 334.188C656.854 334.018 656.788 333.855 656.67 333.733L649.447 326.221C649.322 326.09 649.149 326.017 648.968 326.017Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M645.053 332.662H632.84C632.661 332.662 632.49 332.734 632.365 332.861L627.887 337.428C627.766 337.551 627.698 337.716 627.697 337.888L627.606 350.108C627.604 350.291 627.678 350.466 627.81 350.592L632.368 354.975C632.492 355.094 632.657 355.161 632.829 355.161H645.152C645.33 355.161 645.501 355.089 645.626 354.961L650.006 350.495C650.129 350.369 650.197 350.2 650.196 350.024L650.104 337.884C650.103 337.714 650.037 337.551 649.919 337.428L645.532 332.866C645.406 332.736 645.233 332.662 645.053 332.662Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M654.473 316.713H624.472C624.293 316.713 624.122 316.784 623.997 316.912L612.749 328.381C612.628 328.504 612.56 328.669 612.559 328.841L612.335 358.85C612.333 359.032 612.407 359.207 612.539 359.334L624 370.354C624.124 370.473 624.289 370.54 624.46 370.54H654.704C654.883 370.54 655.054 370.468 655.179 370.34L666.196 359.103C666.319 358.978 666.387 358.809 666.386 358.633L666.162 328.838C666.16 328.668 666.094 328.505 665.976 328.382L654.952 316.917C654.826 316.786 654.653 316.713 654.473 316.713Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M711.292 326.017H691.479C691.3 326.017 691.129 326.088 691.004 326.216L683.633 333.732C683.512 333.855 683.444 334.02 683.443 334.192L683.295 354.013C683.294 354.195 683.367 354.37 683.499 354.497L691.007 361.716C691.131 361.835 691.296 361.901 691.467 361.901H711.448C711.626 361.901 711.797 361.829 711.922 361.702L719.138 354.342C719.261 354.217 719.329 354.048 719.328 353.872L719.18 334.188C719.178 334.018 719.112 333.855 718.994 333.733L711.771 326.221C711.646 326.09 711.473 326.017 711.292 326.017Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M707.377 332.662H695.164C694.986 332.662 694.815 332.734 694.69 332.861L690.211 337.428C690.091 337.551 690.022 337.716 690.021 337.888L689.93 350.108C689.929 350.291 690.002 350.466 690.134 350.592L694.692 354.975C694.816 355.094 694.981 355.161 695.153 355.161H707.476C707.655 355.161 707.826 355.089 707.951 354.961L712.33 350.495C712.453 350.369 712.521 350.2 712.52 350.024L712.428 337.884C712.427 337.714 712.361 337.551 712.243 337.428L707.856 332.866C707.731 332.736 707.558 332.662 707.377 332.662Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M716.797 316.713H686.796C686.617 316.713 686.447 316.784 686.322 316.912L675.073 328.381C674.952 328.504 674.884 328.669 674.883 328.841L674.659 358.85C674.658 359.032 674.731 359.207 674.863 359.334L686.324 370.354C686.448 370.473 686.613 370.54 686.785 370.54H717.029C717.207 370.54 717.378 370.468 717.503 370.34L728.52 359.103C728.643 358.978 728.711 358.809 728.71 358.633L728.486 328.838C728.484 328.668 728.418 328.505 728.3 328.382L717.276 316.917C717.151 316.786 716.978 316.713 716.797 316.713Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M773.616 326.017H753.803C753.625 326.017 753.454 326.088 753.329 326.216L745.957 333.732C745.837 333.855 745.769 334.02 745.767 334.192L745.619 354.013C745.618 354.195 745.692 354.37 745.823 354.497L753.331 361.716C753.455 361.835 753.62 361.901 753.792 361.901H773.772C773.951 361.901 774.122 361.829 774.247 361.702L781.462 354.342C781.585 354.217 781.653 354.048 781.652 353.872L781.504 334.188C781.503 334.018 781.436 333.855 781.318 333.733L774.095 326.221C773.97 326.09 773.797 326.017 773.616 326.017Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M769.701 332.662H757.488C757.31 332.662 757.139 332.734 757.014 332.861L752.535 337.428C752.415 337.551 752.347 337.716 752.345 337.888L752.254 350.108C752.253 350.291 752.326 350.466 752.458 350.592L757.016 354.975C757.14 355.094 757.305 355.161 757.477 355.161H769.8C769.979 355.161 770.15 355.089 770.275 354.961L774.654 350.495C774.777 350.369 774.845 350.2 774.844 350.024L774.753 337.884C774.751 337.714 774.685 337.551 774.567 337.428L770.18 332.866C770.055 332.736 769.882 332.662 769.701 332.662Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M779.121 316.713H749.12C748.942 316.713 748.771 316.784 748.646 316.912L737.397 328.381C737.276 328.504 737.208 328.669 737.207 328.841L736.983 358.85C736.982 359.032 737.055 359.207 737.187 359.334L748.648 370.354C748.772 370.473 748.937 370.54 749.109 370.54H779.353C779.531 370.54 779.702 370.468 779.827 370.34L790.844 359.103C790.967 358.978 791.035 358.809 791.034 358.633L790.81 328.838C790.809 328.668 790.742 328.505 790.624 328.382L779.6 316.917C779.475 316.786 779.302 316.713 779.121 316.713Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M835.94 326.017H816.127C815.949 326.017 815.778 326.088 815.653 326.216L808.282 333.732C808.161 333.855 808.093 334.02 808.091 334.192L807.944 354.013C807.942 354.195 808.016 354.37 808.147 354.497L815.655 361.716C815.779 361.835 815.944 361.901 816.116 361.901H836.096C836.275 361.901 836.446 361.829 836.571 361.702L843.786 354.342C843.909 354.217 843.977 354.048 843.976 353.872L843.828 334.188C843.827 334.018 843.76 333.855 843.643 333.733L836.42 326.221C836.294 326.09 836.121 326.017 835.94 326.017Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M832.025 332.662H819.813C819.634 332.662 819.463 332.734 819.338 332.861L814.86 337.428C814.739 337.551 814.671 337.716 814.67 337.888L814.578 350.108C814.577 350.291 814.651 350.466 814.782 350.592L819.341 354.975C819.464 355.094 819.629 355.161 819.801 355.161H832.124C832.303 355.161 832.474 355.089 832.599 354.961L836.978 350.495C837.101 350.369 837.169 350.2 837.168 350.024L837.077 337.884C837.076 337.714 837.009 337.551 836.891 337.428L832.504 332.866C832.379 332.736 832.206 332.662 832.025 332.662Z"
            stroke="white" stroke-width="4.46985"/>
          <path
            d="M841.445 316.713H811.444C811.266 316.713 811.095 316.784 810.97 316.912L799.721 328.381C799.601 328.504 799.533 328.669 799.531 328.841L799.307 358.85C799.306 359.032 799.38 359.207 799.511 359.334L810.972 370.354C811.096 370.473 811.261 370.54 811.433 370.54H841.677C841.856 370.54 842.027 370.468 842.152 370.34L853.168 359.103C853.291 358.978 853.36 358.809 853.358 358.633L853.134 328.838C853.133 328.668 853.067 328.505 852.949 328.382L841.924 316.917C841.799 316.786 841.626 316.713 841.445 316.713Z"
            stroke="white" stroke-width="4.46985"/>
        </g>
      </g>
    </svg>
    <div class="wrap large">
      <div class="flex space-between container-footer">
        <div class="flex left-block">
          <div>
            <p class="text-white title-s margin-bot-16">{{ $lang.footer.title1 }}<br>{{ $lang.footer.title2 }}</p>
            <primary-btn link="https://www.helloasso.com/associations/jiboiana/collectes/reforestation-en-amazonie-avec-le-peuple-autochtone-huni-kuin-par-jiboiana" :title="$lang.support"></primary-btn>
          </div>
          <div class="logo-pc">
            <svg fill="none" height="29" viewBox="0 0 120 29" width="120" xmlns="http://www.w3.org/2000/svg">
              <path d="M72.6128 12.038L77.4667 2.36986L82.3205 12.038H72.6128Z" stroke="white" stroke-width="2.12659"/>
              <path d="M107.552 12.038L112.406 2.36986L117.26 12.038H107.552Z" stroke="white" stroke-width="2.12659"/>
              <path
                d="M3.97114 13.2719C2.08053 13.2719 0.757099 12.51 0.000854492 10.986L2.04615 9.81727C2.42427 10.5735 3.06594 10.9516 3.97114 10.9516C4.60134 10.9516 5.07113 10.8027 5.3805 10.5048C5.68988 10.2068 5.84456 9.80008 5.84456 9.28446V1.00014H8.19923V9.28446C8.19923 10.5449 7.80392 11.5245 7.0133 12.2235C6.22268 12.9225 5.20863 13.2719 3.97114 13.2719Z"
                fill="white"/>
              <path d="M16.1799 1.00014H18.5518V13.0313H16.1799V1.00014Z" fill="white"/>
              <path
                d="M33.8929 6.79229C34.947 7.39958 35.4741 8.31624 35.4741 9.54227C35.4741 10.5621 35.1132 11.3985 34.3913 12.0516C33.6694 12.7047 32.7814 13.0313 31.7273 13.0313H26.6226V1.00014H31.3663C32.3976 1.00014 33.2627 1.32098 33.9616 1.96264C34.672 2.59284 35.0272 3.40065 35.0272 4.38606C35.0272 5.38292 34.6491 6.185 33.8929 6.79229ZM31.3663 3.21732H28.9945V5.86417H31.3663C31.733 5.86417 32.0366 5.73813 32.2773 5.48605C32.5293 5.23397 32.6554 4.91887 32.6554 4.54075C32.6554 4.16262 32.5351 3.84752 32.2944 3.59544C32.0538 3.34336 31.7445 3.21732 31.3663 3.21732ZM31.7273 10.8141C32.1283 10.8141 32.4606 10.6824 32.7241 10.4188C32.9877 10.1438 33.1194 9.80008 33.1194 9.38758C33.1194 8.98654 32.9877 8.65426 32.7241 8.39072C32.4606 8.11572 32.1283 7.97822 31.7273 7.97822H28.9945V10.8141H31.7273Z"
                fill="white"/>
              <path
                d="M52.9961 11.4673C51.7815 12.6704 50.3034 13.2719 48.5618 13.2719C46.8201 13.2719 45.342 12.6704 44.1274 11.4673C42.9243 10.2527 42.3228 8.76884 42.3228 7.01573C42.3228 5.26261 42.9243 3.7845 44.1274 2.58138C45.342 1.36681 46.8201 0.759521 48.5618 0.759521C50.3034 0.759521 51.7815 1.36681 52.9961 2.58138C54.2107 3.7845 54.818 5.26261 54.818 7.01573C54.818 8.76884 54.2107 10.2527 52.9961 11.4673ZM45.7946 9.85164C46.5394 10.585 47.4618 10.9516 48.5618 10.9516C49.6618 10.9516 50.5841 10.585 51.3289 9.85164C52.0737 9.10686 52.4461 8.16155 52.4461 7.01573C52.4461 5.8699 52.0737 4.9246 51.3289 4.17981C50.5841 3.43502 49.6618 3.06263 48.5618 3.06263C47.4618 3.06263 46.5394 3.43502 45.7946 4.17981C45.0498 4.9246 44.6774 5.8699 44.6774 7.01573C44.6774 8.16155 45.0498 9.10686 45.7946 9.85164Z"
                fill="white"/>
              <path d="M62.3287 1.00014H64.7006V13.0313H62.3287V1.00014Z" fill="white"/>
              <path
                d="M97.0566 1.00014H99.4285V13.0313H97.6238L92.4676 5.67511V13.0313H90.0957V1.00014H91.9004L97.0566 8.33916V1.00014Z"
                fill="white"/>
              <g class="bot-logo" opacity="0.2">
                <path d="M72.612 16.8451L77.4658 26.5132L82.3196 16.8451H72.612Z" stroke="white"
                      stroke-width="2.12659"/>
                <path d="M107.552 16.8451L112.405 26.5132L117.259 16.8451H107.552Z" stroke="white"
                      stroke-width="2.12659"/>
                <path
                  d="M3.97028 15.6109C2.07967 15.6109 0.756244 16.3729 0 17.8968L2.0453 19.0655C2.42342 18.3093 3.06508 17.9312 3.97028 17.9312C4.60049 17.9312 5.07028 18.0801 5.37965 18.378C5.68902 18.676 5.84371 19.0827 5.84371 19.5984V27.8827H8.19838V19.5984C8.19838 18.3379 7.80307 17.3583 7.01245 16.6593C6.22183 15.9604 5.20777 15.6109 3.97028 15.6109Z"
                  fill="white"/>
                <path d="M16.1791 27.8827H18.5509V15.8515H16.1791V27.8827Z" fill="white"/>
                <path
                  d="M33.892 22.0905C34.9462 21.4832 35.4733 20.5666 35.4733 19.3405C35.4733 18.3208 35.1123 17.4843 34.3905 16.8312C33.6686 16.1781 32.7806 15.8515 31.7264 15.8515H26.6218V27.8827H31.3655C32.3967 27.8827 33.2618 27.5618 33.9608 26.9202C34.6712 26.29 35.0264 25.4822 35.0264 24.4968C35.0264 23.4999 34.6483 22.6978 33.892 22.0905ZM31.3655 25.6655H28.9936V23.0186H31.3655C31.7321 23.0186 32.0358 23.1447 32.2764 23.3968C32.5285 23.6488 32.6545 23.9639 32.6545 24.3421C32.6545 24.7202 32.5342 25.0353 32.2936 25.2874C32.053 25.5395 31.7436 25.6655 31.3655 25.6655ZM31.7264 18.0687C32.1275 18.0687 32.4597 18.2004 32.7233 18.464C32.9868 18.739 33.1186 19.0827 33.1186 19.4952C33.1186 19.8963 32.9868 20.2286 32.7233 20.4921C32.4597 20.7671 32.1275 20.9046 31.7264 20.9046H28.9936V18.0687H31.7264Z"
                  fill="white"/>
                <path
                  d="M52.9953 17.4156C51.7807 16.2124 50.3026 15.6109 48.5609 15.6109C46.8193 15.6109 45.3411 16.2124 44.1266 17.4156C42.9234 18.6301 42.3219 20.114 42.3219 21.8671C42.3219 23.6202 42.9234 25.0983 44.1266 26.3014C45.3411 27.516 46.8193 28.1233 48.5609 28.1233C50.3026 28.1233 51.7807 27.516 52.9953 26.3014C54.2098 25.0983 54.8171 23.6202 54.8171 21.8671C54.8171 20.114 54.2098 18.6301 52.9953 17.4156ZM45.7937 19.0312C46.5385 18.2978 47.4609 17.9312 48.5609 17.9312C49.6609 17.9312 50.5833 18.2978 51.3281 19.0312C52.0729 19.776 52.4453 20.7213 52.4453 21.8671C52.4453 23.0129 52.0729 23.9582 51.3281 24.703C50.5833 25.4478 49.6609 25.8202 48.5609 25.8202C47.4609 25.8202 46.5385 25.4478 45.7937 24.703C45.049 23.9582 44.6766 23.0129 44.6766 21.8671C44.6766 20.7213 45.049 19.776 45.7937 19.0312Z"
                  fill="white"/>
                <path d="M62.3279 27.8827H64.6997V15.8515H62.3279V27.8827Z" fill="white"/>
                <path
                  d="M97.0558 27.8827H99.4276V15.8515H97.6229L92.4667 23.2077V15.8515H90.0949V27.8827H91.8995L97.0558 20.5437V27.8827Z"
                  fill="white"/>
              </g>
            </svg>
          </div>
        </div>
        <div class="footer-links">
          <div class="contact">
            <div class="title-links-footer text-white">
              <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_2805_35296)">
                  <g clip-path="url(#clip1_2805_35296)">
                    <g clip-path="url(#clip2_2805_35296)">
                      <path
                        d="M18.397 13.4778C17.8659 13.4778 17.6508 14.1825 17.6422 14.2126C17.5497 14.5244 17.2615 14.7409 16.9368 14.7409C16.9346 14.7409 16.9324 14.7409 16.9303 14.7409C16.6027 14.7381 16.3159 14.5223 16.2285 14.2061C16.2213 14.1825 16.0062 13.4778 15.475 13.4778C14.9975 13.4778 14.748 13.9065 14.748 14.3302C14.7481 15.0678 15.6711 15.7591 16.9361 16.845C17.6597 16.2538 19.124 15.1211 19.124 14.3302C19.124 13.9065 18.8745 13.4778 18.397 13.4778Z"
                        fill="white"/>
                      <path
                        d="M22.4416 6.74769C21.8335 6.74769 21.3404 7.2407 21.3404 7.84882C21.3404 8.03425 21.3404 10.3347 21.3404 10.4671C21.3404 10.8725 21.0118 11.2011 20.6064 11.2011C20.2009 11.2011 19.8723 10.8725 19.8723 10.4671C19.8723 9.93064 19.8723 5.08871 19.8723 4.91248C19.8723 4.30436 19.3793 3.81135 18.7711 3.81135C18.163 3.81135 17.67 4.30436 17.67 4.91248C17.67 5.54927 17.67 10.2317 17.67 10.4671C17.67 10.8725 17.3413 11.2011 16.9359 11.2011C16.5305 11.2011 16.2018 10.8725 16.2018 10.4671C16.2018 10.2534 16.2018 2.23291 16.2018 1.97613C16.2018 1.36801 15.7088 0.875 15.1007 0.875C14.4926 0.875 13.9996 1.36801 13.9996 1.97613C13.9996 2.20649 13.9996 10.1721 13.9996 10.4671C13.9996 10.8725 13.6709 11.2011 13.2655 11.2011C12.8601 11.2011 12.5314 10.8725 12.5314 10.4671C12.5314 9.93064 12.5314 5.08871 12.5314 4.91248C12.5314 4.30436 12.0384 3.81135 11.4303 3.81135C10.8222 3.81135 10.3291 4.30436 10.3291 4.91248V14.9977L6.96267 11.6313C6.39175 11.0604 5.46151 11.0563 4.88658 11.6313C4.36542 12.1532 4.30875 12.9812 4.76398 13.5675C5.06085 13.9647 10.3507 20.0102 10.3507 20.0102H22.8104C23.2849 19.0106 23.5426 17.9331 23.5426 16.8294C23.5426 16.5755 23.5426 8.38647 23.5426 7.84882C23.5427 7.2407 23.0497 6.74769 22.4416 6.74769ZM17.417 18.3628C17.2787 18.4825 17.1074 18.542 16.936 18.542C16.7647 18.542 16.5933 18.4825 16.455 18.3628C13.2878 15.6833 13.2799 15.2523 13.2799 14.3289C13.2799 13.0278 14.2441 12.0084 15.4749 12.0084C16.1043 12.0084 16.5854 12.2671 16.936 12.6112C17.2865 12.2671 17.7675 12.0084 18.397 12.0084C19.6279 12.0084 20.5921 13.0278 20.5921 14.3289C20.5921 15.8742 19.144 16.9536 17.417 18.3628Z"
                        fill="white"/>
                      <path
                        d="M22.0745 21.4797H10.3291V25.1991C10.3291 25.6045 10.6578 25.9332 11.0632 25.9332H22.8086C23.214 25.9332 23.5427 25.6045 23.5427 25.1991V22.9479C23.5427 22.137 22.8854 21.4797 22.0745 21.4797Z"
                        fill="white"/>
                    </g>
                  </g>
                </g>
                <defs>
                  <clipPath id="clip0_2805_35296">
                    <rect width="28" height="28" fill="white"/>
                  </clipPath>
                  <clipPath id="clip1_2805_35296">
                    <rect width="26.25" height="26.25" fill="white" transform="translate(0.875 0.875)"/>
                  </clipPath>
                  <clipPath id="clip2_2805_35296">
                    <rect width="25.0568" height="25.0568" fill="white" transform="translate(1.47168 0.875)"/>
                  </clipPath>
                </defs>
              </svg>
              {{ $lang.footer.contact.title }}
            </div>
            <router-link to="/contact" name="contact" class="footer-link"> {{ $lang.footer.contact.link }}
            </router-link>
          </div>
          <div>
            <div class="title-links-footer text-white">
              <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M21.297 5.82836C17.0256 1.55745 10.1002 1.55698 5.82836 5.82836C1.55698 10.0997 1.55745 17.0251 5.82836 21.297C10.0997 25.5674 17.0251 25.5679 21.297 21.297C25.5679 17.0251 25.5674 10.1002 21.297 5.82836ZM14.9888 18.3182C14.9888 19.1061 14.3502 19.7448 13.5622 19.7448C12.7742 19.7448 12.1356 19.1061 12.1356 18.3182V12.6116C12.1356 11.8236 12.7742 11.1849 13.5622 11.1849C14.3502 11.1849 14.9888 11.8236 14.9888 12.6116V18.3182ZM13.537 10.173C12.7153 10.173 12.1674 9.5909 12.1845 8.87234C12.1674 8.11907 12.7153 7.5546 13.5536 7.5546C14.3925 7.5546 14.9232 8.11955 14.9408 8.87234C14.9403 9.5909 14.393 10.173 13.537 10.173Z"
                  fill="white"/>
              </svg>
              {{ $lang.footer.about.title }}
            </div>
            <a href='/about/jiboiana#team' class="footer-link">{{
                $lang.footer.about.link1
              }}
            </a>
            <router-link :to="{ path: '/about/collaborators', hash: '#collab'}" class="footer-link">
              {{ $lang.footer.about.link2 }}
            </router-link>
            <router-link to="/projects" class="footer-link">{{ $lang.footer.about.link3 }}</router-link>
            <router-link to="about/jiboiana" class="footer-link">{{ $lang.footer.about.link4 }}</router-link>
          </div>
          <div>
            <div class="title-links-footer text-white">
              <svg width="28" height="27" viewBox="0 0 28 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_2805_35334)">
                  <path
                    d="M12.2029 20.5005H22.1318C22.4731 20.5005 22.7498 20.7772 22.7498 21.1185V23.6007C22.7498 23.942 22.4731 24.2187 22.1318 24.2187H12.2029C11.8617 24.2187 11.585 23.942 11.585 23.6007V21.1185C11.585 20.7772 11.8616 20.5005 12.2029 20.5005Z"
                    fill="white"/>
                  <path
                    d="M2.20086 19.1031L8.78833 12.5156L11.4175 15.1448L4.83004 21.7322C4.46761 22.0947 3.99152 22.2759 3.51547 22.2759C3.03942 22.2759 2.56333 22.0947 2.2009 21.7322C1.47601 21.0074 1.47601 19.8279 2.20086 19.1031Z"
                    fill="white"/>
                  <path d="M8.7793 10.76L13.163 6.37632L17.554 10.7673L13.1703 15.151L8.7793 10.76Z" fill="white"/>
                  <path
                    d="M19.7453 11.2005L20.6266 12.0818C20.8679 12.3231 20.8679 12.7144 20.6266 12.9557L15.361 18.2213C15.2403 18.342 15.0821 18.4023 14.924 18.4023C14.7659 18.4023 14.6077 18.342 14.4871 18.2213L13.6058 17.3401C13.3645 17.0987 13.3645 16.7075 13.6058 16.4661L18.8714 11.2006C19.1127 10.9592 19.504 10.9592 19.7453 11.2005Z"
                    fill="white"/>
                  <path
                    d="M7.46647 10.3265C7.22513 10.5679 6.83386 10.5679 6.59252 10.3265L5.71128 9.44531C5.46994 9.20396 5.46994 8.8127 5.71128 8.57136L10.9768 3.30575C11.2181 3.06445 11.6094 3.0644 11.8508 3.30575L12.732 4.18699C12.9734 4.42833 12.9734 4.81959 12.732 5.06094L7.46647 10.3265Z"
                    fill="white"/>
                </g>
                <defs>
                  <clipPath id="clip0_2805_35334">
                    <rect width="21.0938" height="21.0938" fill="white" transform="translate(1.65625 3.125)"/>
                  </clipPath>
                </defs>
              </svg>
              {{ $lang.footer.ressources.title }}
            </div>
            <router-link to="/contact" class="footer-link">{{ $lang.footer.ressources.link1 }}</router-link>
            <router-link to="/CGV" class="footer-link">{{ $lang.footer.ressources.link2 }}</router-link>
            <router-link to="/CGV" class="footer-link">{{ $lang.footer.ressources.link3 }}</router-link>
            <div class="follow-block">
              <div class="title-links-footer text-white">{{ $lang.footer.follow.title }}</div>
              <div class="network-links">
                <a href="https://www.facebook.com/association.jiboiana" targer="_blank">
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M14.9997 1.66675H12.4997C11.3946 1.66675 10.3348 2.10573 9.5534 2.88714C8.77199 3.66854 8.33301 4.72835 8.33301 5.83342V8.33342H5.83301V11.6667H8.33301V18.3334H11.6663V11.6667H14.1663L14.9997 8.33342H11.6663V5.83342C11.6663 5.6124 11.7541 5.40044 11.9104 5.24416C12.0667 5.08788 12.2787 5.00008 12.4997 5.00008H14.9997V1.66675Z"
                      fill="white"/>
                  </svg>
                </a>
                <a href="https://www.instagram.com/association_jiboiana/" target="_blank">
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M14.167 1.66675H5.83366C3.53247 1.66675 1.66699 3.53223 1.66699 5.83342V14.1667C1.66699 16.4679 3.53247 18.3334 5.83366 18.3334H14.167C16.4682 18.3334 18.3337 16.4679 18.3337 14.1667V5.83342C18.3337 3.53223 16.4682 1.66675 14.167 1.66675Z"
                      fill="white"/>
                    <path
                      d="M13.3337 9.47501C13.4366 10.1685 13.3181 10.8769 12.9952 11.4992C12.6723 12.1215 12.1614 12.6262 11.5351 12.9414C10.9088 13.2566 10.1991 13.3663 9.5069 13.2549C8.81468 13.1436 8.17521 12.8167 7.67944 12.321C7.18367 11.8252 6.85685 11.1857 6.74546 10.4935C6.63408 9.8013 6.74379 9.09159 7.05901 8.46532C7.37423 7.83905 7.8789 7.32812 8.50123 7.00521C9.12356 6.68229 9.83187 6.56383 10.5254 6.66667C11.2328 6.77158 11.8878 7.10123 12.3935 7.60693C12.8992 8.11263 13.2288 8.76757 13.3337 9.47501Z"
                      fill="#383838" stroke="#383838" stroke-width="1.7" stroke-linecap="round"
                      stroke-linejoin="round"/>
                    <path d="M14.583 5.41675H14.5913" stroke="#383838" stroke-width="1.7" stroke-linecap="round"
                          stroke-linejoin="round"/>
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </footer>
</template>

<script>
import PrimaryBtn from '@/components/PrimaryBtn'

export default {
  name: 'Footer',
  components: { PrimaryBtn }
}
</script>

<style scoped lang="scss">
.network-links {
  a:not(:last-child) {
    margin-right: 8px;
  }
}

.footer-links {
  position: relative;
  z-index: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .title-links-footer {
    font-size: 1.5rem;
    line-height: 2rem;
    display: flex;
    margin-bottom: 16px;

    > svg {
      margin-right: 4px;
    }
  }

  > div {
    display: flex;
    flex-direction: column;

    &:not(:last-child) {
      margin-right: 52px;
      padding-bottom: 24px;
    }

    > a, .footer-links {
      color: white;
      font-family: Gilroy-Medium;

      &:not(:last-child) {
        margin-bottom: 8px;
      }

    }
  }
}

.follow-block {
  margin: 52px 0;
}

.left-block {
  flex-direction: column;
  justify-content: space-between;
  z-index: 1;
  padding-right: 24px;
}

.logo-mob {
  display: none;
}

.logo-pc {

  svg {
    g {
      transform: translateY(15px);
      transition: 0.2s;
    }

    &:hover > g {
      transform: none
    }
  }
}

.container {
  padding: 80px 0;
  background: #201F1F;
  position: relative;
  height: 444px;
  overflow: hidden;
  z-index: 0;

  .snake {
    z-index: 0;
    left: -180px;
    bottom: 0;
    position: absolute;
    transform: rotate(-25deg);
  }
}

@media (max-width: 768px) {
  .container {
    height: auto;
  }
  .container-footer {
    flex-direction: column;

    .left-block {
      margin-bottom: 32px;
    }
  }
  .contact {
    display: none !important;
  }
  .logo-pc {
    display: none;
  }

  .logo-mob {
    display: block;
    margin-left: 32px;
  }
}

@media (max-width: 425px) {
  .logo-mob {
    margin-left: 16px;
  }
}
</style>
