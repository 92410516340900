import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Projects from '@/views/Projects.vue'
import Project from '@/views/Project'
import Events from '@/views/Events'
import Jiboiana from '@/views/Jiboiana'
import Collaborators from '@/views/Collaborators'
import Contact from '@/views/Contact'
import CGV from '@/views/CGV'
import Blog from '@/views/Blog'
import Article from '@/views/Article'
import axios from 'axios'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      img: require('../assets/imgs/FemmesenJaunes.jpg')
    }
  },
  {
    path: '/projects',
    name: 'Projects',
    component: Projects,
    meta: {
      img: require('../assets/imgs/Cabanes.jpg')
    }
  },
  {
    path: '/blog',
    name: 'blog',
    component: Blog,
    meta: {}
  },
  {
    path: '/blog/:slug',
    name: 'article',
    component: Article,
    meta: {}
  },
  {
    path: '/events',
    name: 'Events',
    component: Events,
    meta: {
      img: require('../assets/imgs/dancin.jpg')
    }
  },
  {
    path: '/project/:slug',
    name: 'Project',
    component: Project,
    meta: {
      img: require('../assets/imgs/Clementine.jpg')
    }
  },
  {
    path: '/about/jiboiana',
    name: 'about',
    component: Jiboiana,
    meta: {
      img: require('../assets/imgs/who-are-we.jpg')
    }
  },
  {
    path: '/about/collaborators',
    name: 'Collaborators',
    component: Collaborators,
    meta: {
      img: require('../assets/imgs/Appreciation-culturelle.jpg')
    }
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact,
    meta: {
      img: require('../assets/imgs/contact.jpg')
    }
  },
  {
    path: '/cgv',
    name: 'CGV',
    component: CGV,
    meta: {
      img: require('../assets/imgs/contact.jpg')
    }
  }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior (to, from, savedPosition) {
    return { top: 0 }
  },
  routes
})

async function fetchHeaderData () {
  try {
    const response = await axios.get('https://cdn.contentful.com/spaces/ijpmkw98fb9q/environments/master/entries?content_type=header', {
      headers: {
        Authorization: 'Bearer ZX3-Q_BUEEGfwohSQNUM58tDOuq_EXfppcw-83JPbb0'
      }
    })
    return response.data.items[0].fields
  } catch (error) {
    console.error('Erreur lors de la récupération des données depuis Contentful', error)
    return null
  }
}

async function fetchImageData (entryId) {
  try {
    const response = await axios.get(`https://cdn.contentful.com/spaces/ijpmkw98fb9q/environments/master/assets/${entryId}`, {
      headers: {
        Authorization: 'Bearer ZX3-Q_BUEEGfwohSQNUM58tDOuq_EXfppcw-83JPbb0'
      }
    })
    return response.data.fields.file.url
  } catch (error) {
    console.error('Erreur lors de la récupération de l\'image depuis Contentful', error)
    return null
  }
}

async function updateMetaImg (to, from, next) {
  const headerData = await fetchHeaderData()
  if (headerData) {
    const data = []
    for (const [objName, objData] of Object.entries(headerData)) {
      data.push({ name: objName, id: objData.sys.id })
    }
    for (const el of data) {
      const image = await fetchImageData(el.id)
      if (image) {
        el.img = image
      }
      if (to.name.toLowerCase().includes(el.name)) {
        to.meta.img = el.img
      }
    }
  }
  next()
}

router.beforeEach(async (to, from, next) => {
  await updateMetaImg(to, from, next)
})

export default router
