<template>
  <div class="container">
    <h3 class="title-xs">{{ title }}</h3>
    <div class="flex space-between" v-if="type === 'event'">
      <p class="subtitle">{{ subtitle }}</p>
      <p class="subtitle">{{ date }}</p>
    </div>
    <p class="subtitle" v-else>{{ subtitle }}</p>
    <p class="description small">{{ description }}</p>
    <div class="container-img relative">
      <img :src="img" alt="" loading="lazy">
      <div class="tag" v-if="tag">{{ tag }}</div>
    </div>

    <primary-btn :page-link="id" :title="$lang.projects.btnDiscover" v-if="id"/>
    <div class="flex space-btn" v-if="type==='event'">
      <primary-btn :link="link" :title="$lang.events.reserve"/>
      <primary-btn :action="true" color="grey" :title="$lang.projects.btnDiscover" @click="$emit('show-modal')"/>
    </div>

  </div>
</template>

<script>
import PrimaryBtn from '@/components/PrimaryBtn'

export default {
  name: 'Card',
  components: { PrimaryBtn },
  props: {
    tag: {
      type: String,
      required: false
    },
    title: String,
    subtitle: String,
    description: String,
    img: String,
    id: String,
    type: String,
    link: String,
    date: String

  },
  methods: {}
}
</script>

<style scoped lang="scss">
.container {
  max-width: 384px;

  .container-img {
    width: 100%;
    height: 196px;
    margin-bottom: 12px;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  .description {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    margin-top: 8px;
    margin-bottom: 12px;
  }
}

.tag {
  border-radius: 200px;
  border: 1px solid var(--color-success-soft);
  padding: 0px 8px;
  width: fit-content;
  position: absolute;
  bottom: 12px;
  right: 14px;
  color: white;
}

.space-btn {
  justify-content: space-between;
}

@media screen and (max-width: 576px) {
  .space-btn {
    display: block;

    div {
      width: 100% !important;

      &:first-child {
        margin-bottom: 12px;
      }
    }
  }
}
</style>
