<template>
  <div class="center-loader">
    <div class="">
      <svg class="animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="var(--color-text)" stroke-width="4"></circle>
        <path class="opacity-75" fill="var(--color-primary)"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Loader'
}
</script>

<style scoped lang="scss">
.center-loader {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;

  > ::v-deep svg {
    width: 50px;
    height: 50px;
  }

  > div {
    width: 64px;
    height: 64px
  }
}

.animate-spin {
  animation: spin 1s linear infinite;
  height: 64px;
  width: 64px;

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}
</style>
