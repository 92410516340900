const fr = {
  support: 'Je soutiens',
  nav: {
    project: 'Nos projets',
    event: 'Evenements',
    about: {
      abt: 'Qui sommes-nous',
      jiboina: 'Qui est Jiboiana',
      collab: 'Collaborations'
    },
    blog: 'Actualités'
  },
  head: {
    title1: 'Soutenons ensemble',
    title2: 'Les Gardien.nes de la Terre'
  },
  footer: {
    title1: 'Soutenons ensemble',
    title2: 'Les Gardien.nes de la Terre',
    contact: {
      title: 'Nous contacer',
      link: 'S\'impliquer'
    },
    about: {
      title: 'A propos',
      link1: 'Equipe',
      link2: 'Partenaires',
      link3: 'Actions',
      link4: 'Valeurs'
    },
    ressources: {
      title: 'Ressources',
      link1: 'Contact',
      link2: 'Mentions légales',
      link3: 'Crédits'
    },
    follow: {
      title: 'Nous suivre'
    }
  },
  home: {
    intro: {
      title: 'Le rôle méconnu des peuples autochtones ',
      representBefore: 'Reforestation, eau potable, autonomie alimentaire, préservation culturelle, aide humanitaire, venue de représentants de communautés locales en Europe : Jiboiana œuvre avec les peuples autochtones pour la préservation de la Nature.',
      represent: 'Bien qu’ils ne représentent que ',
      population: ' de la population mondiale, et pourtant ils protègent',
      terre: 'de la biodiversité sur Terre.',
      p2: 'Depuis des siècles nous avons séparé l’humain de la Nature, c’est pourquoi les peuples autochtones sont souvent oubliés des discours environnementaux, alors qu’ils sont l’incarnation de cette nécessaire union.',
      p3: "C'est pourquoi nous avons créé Jiboiana : afin d'ouvrir un espace de solidarité où leurs voix peuvent s'exprimer librement, et leurs messages être entendus partout sur la planète. Nous mettons en avant leur rôle capital pour la survie de l'entièreté du vivant. "
    },
    actions: {
      title: 'Nos actions',
      action1: 'Reforestation',
      action2: 'Eau potable',
      action3: 'Digitale',
      action4: 'Droit des peuples'
    },
    soutien: {
      title: 'Les soutenir dans l’autonomie',
      p1: "Les projets de Jiboiana ont pour but de soutenir les communautés autochtones sur le long terme, dans une optique d’autonomie, car l'idée fondamentale est qu’à la suite de la réalisation de ces projets ils aient au fil du temps de moins en moins besoin de nous. ",
      p3: "Par exemple, le programme de reforestation et de sécurité alimentaire que nous avons mis en place avec le peuple autochtone Huni Kuin, en Amazonie brésilienne, a été un véritable succès d'autonomie : en seulement 1 an et demi, nous avons construit avec 8 villages onze pépinières, et planté plus de 7000 arbres - et ce sont les agents agroforestiers et les familles qui ont réalisé la majorité de ce travail en notre absence. ",
      p2: 'De plus, nous travaillons avec des leaders et activistes autochtones sur des projets politiques, visant à protéger leurs droits et leurs terres, ainsi qu’à porter leurs voix à l’international.'
    },
    qlqPhotos: {
      title: 'Quelques photos',
      subtitle: 'Parcequ’ils méritent d’être vus et entendus.',
      btn: 'Découvrir plus'
    },
    equipe: {
      title: 'La team JIBOIANA ',
      subtitle: 'Les membres de la team jiboiana',
      p: 'Sans eux, sans vous, rien de tout cela n’aurait été possible !',
      btn: 'Découvre l\'équipe'
    }
  },
  about: {
    title: 'Qui est jiboiana ?',
    quote: '“Jiboiana est plus qu’une association : c’est un mouvement pour la reconnaissance du rôle des peuples autochtones”',
    author: 'Léo Landon',
    p1: 'Notre association est née en 2020 suite à plusieurs voyages au cœur  de l’Amazonie chez le peuple Huni Kuin. Avec générosité, ils nous ont permis de participer à certains de leurs rites et de leurs coutumes, de voir que l’humain peut évoluer en harmonie avec la Nature, et d’apprendre d’autres manières de penser et de vivre.',
    p2: 'Durant nos échanges, ils nous ont fait part de la nécessité de créer un pont entre eux et l’international, afin de faire connaître leur culture et leur lutte. Préserver la forêt, et donc préserver leur mode de vie, n’est pas qu’un enjeu national ; cela concerne chacune et chacun d’entre nous.\n',
    p3: '\n' +
      'C’est pourquoi nous avons créé Jiboiana : car nous avons pris conscience que notre destin est intimement lié au leur. Si les peuples autochtones disparaissent, les plus grands et riches écosystèmes sur la planète disparaîtront à leur tour',
    where: {
      title: 'D’où sommes-nous ?',
      p1: 'Notre équipe est constituée de sept françaises et français, résidant entre Paris, São Paulo, Amsterdam et Bogota. Nous avons également rassemblé une équipe de bénévoles qui nous aident à réaliser nos actions.',
      p2: 'L’art est une part importante dans toutes les cultures ; pour honorer cette universalité, nous avons des partenariats avec de nombreux artistes : photographes, musiciens, peintres, réalisateurs... En proposant leurs œuvres, ils permettent à la fois de sensibiliser le public et de lever des fonds pour nos projets.',
      p3: '\n' +
        'Nous remercions chaleureusement chacune et chacun d’entre vous pour vos contributions qui sont toutes aussi importantes que nécessaires !'
    },
    jiboina: {
      title: 'Que veut dire Jiboiana ?',
      p: 'Jiboiana vient du mot “Jiboia”, qui en portugais signifie “Boa”, ce grand serpent que l’on trouve dans les forêts tropicales. Dans la culture du peuple Huni Kuin, la Jiboia est venue leur apporter la connaissance de leurs médecines et de leurs traditions. C’est un animal qui a travers sa mue apporte la transformation. Il revient donc régulièrement dans leurs prières et dans leurs chants.'
    },
    team: {
      title: 'L\'équipe'
    }
  },
  collab: {
    title: 'Les partenaires et soutiens de jiboiana',
    p: 'Estamos tudos juntos',
    p2: "Merci à tous nos partenaires et collaborateurs qui soutiennent Jiboiana et les gardiennes et gardiens de la Terre. Fondations, ONGs, artistes, activistes, sans chacun d'entre eux, chacun d'entre vous, nous ne serions pas allés aussi loin.",
    title2: 'Partenaires et collaborateurs'
  },
  projects: {
    title: 'Tous nos projets',
    btnDiscover: 'Découvrir'
  },
  events: {
    title: 'Evénements',
    type: 'Genre',
    reserve: 'Prendre sa place'
  },
  contact: {
    title: 'Nous contacter',
    p: 'Remplissez le formulaire et nous vous répondrons dans les meilleurs délais',
    input1: 'Prénom',
    input2: 'Nom',
    input3: 'Téléphone',
    input4: ['Nous contacter pour', 'Collaboration', 'Voyages', 'Bénévolat', 'Don d\'objets', 'Autre'],
    input5: 'Votre message',
    send: 'Envoyer'
  }
}

export default fr
