<template>
  <div class="lang" v-if="lang.length">
    <button class="container-lang" :id="lang[0].id" @click="openLang = true" @mouseover="openLang = true" v-if="lang">
      <img :src="lang[0].img" alt="" loading="lazy">
    </button>
    <div v-if="openLang" class="lang-choice">
      <button class="container-lang" :id="lang[1].id" @click="firstLang">
        <img :src="lang[1].img" alt="" loading="lazy">
      </button>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'langSystem',
  computed: {
    ...mapGetters(['getLang'])
  },
  data () {
    return {
      openLang: false,
      lang: [
        {
          img: require('../assets/langueFr.png'),
          id: 'fr'
        }, {
          img: require('../assets/langueEn.png'),
          id: 'en'
        }
      ]
    }
  },
  methods: {
    ...mapActions(['setLang']),
    firstLang () {
      const first = this.lang.shift()
      this.lang.push(first)
      window.localStorage.setItem('locale', this.lang[0].id)
      this.openLang = false
      this.setLang(this.lang)
      this.$nextTick(() => {
        location.reload()
      })
    },
    secondLang () {
      const last = this.lang.pop()
      const first = this.lang.shift()
      this.lang.splice(0, 0, last)
      this.lang.splice(2, 0, first)
      window.localStorage.setItem('locale', this.lang[0].id)
      this.openLang = false
      this.setLang(this.lang)
      this.$nextTick(() => {
        location.reload()
      })
    }
  },
  created () {
    if (localStorage.getItem('locale')) {
      this.lang = this.getLang
    } else {
      window.localStorage.setItem('locale', this.lang[0].id)
      this.setLang(this.lang)
    }
  }
}
</script>
<style lang="scss" scoped>
.lang{
  position: absolute;
  right: 0;
}

.lang-choice {
  display: flex;
  flex-direction: column;
}

.container-lang {
  width: 32px;

  img {
    object-fit: cover;
    width: 100%;
    height: auto;
  }
}

@media (max-width: 886px) {
  .lang{
    top: 10px;
    align-self: center;
    position: inherit ;
  }
}
</style>
