<template>
  <div>
    <router-link type="button" :to="{name: 'Project', params:{slug: pageLink}}" v-if="pageLink">
      <button :class="color">{{ title }}</button>
    </router-link>
    <button :class="color" v-if="link"><a :href='link' target="_blank">{{ title }}</a></button>
    <button :class="color" v-if="action">{{ title }}</button>
    <router-link type="button" :to="route" v-if="route">
      <button :class="color">{{ title }}</button>
    </router-link>
  </div>

</template>

<script>
export default {
  name: 'PrimaryBtn',
  props: {
    route: String,
    title: {
      type: String,
      required: true
    },
    link: {
      type: String,
      required: false
    },
    pageLink: {
      type: String,
      required: false
    },
    color: String,
    action: Boolean
  }
}
</script>

<style scoped lang="scss">
div {
  width: fit-content;
}

button {
  color: white;
  max-height: 38px;
  padding: 7px;
  min-width: 180px;
  background: var(--color-primary);
  border-radius: var(--radius-default);

  &.grey {
    color: var(--color-text-strong);
    background: var(--color-surface-divider);

    a {
      color: var(--color-text-strong);
    }
  }
  &:focus{
    outline: none;
  }

}

a {
  color: white;
}

@media (max-width: 768px) {
  button {
    width: 100%;
  }
}

</style>
