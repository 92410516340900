<template>
  <transition name="fade" @enter="showModal = true">
    <div v-if="isVisible" class="overlay" @click="showModal = false">
      <!-- Modal -->
      <transition name="fade-moove" @leave="$emit('close-modal')">
        <div v-if="showModal" class="modal" @click.stop>
          <div class="header">
            <div class="title">
              <h4 class="title-l margin-bot-16">{{ title }}</h4>
              <p class="strong margin-bot-8">{{ date }}</p>
              <p class="small margin-bot-8">{{ location }}</p>
            </div>
            <button class="closeBtn" @click="showModal = false">
              <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13 1L1 13" stroke="#505F79" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M1 1L13 13" stroke="#505F79" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </button>
          </div>
          <div class="body">
            <slot name="body"></slot>
          </div>
          <div v-if="hasFooterSlot" class="footer">
            <slot name="footer"></slot>
          </div>
        </div>
      </transition>
    </div>
  </transition>
</template>

<script>

export default {
  name: 'UniModal',
  components: {},
  computed: {
    hasFooterSlot () {
      return !!this.$slots.footer
    }
  },
  methods: {},
  props: {
    title: String,
    date: String,
    location: String,
    isVisible: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      showModal: true
    }
  }
}
</script>

<style scoped lang="scss">
.closeBtn {
  width: 24px;
  height: 24px;
  position: absolute;
  right: 24px;
  top: 24px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 30;
}

.modal {
  position: relative;
  background: var(--color-surface-soft);
  box-shadow: var(--shadow-l);
  height: 100%;
  padding: 32px 24px;
  display: flex;
  flex-direction: column;
}

.body {
  width: 100%;
  overflow: auto;
  text-align: center;
  margin-bottom: 24px;

}

.header > h4 {
  margin: 0;
}

.footer {
  z-index: 2;
  position: relative;
  flex-grow: 1;
  display: flex;

  :first-child {
    align-self: flex-end;
  }
}

.title {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.title h4 {
  //margin-left: var(--space-16);
}

/* Transitions */
.fade-enter-active {
  transition: opacity .2s ease-in-out;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
  opacity: 0;
}

.fade-moove-enter-active,
.fade-moove-leave-active {
  transition: var(--transition-default);
}

.fade-moove-enter /* .fade-leave-active below version 2.1.8 */
{
  transform: translateY(100vh);
}

@media screen and (min-width: 799px) {
  .overlay {
    background: rgba(9, 30, 66, 0.54);
  }
  .modal {
    width: 670px;
    top: 50px;
    margin: auto auto;
    height: 70vh;
    max-height: 800px;
    border-radius: var(--radius-default);
  }
  .header {
    padding-bottom: 16px;
  }

  /* Transitions */
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.4s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
  {
    opacity: 0;
  }

  .fade-moove-enter-active,
  .fade-moove-leave-active {
    transition: 0.25s;
  }
  .fade-moove-enter /* .fade-leave-active below version 2.1.8 */
  {
    opacity: 0;
    transform: translateY(32px);
  }
  .fade-moove-leave-to {
    opacity: 0;
    transform: translateY(-32px);
  }
}
</style>
